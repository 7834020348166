import React, { useRef, useEffect, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { PencilIcon } from '@heroicons/react/24/solid';
import { getSignedImageUrl } from '../../utils/imageUrl';

interface Props {
  imageUrl?: string;
  firstName: string;
  lastName: string;
  onImageSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  imageState: {
    src: string | null;
    crop: Crop;
    croppedImageUrl: string | null;
  };
  setImageState: React.Dispatch<
    React.SetStateAction<{
      src: string | null;
      crop: Crop;
      croppedImageUrl: string | null;
    }>
  >;
  onCropComplete: (crop: Crop) => void;
}

export const ProfileImageUpload: React.FC<Props> = ({
  imageUrl,
  firstName,
  lastName,
  onImageSelect,
  imageState,
  setImageState,
  onCropComplete,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const initials = `${firstName[0]}${lastName[0]}`.toUpperCase();
  const [signedUrl, setSignedUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadSignedUrl = async () => {
      if (imageUrl) {
        const signed = await getSignedImageUrl(imageUrl);
        console.log('Signed URL:', signed);
        setSignedUrl(signed);
      }
    };

    loadSignedUrl();
  }, [imageUrl]);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      {imageState.src ? (
        // Show cropping interface when a new image is selected
        <div className="w-full max-w-md">
          <ReactCrop
            crop={imageState.crop}
            onChange={(crop) => setImageState((prev) => ({ ...prev, crop }))}
            onComplete={onCropComplete}
            aspect={1}
          >
            <img src={imageState.src} alt="Upload preview" />
          </ReactCrop>
        </div>
      ) : (
        // Show current profile image or initials
        <div className="relative group cursor-pointer" onClick={handleClick}>
          <div className="w-32 h-32 rounded-full overflow-hidden relative">
            {imageUrl ? (
              <img
                src={signedUrl || imageUrl}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center text-2xl font-bold">
                {initials}
              </div>
            )}
            {/* Overlay with edit icon on hover */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
              <PencilIcon className="h-8 w-8 text-white" />
            </div>
          </div>
        </div>
      )}

      {/* Hidden file input */}
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={onImageSelect}
        accept="image/jpeg,image/png,image/gif,image/webp"
      />

      {/* Show cropped preview if available */}
      {imageState.croppedImageUrl && (
        <div className="mt-4">
          <p className="text-sm text-gray-500 mb-2">Preview:</p>
          <img
            src={imageState.croppedImageUrl}
            alt="Crop preview"
            className="w-32 h-32 rounded-full object-cover"
          />
        </div>
      )}
    </div>
  );
};
