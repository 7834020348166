export const workflowPhases = {
  'Prospective Jobs': [
    'initiated',
    'estimating',
    'estimate - finished',
    'letter agreement - sent',
    'letter agreement - signed',
    'Deposit - Sent',
    'Deposit - Paid',
  ],
  'Pre Work': ['scheduling - needs schedule', 'scheduling - waiting to start'],
  'Drawings In Progress': [
    'schematics - in progress',
    'schematics - blocked',
    'schematics - finished',
    'design plans - in progress',
    'design plans - blocked',
    'design plans - finished',
  ],
  'AHJ Review': [
    'AHJ - submitted',
    'AHJ - has comments',
    'AHJ - addressing comments',
    'AHJ - approved',
  ],
  'Closed - Delivered': ['closed'],
  'Closed - Lost': ['closed - lost'],
  'On Hold': ['holding'],
} as const;

export type WorkflowPhase = keyof typeof workflowPhases;
