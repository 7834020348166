import React from 'react';
import DOMPurify from 'dompurify';
import styles from './Thread.module.css';

interface MessageContentProps {
  content: string;
  onMentionClick: (userId: string) => void;
}

export const MessageContent: React.FC<MessageContentProps> = ({
  content,
  onMentionClick,
}) => {
  const processContent = (content: string) => {
    // Process mentions in the HTML content
    const processedContent = content.replace(
      /@\[([^\]]+)\]\(([^)]+)\)/g,
      '<span class="mention" data-user-id="$1">@$2</span>',
    );

    const div = document.createElement('div');
    div.innerHTML = processedContent;

    // Process links before sanitization
    div.querySelectorAll('a').forEach((link) => {
      const href = link.getAttribute('href');
      if (href) {
        // If it starts with '/', it's an internal link - leave as is
        // Otherwise, assume it's a full URL
        if (!href.startsWith('/')) {
          try {
            // Test if it's a valid URL
            new URL(href);
          } catch (e) {
            // If not a valid URL, prepend with https://
            link.setAttribute('href', `https://${href}`);
          }
        }
      }
    });

    // Sanitize the processed HTML
    return DOMPurify.sanitize(div.innerHTML, {
      ALLOWED_TAGS: [
        'p',
        'br',
        'a',
        'strong',
        'em',
        'u',
        'span',
        'ul',
        'ol',
        'li',
      ],
      ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'data-user-id'],
    });
  };

  const handleClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;

    if (target.classList.contains('mention')) {
      const userId = target.getAttribute('data-user-id');
      if (userId) {
        onMentionClick(userId);
      }
    }

    if (target.tagName === 'A') {
      e.preventDefault();
      window.open(target.href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div
      className={`prose max-w-none ${styles['message-content']}`}
      onClick={handleClick}
      dangerouslySetInnerHTML={{
        __html: processContent(content),
      }}
    />
  );
};
