import { Badge, Button, List, Modal, Tag } from 'antd';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { format, addDays } from 'date-fns';
import { leavesState } from '../../atoms/leave.atom';
import { fetchLeaves, updateLeave } from '../../utils/api';

const PendingLeaveApprovals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leaves, setLeaves] = useRecoilState(leavesState);

  const pendingLeaves = leaves.filter((leave) => leave.status === 'requested');

  const handleStatusUpdate = async (
    leaveId: string,
    status: 'approved' | 'denied',
  ) => {
    try {
      await updateLeave(leaveId, { status });
      const response = await fetchLeaves();
      setLeaves(response.data);
    } catch (error) {
      console.error('Failed to update leave status:', error);
    }
  };

  return (
    <>
      <Badge count={pendingLeaves.length} offset={[0, 0]}>
        <Button onClick={() => setIsModalOpen(true)}>Pending Requests</Button>
      </Badge>

      <Modal
        title="Pending Leave Requests"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={600}
      >
        <List
          dataSource={pendingLeaves}
          renderItem={(leave) => (
            <List.Item
              actions={[
                <Button
                  type="primary"
                  onClick={() => handleStatusUpdate(leave._id, 'approved')}
                >
                  Approve
                </Button>,
                <Button
                  danger
                  onClick={() => handleStatusUpdate(leave._id, 'denied')}
                >
                  Deny
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={leave.title}
                description={
                  <>
                    <div>
                      {format(new Date(leave.startDate), 'MMM d, yyyy')} -{' '}
                      {format(
                        addDays(new Date(leave.startDate), leave.duration - 1),
                        'MMM d, yyyy',
                      )}
                    </div>
                    <div>
                      Requested by: {leave.createdBy.firstName}{' '}
                      {leave.createdBy.lastName}
                    </div>
                    <div>
                      Members:{' '}
                      {leave.members
                        .map((m) => `${m.firstName} ${m.lastName}`)
                        .join(', ')}
                    </div>
                    {leave.description && (
                      <div className="mt-2">{leave.description}</div>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default PendingLeaveApprovals;
