// src/../atoms/project.atom.ts

import { atom, selector, selectorFamily } from 'recoil';
import { calculateItemTotal } from '../utils/misc';
import {
  fetchProjects,
  createProject,
  fetchProject,
  updateProject,
  deleteProject,
  fetchProjectsByCompany,
} from '../utils/api';
import { LOEItemI, Milestone, Project } from '../models/general';
import { startTransition } from 'react';
import { addDays, isAfter, isBefore, isToday } from 'date-fns';
import { activeCompanyState } from './new.company.atom';

export const activeProjectState = atom({
  key: 'activeProjectState',
  default: {} as Project,
});

export const lOEItemListState = atom({
  key: 'LOEList',
  default: [] as LOEItemI[],
});

export const milestonesState = atom({
  key: 'milestoneList',
  default: [] as Milestone[],
});

export const lOETotals = selector({
  key: 'LOETotals',
  get: ({ get }) => {
    const { loeEstimate: lOEItemList, disciplines } = get(activeProjectState);
    if (lOEItemList === undefined) return;
    let total = 0;
    let vals = {
      architecture: 0,
      civil: 0,
      electrical: 0,
      foundation: 0,
      mechanical: 0,
      plumbing: 0,
      structural: 0,
      total: 0,
    };
    disciplines.map((discipline) => {
      let counter = 0;
      lOEItemList.forEach((item) => {
        if (item.discipline === discipline)
          counter = counter + calculateItemTotal(item);
      });
      (vals as any)[discipline] = counter;
      total = total + counter;
    });
    vals.total = total;

    return vals;
  },
});

// Create a selector to fetch projects
export const projectsQuery = selector({
  key: 'ProjectsQuery',
  get: async ({ get }) => {
    const activeCompany = get(activeCompanyState);
    if (!activeCompany?._id) return [];

    try {
      const response = await fetchProjectsByCompany(activeCompany._id);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch projects:', error);
      return [];
    }
  },
});

// Update projectListState to use the selector
export const projectListState = atom({
  key: 'projectListState',
  default: projectsQuery,
});

export const keyMilestones = selector({
  key: 'keyMilestones',
  get: ({ get }) => {
    const activeProjects = get(projectListState);
    if (activeProjects === undefined) return;
    let miles = [];
    activeProjects.forEach((project: Project) => {
      const { loeEstimate: lOEItemList, disciplines } = project;
      if (lOEItemList === undefined) return;
      let total = 0;
      let vals = {
        architecture: 0,
        civil: 0,
        electrical: 0,
        foundation: 0,
        mechanical: 0,
        plumbing: 0,
        structural: 0,
        total: 0,
      };
      disciplines.flatMap((discipline) => {
        let counter = 0;
        lOEItemList.forEach((item) => {
          if (item.discipline === discipline)
            counter = counter + calculateItemTotal(item);
        });
        (vals as any)[discipline] = counter;
        total = total + counter;
      });
      vals.total = total;
      project.milestones?.forEach((milestone) => {
        if (
          milestone.completionDate ||
          project.workFlowStatus === 'closed - lost' ||
          project.workFlowStatus === 'closed' ||
          project.workFlowStatus === 'initiated' ||
          project.workFlowStatus === 'holding' ||
          project.workFlowStatus === 'estimating' ||
          project.workFlowStatus === 'estimate - finished' ||
          project.workFlowStatus === 'letter agreement - sent' ||
          project.workFlowStatus === 'letter agreement - signed' ||
          project.workFlowStatus === 'Deposit - Sent'
        )
          return;
        switch (milestone.title) {
          case 'Paid Deposit':
            if (vals.total > 10000) {
              miles.push({
                ...milestone,
                projectName: project.name,
                invoiceAmount: vals.total * 0.1,
              });
            } else {
              miles.push({
                ...milestone,
                projectName: project.name,
                invoiceAmount: vals.total * 0.5,
              });
            }
            break;

          case 'Schematic Delivery':
            if (vals.total > 10000) {
              miles.push({
                ...milestone,
                projectName: project.name,
                invoiceAmount: vals.total * 0.4,
              });
            } else {
            }
            break;

          case '100% Plans Delivery':
            if (vals.total > 10000) {
              miles.push({
                ...milestone,
                projectName: project.name,
                invoiceAmount: vals.total * 0.4,
              });
            } else {
              miles.push({
                ...milestone,
                projectName: project.name,
                invoiceAmount: vals.total * 0.5,
              });
            }
            break;

          case 'AHJ Permit Approved':
            if (vals.total > 10000) {
              miles.push({
                ...milestone,
                projectName: project.name,
                invoiceAmount: vals.total * 0.1,
              });
            } else {
            }
            break;

          default:
            break;
        }
      });
    });
    let total = 0;
    miles.forEach((mile) => (total = total + mile.invoiceAmount));
    console.log('TOTAL', total);
    return miles;
  },
});

// Persisting project changes
export const projectCRUDState = selector({
  key: 'projectCRUDState',
  get: ({ get }) => get(projectListState),
  set: ({ set, get }, newValue) => {
    const currentProjects = get(projectListState);

    if (newValue.action === 'create') {
      createProject(newValue.data).then((response) => {
        set(projectListState, [...currentProjects, response.data]);
      });
    } else if (newValue.action === 'update') {
      updateProject(newValue.id, newValue.data).then((response) => {
        set(
          projectListState,
          currentProjects.map((project: Project) =>
            project._id === newValue.id ? response.data : project,
          ),
        );
      });
    } else if (newValue.action === 'delete') {
      deleteProject(newValue.id).then(() => {
        set(
          projectListState,
          currentProjects.filter(
            (project: Project) => project._id !== newValue.id,
          ),
        );
      });
    }
  },
});
