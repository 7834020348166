import { getSignedUrl } from './api';

interface CachedUrl {
  signedUrl: string;
  expiresAt: number;
}

const CACHE_KEY = 'signed_url_cache';
const CACHE_DURATION = 0.1 * 60 * 60 * 1000; // 30 minutes in milliseconds

// Get cache from localStorage
const getUrlCache = (): Record<string, CachedUrl> => {
  try {
    const cache = localStorage.getItem(CACHE_KEY);
    return cache ? JSON.parse(cache) : {};
  } catch (error) {
    console.error('Error reading URL cache:', error);
    return {};
  }
};

// Save cache to localStorage
const setUrlCache = (cache: Record<string, CachedUrl>) => {
  try {
    localStorage.setItem(CACHE_KEY, JSON.stringify(cache));
  } catch (error) {
    console.error('Error saving URL cache:', error);
  }
};

export const getSignedImageUrl = async (
  imageUrl: string | null | undefined,
): Promise<string | null> => {
  if (!imageUrl || !imageUrl.includes('storage.googleapis.com')) {
    return imageUrl || null;
  }

  // Check cache first
  const cache = getUrlCache();
  const cachedItem = cache[imageUrl];
  const now = Date.now();

  if (cachedItem && cachedItem.expiresAt > now) {
    console.log('Returning cached signed URL:', cachedItem.signedUrl);
    return cachedItem.signedUrl;
  }

  try {
    const [_, pathWithBucket] = imageUrl.split('storage.googleapis.com/');
    const [bucketName, ...pathParts] = pathWithBucket.split('/');
    const fileName = pathParts.join('/');

    const response = await getSignedUrl({ fileName });
    const signedUrl = response.data.url;

    // Update cache
    cache[imageUrl] = {
      signedUrl,
      expiresAt: now + CACHE_DURATION,
    };
    setUrlCache(cache);

    return signedUrl;
  } catch (error) {
    console.error('Error getting signed URL:', error);
    return imageUrl; // Fallback to original URL if signing fails
  }
};

// Optional: Add a function to clear expired cache entries
export const cleanUrlCache = () => {
  const cache = getUrlCache();
  const now = Date.now();
  let hasChanges = false;

  Object.keys(cache).forEach((key) => {
    if (cache[key].expiresAt <= now) {
      delete cache[key];
      hasChanges = true;
    }
  });

  if (hasChanges) {
    setUrlCache(cache);
  }
};
