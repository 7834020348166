import React from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { getMetrics, getMilestoneCounts } from '../../utils/api';
import { Tooltip } from 'antd';
import { activeAuths } from '../../atoms/user.atom';

interface MetricData {
  _id: string;
  company: string;
  milestone: string;
  displayName: string;
  operation: string;
  comparison: string;
  target: number;
  includeAmount?: boolean;
  amountTarget?: number;
  timeFrame: string;
}

interface MetricResult {
  metricId: string;
  milestone: string;
  count: number;
  totalValue: number;
  timeFrame: string;
}

interface MilestoneMetric {
  title: string;
  displayName: string;
  color: string;
  count: number;
  target: number;
  includeAmount?: boolean;
  amountTarget?: number;
  currentAmount?: number;
  timeFrame: string;
}

const getColorForTitle = (title: string): string => {
  const colorMap: { [key: string]: string } = {
    'Estimate Completed': '#ADD8E6',
    'Letter Agreement Signed': '#ADD8E6',
    'Paid Deposit': '#0066CC',
    'Schematic Delivery': '#90EE90',
    '100% Plans Delivery': '#228B22',
  };
  return colorMap[title] || '#CCCCCC';
};

const MilestoneMetrics: React.FC = () => {
  const { data: metricsData } = useQuery<{ data: MetricData[] }>(
    'metrics',
    getMetrics,
  );
  const { data: countsData } = useQuery<{ data: MetricResult[] }>(
    'milestoneCounts',
    () => getMilestoneCounts(),
    {
      enabled: !!metricsData?.data,
    },
  );
  const activeAuthorizations = useRecoilValue(activeAuths);

  if (!metricsData?.data) return null;

  const milestoneMetrics: MilestoneMetric[] = metricsData.data.map((metric) => {
    const result = countsData?.data?.find((r) => r.metricId === metric._id);

    return {
      title: metric.milestone,
      displayName: metric.displayName || metric.milestone,
      color: getColorForTitle(metric.milestone),
      count: result?.count || 0,
      target: metric.target,
      includeAmount: metric.includeAmount,
      amountTarget: metric.amountTarget,
      currentAmount: result?.totalValue || 0,
      timeFrame: metric.timeFrame || 'month',
    };
  });

  const formatTimeFrame = (timeFrame: string): string => {
    switch (timeFrame) {
      case 'week':
        return 'This Week';
      case 'month':
        return 'This Month';
      case 'quarter':
        return 'This Quarter';
      case 'year':
        return 'This Year';
      default:
        return `This ${timeFrame}`;
    }
  };

  return (
    <div className="grid grid-cols-4 gap-4 p-4">
      {milestoneMetrics.map((metric) => (
        <div key={metric.title} className="w-48 text-center">
          <div className="relative w-32 h-16 overflow-hidden mx-auto">
            <div
              className="absolute w-32 h-32 rounded-full border-8"
              style={{
                borderColor: metric.color,
                clipPath: 'polygon(0 50%, 100% 50%, 100% 0, 0 0)',
                opacity: 0.3,
              }}
            />
            <div
              className="absolute w-32 h-32 rounded-full border-8"
              style={{
                borderColor: metric.color,
                clipPath: `polygon(0 50%, ${(metric.count / metric.target) * 100}% 50%, ${(metric.count / metric.target) * 100}% 0, 0 0)`,
              }}
            />
          </div>
          <div className="mt-2">
            <Tooltip title={metric.title}>
              <div className="font-bold cursor-help">{metric.displayName}</div>
            </Tooltip>
            <div className="text-sm text-gray-600">
              {formatTimeFrame(metric.timeFrame)}
            </div>
            <div>
              {metric.count} / {metric.target}
            </div>
            {metric.includeAmount && activeAuthorizations.hasOwner && (
              <div className="text-sm text-gray-600">
                ${metric.currentAmount?.toLocaleString()} / $
                {metric.amountTarget?.toLocaleString()}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MilestoneMetrics;
