import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Project } from '../models/general';
import { fetchProject } from '../utils/api';
import FileList from '../components/FileList';

const PublicProjectPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadProjectData = async () => {
      if (!projectId) return;

      setIsLoading(true);
      try {
        const projectResponse = await fetchProject(projectId);
        setProject(projectResponse.data);
      } catch (err) {
        setError('Unable to load project information');
        console.error('Failed to fetch project data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadProjectData();
  }, [projectId]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-xl">Loading project information...</div>
      </div>
    );
  }

  if (error || !project) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-xl text-red-600">
          {error || 'Project not found'}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className="border-b border-gray-300 bg-white px-6 py-6 shadow-sm">
            <h2 className="text-3xl font-bold text-gray-900 capitalize mb-2">
              {project.address}
            </h2>
            <p className="text-lg text-gray-600">
              by {project.company.name || 'Company information not available'}
            </p>
          </div>

          {/* Main Content */}
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Project Files Section */}
              <div className="col-span-1">
                <div className="mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    General Files
                  </h3>
                  <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 h-[400px] overflow-auto">
                    <FileList
                      heading="Client Documents"
                      files={project.clientDocuments}
                    />
                    <div className="mt-6">
                      <FileList
                        heading="Work Documents"
                        files={project.workDocuments}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Deliverables Section */}
              <div className="col-span-1">
                <div className="mb-4">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Project Deliverables
                  </h3>
                  <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 h-[400px] overflow-auto">
                    <FileList
                      heading="Deliverables"
                      files={project.deliverableDocuments}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicProjectPage;
