// src/../pages/ProfilePage.tsx

import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeUserState } from '../atoms/user.atom';
import { activeCompanyState } from '../atoms/new.company.atom';
import { updateUser, uploadFile } from '../utils/api';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { compressImage } from '../utils/imageUtils';
import { ProfileImageUpload } from '../components/Profile/ProfileImageUpload';

const ProfilePage = () => {
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const setActiveUser = useSetRecoilState(activeUserState);

  const [formData, setFormData] = useState({
    phone: '',
    email: '',
    firstName: '',
    lastName: '',
    imageUrl: '',
  });

  const [imageState, setImageState] = useState({
    src: null as string | null,
    crop: { unit: '%', width: 100, aspect: 1 } as Crop,
    croppedImageUrl: null as string | null,
  });

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (activeUser) {
      setFormData({
        phone: activeUser.phone || '',
        email: activeUser.email || '',
        firstName: activeUser.firstName || '',
        lastName: activeUser.lastName || '',
        imageUrl: activeUser.imageUrl || '',
      });
    }
  }, [activeUser]);

  const validateImageType = (file: File) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      throw new Error(
        'Invalid file type. Please upload a JPEG, PNG, GIF, or WebP image.',
      );
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      validateImageType(file);

      const reader = new FileReader();
      reader.onload = () => {
        setImageState((prev) => ({
          ...prev,
          src: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const getCroppedImg = async (imageSrc: string, crop: Crop): Promise<Blob> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width;
        canvas.height = crop.width; // Force square aspect ratio

        ctx?.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.width * scaleY,
          0,
          0,
          crop.width,
          crop.width,
        );

        canvas.toBlob(
          (blob) => {
            resolve(blob as Blob);
          },
          'image/jpeg',
          0.9,
        );
      };
    });
  };

  const handleCropComplete = async (crop: Crop) => {
    if (!imageState.src) return;

    try {
      const croppedImageBlob = await getCroppedImg(imageState.src, crop);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      setImageState((prev) => ({
        ...prev,
        croppedImageUrl,
        crop,
      }));
    } catch (err) {
      setError('Failed to crop image');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUploading(true);
    setError(null);

    try {
      let imageUrl = formData.imageUrl;

      // Upload new image if one was selected and cropped
      if (imageState.croppedImageUrl) {
        const response = await fetch(imageState.croppedImageUrl);
        const imageBlob = await response.blob();

        // Compress the image
        const compressedImage = await compressImage(imageBlob, {
          maxWidth: 400,
          maxHeight: 400,
          quality: 0.8,
        });

        // Prepare file upload data
        const file = new File([compressedImage], 'profile-image.jpg', {
          type: 'image/jpeg',
        });
        console.log('file', file);
        // Upload using the same endpoint as project files
        const uploadResponse = await uploadFile(
          activeCompany._id,
          file,
          `${activeUser._id}-profile.jpg`,
          'Profile image',
          'profile-images', // or another designated folder
        );

        imageUrl = uploadResponse.data.url;
      }

      // Update user profile with all form data including new image URL
      const updatedUser = {
        ...activeUser,
        ...formData,
        imageUrl,
      };

      await updateUser(activeUser._id, updatedUser);
      setActiveUser(updatedUser);

      // Clear image editing state
      setImageState({
        src: null,
        crop: { unit: '%', width: 100, aspect: 1 },
        croppedImageUrl: null,
      });
    } catch (err) {
      setError('Failed to update profile');
      console.error(err);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-center mb-8">Profile Settings</h1>

      {error && <div className="text-red-500 text-center mb-4">{error}</div>}

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Profile Image Section */}
        <div className="flex justify-center">
          <ProfileImageUpload
            imageUrl={formData.imageUrl}
            firstName={formData.firstName}
            lastName={formData.lastName}
            onImageSelect={handleImageChange}
            imageState={imageState}
            setImageState={setImageState}
            onCropComplete={handleCropComplete}
          />
        </div>

        {/* Profile Information Section */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, firstName: e.target.value }))
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, lastName: e.target.value }))
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, phone: e.target.value }))
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isUploading}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isUploading ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfilePage;
