import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import letterHead from './../images/letterHead.png';

import { useOutletContext } from 'react-router-dom';
import { Milestone, Project } from '../models/general';
import LetterDisplay from './HTMLPDF';
import Terms from './Exhibits/Terms';
import AdditionalServices from './Exhibits/AdditionalServices';
import { useReactToPrint } from 'react-to-print';
import { toolbarOptions } from './quill/toolbar';
import './quill/customQuillFormats';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeUserState } from '../atoms/user.atom';
import { formatCurrency } from '../utils/misc';
import { activeCompanyState } from '../atoms/new.company.atom';
import { lOETotals, milestonesState } from '../atoms/project.atom';
import {
  createThread,
  fetchMilestonesByProject,
  sendMessage,
  updateMilestone,
  addThreadMessage,
  fetchThreadsByProject,
  createAgreement,
  getCurrentAgreement,
  getProjectAgreements,
  getAgreementDiff,
} from '../utils/api';
import { addDays } from 'date-fns';
import Modal from 'react-modal';

interface OutletContext {
  activeProject: Project;
  updateProject: (id: string, project: Project) => Promise<Project>;
}

// Define custom formats before registering them
class BoldBlot extends Quill.import('blots/inline') {
  static blotName = 'bold';
  static tagName = 'strong';
}
Quill.register('formats/bold', BoldBlot);

// Define modules configuration
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

// Define allowed formats
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
];

interface AgreementVersion {
  _id: string;
  content: string;
  version: number;
  createdBy: {
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  changeDescription?: string;
}

function EditableAgreement({ initialContent }) {
  const [editorContent, setEditorContent] = useState(initialContent);
  const [versions, setVersions] = useState<AgreementVersion[]>([]);
  const [showVersionHistory, setShowVersionHistory] = useState(false);
  const [selectedVersions, setSelectedVersions] = useState<string[]>([]);
  const [versionDiff, setVersionDiff] = useState<any[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [changeDescription, setChangeDescription] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const totals = useRecoilValue(lOETotals);
  const targetRef = useRef();
  const { activeProject, updateProject } = useOutletContext<OutletContext>();
  const [activeMilestones, setActiveMilestones] =
    useRecoilState(milestonesState);
  const [selectedVersion, setSelectedVersion] =
    useState<AgreementVersion | null>(null);
  const [isRollbackModalOpen, setIsRollbackModalOpen] = useState(false);

  useEffect(() => {
    if (activeProject?._id) {
      loadAgreementVersions();
    }
  }, [activeProject?._id]);

  const loadAgreementVersions = async () => {
    try {
      const response = await getProjectAgreements(activeProject._id);
      setVersions(response.data);
    } catch (error) {
      console.error('Failed to load agreement versions:', error);
    }
  };

  const handleSaveClick = () => {
    setIsConfirmModalOpen(true);
  };

  const saveUpdates = async () => {
    if (!changeDescription.trim()) {
      return;
    }

    setIsSaving(true);
    try {
      // Create new agreement version
      const agreementResponse = await createAgreement({
        content: editorContent,
        projectId: activeProject._id,
        createdBy: activeUser._id,
        changeDescription,
      });

      const newAgreementId = agreementResponse.data._id;

      // Update project with new agreement reference
      const updatedProject = {
        ...activeProject,
        currentAgreement: newAgreementId,
        agreementHistory: [
          ...(activeProject.agreementHistory || []),
          newAgreementId,
        ],
      };

      await updateProject(activeProject._id, updatedProject);
      await loadAgreementVersions();

      setChangeDescription('');
      setIsConfirmModalOpen(false);
      alert('Agreement saved successfully!');
    } catch (error) {
      console.error('Failed to save agreement:', error);
      alert('Failed to save agreement. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCompareVersions = async () => {
    if (selectedVersions.length !== 2) return;

    try {
      const diff = await getAgreementDiff(
        selectedVersions[0],
        selectedVersions[1],
      );
      setVersionDiff(diff.data);
    } catch (error) {
      console.error('Failed to get diff:', error);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');

  const openModal = () => {
    if (activeUser && activeProject) {
      const defaultMessage = `
        ${activeProject.client.owner.firstName}, I hope this email finds you well. Please find your Proposal for ${activeProject.address} at the link below. Feel free to reach out if you have any questions or would like to discuss it. Assuming things look good, please sign the Letter agreement and we will send out a deposit invoice that can be paid digitally.

As soon as we receive the deposit we will get your project on schedule.

We are looking forward to working with you.

Sincerely,
${activeUser.firstName} ${activeUser.lastName}
      `;
      setMessageContent(defaultMessage);
      setShowModal(true);
    } else {
      console.error('User or project information is missing');
    }
  };

  const closeModal = () => setShowModal(false);

  const handleSendMessage = async () => {
    try {
      if (!activeCompany._id) throw Error('Company not found');
      if (!activeProject._id) throw Error('Project not found');

      // Create or find the Letter Agreement thread
      const threadsResponse = await fetchThreadsByProject(activeProject._id);
      let thread = threadsResponse.data?.find(
        (t: any) => t.name === 'Client Communication',
      );

      // Create thread if it doesn't exist
      if (!thread) {
        const createResponse = await createThread({
          name: 'Client Communication',
          companyId: activeCompany._id,
          projectId: activeProject._id,
          type: 'project',
          members: [
            ...(activeProject.client?.owner?._id
              ? [activeProject.client.owner._id]
              : []),
            ...(activeProject.contacts?.map((contact) => contact._id) || []),
          ],
        });
        thread = createResponse.data;
      }

      if (!thread) throw Error('Failed to select thread');

      // Save the current agreement state before sending
      const agreementResponse = await createAgreement({
        content: editorContent,
        projectId: activeProject._id,
        createdBy: activeUser._id,
        changeDescription: 'Letter Agreement Sent to Client',
      });

      const newAgreementId = agreementResponse.data._id;

      // Send message with notification
      await addThreadMessage(thread._id, {
        content: messageContent,
        notify: true,
        isLetterAgreement: true,
      });

      setShowModal(false);

      // Update project with new agreement and status
      const updatedProject = {
        ...activeProject,
        workFlowStatus: 'letter agreement - sent',
        currentAgreement: newAgreementId,
        agreementHistory: [
          ...(activeProject.agreementHistory || []),
          newAgreementId,
        ],
      };
      await updateProject(activeProject._id, updatedProject);

      // Update milestones
      for (const milestone of activeMilestones || []) {
        console.log('milestone', milestone);
        if (!milestone._id) {
          alert('OLD MILESTONE FOUND, changes not saved');
          return;
        }

        if (
          milestone.title === 'Estimate Completed' &&
          !milestone.completionDate
        ) {
          console.log('updating milestone', milestone);
          await updateMilestone(milestone._id, {
            completionDate: new Date(),
          });
        } else if (milestone.title === 'Letter Agreement Sent') {
          await updateMilestone(milestone._id, {
            completionDate: new Date(),
          });
        } else if (milestone.title === 'Letter Agreement Signed') {
          await updateMilestone(milestone._id, {
            targetDate: addDays(new Date(), 7),
          });
        }
      }

      // Refresh milestones
      try {
        const updatedResponse = await fetchMilestonesByProject(
          activeProject._id,
        );
        setActiveMilestones(updatedResponse.data);
      } catch (e) {
        console.error('Failed to update Milestones:', e);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  const handleSignedAgreement = async (action: string) => {
    const updatedProject = {
      ...activeProject,
      workFlowStatus:
        action === 'signed' ? 'letter agreement - signed' : 'closed - lost',
    };
    updateProject(activeProject._id, updatedProject);

    // Update milestones
    for (const milestone of activeMilestones || []) {
      console.log('milestone', milestone);
      if (!milestone._id) {
        alert('OLD MILESTONE FOUND, changes not saved');
        return;
      }

      if (milestone.title === 'Letter Agreement Signed') {
        await updateMilestone(milestone._id, {
          completionDate: new Date(),
        });
      } else if (milestone.title === 'Deposit Sent') {
        await updateMilestone(milestone._id, {
          targetDate: addDays(new Date(), 1),
        });
      }
    }
  };
  const handleReOpenEstimate = () => {
    const updatedProject = {
      ...activeProject,
      workFlowStatus: 'letter agreement - sent',
    };
    updateProject(activeProject._id, updatedProject);
  };
  const handlePrintAndUpdate = () => {
    const updatedProject = {
      ...activeProject,
      workFlowStatus: 'letter agreement - sent',
      agreementContent: editorContent,
    };
    updateProject(activeProject._id, updatedProject);
    // Print the content
    if (targetRef && targetRef.current) {
      handlePrint();
    }
  };

  // This is the packaged print function this needs to replace the other methods. Will also need to apply some styling fixes coming from the text editor
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  const handleReprint = () => {
    // Print the content
    if (targetRef && targetRef.current) {
      handlePrint();
    }
  };
  const doesNeedLetter =
    activeProject.workFlowStatus === 'estimate - finished' ||
    activeProject.workFlowStatus === 'letter agreement - sent';
  const isEstimating =
    activeProject.workFlowStatus === 'estimating' ||
    activeProject.workFlowStatus === 'initiated';
  const isClosedAgreement =
    activeProject.workFlowStatus !== 'initiated' &&
    activeProject.workFlowStatus !== 'estimating' &&
    activeProject.workFlowStatus !== 'estimate - finished' &&
    activeProject.workFlowStatus !== 'letter agreement - sent';

  useEffect(() => {
    setEditorContent(initialContent);
  }, [initialContent]);

  // Add rollback function
  const handleRollback = async () => {
    if (!selectedVersion) return;

    setIsSaving(true);
    try {
      // Create new agreement version using selected version's content
      const agreementResponse = await createAgreement({
        content: selectedVersion.content,
        projectId: activeProject._id,
        createdBy: activeUser._id,
        changeDescription: `Rolled back to version ${selectedVersion.version}`,
      });

      const newAgreementId = agreementResponse.data._id;

      // Update project with new agreement reference
      const updatedProject = {
        ...activeProject,
        currentAgreement: newAgreementId,
        agreementHistory: [
          ...(activeProject.agreementHistory || []),
          newAgreementId,
        ],
      };

      await updateProject(activeProject._id, updatedProject);
      await loadAgreementVersions();

      // Update editor content
      setEditorContent(selectedVersion.content);

      setSelectedVersion(null);
      setIsRollbackModalOpen(false);
      setShowVersionHistory(false);
      alert('Successfully rolled back to previous version');
    } catch (error) {
      console.error('Failed to rollback version:', error);
      alert('Failed to rollback. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return activeProject.signedContracts &&
    activeProject.signedContracts.length > 0 ? (
    <div className="container mx-auto my-4 p-4 rounded">
      <LetterDisplay content={activeProject.signedContracts[0]} />
    </div>
  ) : (
    <>
      {doesNeedLetter && (
        <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
          <h1 className="text-xl font-bold mb-4">Letter Agreement Editor</h1>

          {/* Version and action controls */}
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <button
                onClick={handleSaveClick}
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded font-medium"
              >
                Save Progress
              </button>
              <button
                onClick={() => setShowVersionHistory(true)}
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded flex items-center gap-2"
              >
                <span>Version History</span>
                <span className="text-sm">({versions.length})</span>
              </button>
            </div>

            {/* Action buttons */}
            <div className="grid grid-cols-4 gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded font-medium"
                onClick={openModal}
              >
                Send Agreement
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded font-medium"
                onClick={() => handleSignedAgreement('signed')}
              >
                Mark as Signed
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded font-medium"
                onClick={() => handleSignedAgreement('lost')}
              >
                Mark as Closed
              </button>
            </div>
          </div>

          {/* Editor */}
          <div className="mb-4">
            <ReactQuill
              value={editorContent}
              onChange={setEditorContent}
              modules={modules}
              formats={formats}
              theme="snow"
            />
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded font-medium"
                onClick={() => handleReprint()}
              >
                Print Preview
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
        {isEstimating && (
          <h1 className="text-center text-red-700">
            Estimate Still In Process - Confirm Estimate to Edit and Print
            Letter Agrement
          </h1>
        )}
        {isClosedAgreement && (
          <>
            <button
              className="rounded bg-gray-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2"
              onClick={() => handleReOpenEstimate()}
            >
              ReOpen Contract
            </button>
            <button
              className="rounded bg-blue-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2 mx-2"
              onClick={() => handleReprint()}
            >
              Reprint Print PDF
            </button>
          </>
        )}
      </div>

      <div ref={targetRef} className="quill">
        <div className=" bg-white p-6 rounded-lg shadow-lg mt-2">
          <img
            className="w-100 inline"
            src={letterHead}
            alt="Abacus Engineering Inc."
          ></img>
          <LetterDisplay content={editorContent} />
          <h2 className="font-bold text-2xl">Fee Schedule</h2>
          <br />
          <div className="mx-6">
            {activeProject.disciplines.map((discipline: string) => (
              <>
                <div className="grid grid-cols-2">
                  <p>{discipline.toUpperCase()} Design & Drawings</p>
                  <p>{formatCurrency(totals[discipline])}</p>
                </div>
              </>
            ))}
            <div className="grid grid-cols-2">
              <p className="font-bold">Total</p>
              <p className="font-bold">{formatCurrency(totals?.total)}</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="grid grid-cols-2">
            <div className="col-span-1">
              <h3>Client: {activeProject.client?.name}</h3>
              <p>
                Representative: {activeProject.client?.owner?.firstName}{' '}
                {activeProject.client?.owner?.lastName}
              </p>
              <br />
              <p>Signature: _________________________</p>
              <p>Date : ______________________</p>
            </div>
            <div className="col-span-1">
              <h3>Engineer: {activeProject.company?.name}</h3>
              <p>
                Representative: {activeUser?.firstName} {activeUser?.lastName}
              </p>
              <br />
              <p>Signature: _________________________</p>
              <p>Date : ______________________</p>
            </div>
          </div>
          <br />
          <br />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
          <Terms />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
          <AdditionalServices />
        </div>
        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg w-1/2">
              <h2 className="text-2xl font-semibold mb-4">
                Send Letter Agreement
              </h2>
              <h2 className="text-xl font-semibold mb-4">
                To: {activeProject.client.email}
              </h2>
              <p>Message:</p>
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                rows={10}
                className="w-full p-2 border rounded mb-4"
                placeholder="Edit the message content here..."
              />
              <div className="flex justify-end">
                <button
                  onClick={closeModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSendMessage}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Version History Modal */}
      {showVersionHistory && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-3/4 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between mb-4">
              <h2 className="text-2xl font-semibold">Version History</h2>
              <button
                onClick={() => setShowVersionHistory(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>

            <div className="mb-4">
              {versions.map((version) => (
                <div
                  key={version._id}
                  className="flex items-center justify-between mb-2 p-2 hover:bg-gray-100 rounded"
                >
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedVersions.includes(version._id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedVersions(
                            [...selectedVersions, version._id].slice(-2),
                          );
                        } else {
                          setSelectedVersions(
                            selectedVersions.filter((id) => id !== version._id),
                          );
                        }
                      }}
                      className="mr-2"
                    />
                    <div>
                      <div className="font-medium">
                        Version {version.version} -{' '}
                        {new Date(version.createdAt).toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-600">
                        By: {version.createdBy.firstName}{' '}
                        {version.createdBy.lastName}
                      </div>
                      {version.changeDescription && (
                        <div className="text-sm text-gray-500">
                          Changes: {version.changeDescription}
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setShowVersionHistory(false);
                      setSelectedVersion(version);
                      setIsRollbackModalOpen(true);
                    }}
                    className="px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
                  >
                    Rollback
                  </button>
                </div>
              ))}
            </div>

            {selectedVersions.length === 2 && (
              <button
                onClick={handleCompareVersions}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Compare Selected Versions
              </button>
            )}

            {versionDiff.length > 0 && (
              <div className="mt-4 p-4 border rounded">
                <h3 className="font-semibold mb-2">Differences:</h3>
                <div className="space-y-2">
                  {versionDiff.map((part, index) => (
                    <div
                      key={index}
                      className={`p-2 rounded ${
                        part.added
                          ? 'bg-green-100 text-green-800 border-l-4 border-green-500'
                          : part.removed
                            ? 'bg-red-100 text-red-800 border-l-4 border-red-500'
                            : 'bg-gray-50'
                      }`}
                    >
                      {part.value}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Rollback Confirmation Modal */}
      <Modal
        isOpen={isRollbackModalOpen}
        onRequestClose={() => !isSaving && setIsRollbackModalOpen(false)}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">Confirm Rollback</h2>
        <div className="mb-4">
          <p className="text-gray-700">
            Are you sure you want to roll back to version{' '}
            {selectedVersion?.version}? This will create a new version with the
            content from version {selectedVersion?.version}.
          </p>
          <p className="text-sm text-gray-500 mt-2">
            Created by: {selectedVersion?.createdBy.firstName}{' '}
            {selectedVersion?.createdBy.lastName}
            <br />
            Date:{' '}
            {selectedVersion &&
              new Date(selectedVersion.createdAt).toLocaleString()}
          </p>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => setIsRollbackModalOpen(false)}
            disabled={isSaving}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleRollback}
            disabled={isSaving}
            className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 disabled:opacity-50"
          >
            {isSaving ? 'Rolling back...' : 'Confirm Rollback'}
          </button>
        </div>
      </Modal>

      {/* Save Changes Modal */}
      <Modal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => !isSaving && setIsConfirmModalOpen(false)}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">Save Changes</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Describe your changes
          </label>
          <textarea
            value={changeDescription}
            onChange={(e) => setChangeDescription(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
            rows={4}
            placeholder="What changes did you make to the agreement?"
            required
          />
        </div>
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => setIsConfirmModalOpen(false)}
            disabled={isSaving}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={saveUpdates}
            disabled={!changeDescription.trim() || isSaving}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default EditableAgreement;
