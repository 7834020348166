import { Routes, Route, Navigate } from 'react-router-dom';
import Main from './pages/HomePage';
import OriginalLayout from './layouts/OriginalLayout';
import LevelOfEffort from './pages/LevelOfEffort';
import Projects from './pages/ProjectsPage';
import ProjectView from './pages/ProjectPage';
import LOE from './pages/LevelOfEffort';
import Agreement from './pages/AgreementPage';
import Signup from './pages/SignUp';
import Signin from './pages/SignIn';
import PrivateRoute from './components/PrivateRoute';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import ComingSoon from './pages/ComingSoon';
import SignAgreementPage from './pages/SignAgreementPage';
import CreateProjectPage from './pages/CreateProjectPage';
import GuestPage from './pages/GuestPage';
import EmailLoginPage from './pages/EmailLoginPage';
import CompanyManagementPage from './pages/CompanyManagementPage';
import Schedule from './pages/SchedulePage';
import RequestPasswordResetPage from './pages/RequestPasswordResetPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import PublicProjectPage from './pages/PublicProjectPage';
import { Suspense } from 'react';
import ProjectPage from './pages/ProjectPage';
import { ProjectDetails } from './pages/ProjectDetails';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/signup"
          element={
            <OriginalLayout>
              <Signup />
            </OriginalLayout>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/request-password-reset"
          element={<RequestPasswordResetPage />}
        />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/emaillogin" element={<EmailLoginPage />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/guest" element={<GuestPage />} />
          <Route
            path="/companies"
            element={
              <OriginalLayout>
                <CompanyManagementPage />
              </OriginalLayout>
            }
          />
          <Route
            path="/project"
            element={
              <OriginalLayout>
                <CreateProjectPage />
              </OriginalLayout>
            }
          />
          <Route
            path="/project/:projectId"
            element={
              <OriginalLayout>
                <ProjectView />
              </OriginalLayout>
            }
          >
            <Route path="" element={<ProjectDetails />} />
            <Route path="loe" element={<LOE />} />
            <Route path="agreement" element={<Agreement />} />
            <Route path="schedule" element={<Schedule />} />
          </Route>
          <Route
            path="profile"
            element={
              <OriginalLayout>
                <ProfilePage />
              </OriginalLayout>
            }
          />
          <Route
            path="/projectList"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <OriginalLayout>
                  <Projects />
                </OriginalLayout>
              </Suspense>
            }
          />
          <Route
            path="sign-agreement/:projectId"
            element={<SignAgreementPage />}
          />
          <Route
            path="/"
            element={
              <OriginalLayout>
                <Suspense fallback={<div>Loading...</div>}>
                  <Main />
                </Suspense>
              </OriginalLayout>
            }
          />
        </Route>

        <Route
          path="/projects/public/:projectId"
          element={<PublicProjectPage />}
        />

        <Route path="*" element={<Navigate to="/login" />} />

        <Route path="/projects/:projectId/*" element={<ProjectPage />}>
          <Route index element={<ProjectDetails />} />
          <Route path="loe" element={<LOE />} />
          <Route path="agreement" element={<Agreement />} />
          <Route path="schedule" element={<Schedule />} />
        </Route>
      </Routes>
    </>
  );
};
export default AppRoutes;
