import { atom } from 'recoil';
import { Leave } from '../models/leave';

export const leavesState = atom<Leave[]>({
  key: 'leavesState',
  default: [],
});

export const pendingLeavesState = atom<Leave[]>({
  key: 'pendingLeavesState',
  default: [],
});
