import React, { useEffect, useState } from 'react';
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { uploadFile, addProjectChatFile } from '../../utils/api';

interface ThreadFileUploadProps {
  projectId?: string;
  companyId: string;
  onFileUpload: (fileInfo: any) => void;
  onError: (error: string) => void;
  pendingFile?: any;
}

export const ThreadFileUpload: React.FC<ThreadFileUploadProps> = ({
  projectId,
  companyId,
  onFileUpload,
  onError,
  pendingFile,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setSelectedFile(file);

    // Create preview for images
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    handleUpload();
  }, [selectedFile]);

  useEffect(() => {
    if (!pendingFile) {
      setSelectedFile(null);
      setPreview(null);
    }
  }, [pendingFile]);

  const handleUpload = async () => {
    if (!selectedFile) return;
    try {
      setIsUploading(true);

      const uploadResponse = await uploadFile(
        companyId,
        selectedFile,
        selectedFile.name,
        'Chat file upload',
        projectId,
      );

      if (projectId) {
        await addProjectChatFile(projectId, {
          url: uploadResponse.data.url,
          name: selectedFile.name,
          mimetype: selectedFile.type,
        });
      }

      const fileData = [
        {
          url: uploadResponse.data.url,
          name: selectedFile.name,
          type: selectedFile.type,
          size: selectedFile.size,
        },
      ];

      onFileUpload(fileData);
    } catch (error) {
      onError('Failed to upload file');
      clearSelection();
    } finally {
      setIsUploading(false);
    }
  };

  const clearSelection = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  return (
    <div className="mt-2">
      {!selectedFile ? (
        <label className="cursor-pointer inline-flex items-center gap-2 text-gray-500 hover:text-gray-700">
          <PaperClipIcon className="w-5 h-5" />
          <input
            type="file"
            className="hidden"
            onChange={handleFileSelect}
            accept="image/*,.pdf,.doc,.docx"
          />
          Attach file
        </label>
      ) : (
        <div className="relative">
          {preview && (
            <div className="relative inline-block">
              <img
                src={preview}
                alt="Preview"
                className="max-h-32 rounded-lg"
              />
              <button
                onClick={clearSelection}
                className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
