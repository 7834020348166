// src/components/TeamManagement.tsx

import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { companyMembersState } from '../atoms/company.members.atom';
import { activeAuths } from '../atoms/user.atom';
import { Project, User } from '../models/general';
import { Switch, Radio } from 'antd';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import {
  createUser,
  fetchUsersByCompany,
  createProjectUser,
} from '../utils/api';
import { Link } from 'react-router-dom';

interface Props {
  project: Project;
  companyId: string;
  updateProject: (project: Project) => Promise<void>;
}

// Add phone validation helper
const isValidPhone = (phone: string) => {
  // Allow formats: (555) 555-5555, 555-555-5555, 5555555555
  const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(phone);
};

const TeamManagement: React.FC<Props> = ({
  project,
  companyId,
  updateProject,
}) => {
  const [companyMembers, setCompanyMembers] =
    useRecoilState(companyMembersState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const [loading, setLoading] = useState(false);
  const [clientMembers, setClientMembers] = useState<User[]>([]);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [newUserForm, setNewUserForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  // Add debug logging
  useEffect(() => {
    console.log('Company Members:', companyMembers);
    console.log('Company ID:', companyId);
  }, [companyMembers, companyId]);

  // Fetch company members if not already loaded
  useEffect(() => {
    const loadCompanyMembers = async () => {
      if (!companyId || companyMembers.length > 0) return;

      try {
        const response = await fetchUsersByCompany(companyId);
        setCompanyMembers(response.data);
      } catch (error) {
        console.error('Failed to fetch company members:', error);
      }
    };

    loadCompanyMembers();
  }, [companyId, companyMembers.length, setCompanyMembers]);

  // Fetch client members
  useEffect(() => {
    const loadClientMembers = async () => {
      if (!project.client?._id) return;

      try {
        const response = await fetchUsersByCompany(project.client._id);
        setClientMembers(response.data);
        console.log('Client Members:', response.data);
      } catch (error) {
        console.error('Failed to fetch client members:', error);
        setClientMembers([]);
      }
    };

    loadClientMembers();
  }, [project.client?._id]);

  const handleToggleMember = async (userId: string, isInternal: boolean) => {
    setLoading(true);
    try {
      const currentContacts = (project.contacts || []).map((contact) =>
        typeof contact === 'string' ? contact : contact._id,
      );

      let updatedContacts;
      if (currentContacts.includes(userId)) {
        updatedContacts = currentContacts.filter((id) => id !== userId);
      } else {
        updatedContacts = [...currentContacts, userId];
      }

      await updateProject({
        ...project,
        contacts: updatedContacts,
      });
    } catch (error) {
      console.error('Failed to update team member:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleAll = async (isInternal: boolean) => {
    setLoading(true);
    try {
      const currentContactIds = (project.contacts || []).map((contact) =>
        typeof contact === 'string' ? contact : contact._id,
      );

      // Get relevant member IDs based on internal/external
      const memberList = isInternal ? companyMembers : clientMembers;
      const allMemberIds = memberList.map((member) => member._id);

      // If all members are already selected, remove all. Otherwise, add all.
      const areAllSelected = allMemberIds.every((id) =>
        currentContactIds.includes(id),
      );

      // Keep the other group's members while updating current group
      const otherGroupMembers = currentContactIds.filter((id) =>
        isInternal
          ? clientMembers.some((member) => member._id === id)
          : companyMembers.some((member) => member._id === id),
      );

      const updatedContacts = areAllSelected
        ? otherGroupMembers
        : [...otherGroupMembers, ...allMemberIds];

      await updateProject({
        ...project,
        contacts: updatedContacts,
      });
    } catch (error) {
      console.error('Failed to update team members:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateClientUser = async () => {
    if (!project.client?._id || !isValidPhone(newUserForm.phone)) return;

    try {
      setLoading(true);
      const cleanPhone = newUserForm.phone.replace(/\D/g, '');

      const response = await createProjectUser({
        ...newUserForm,
        phone: cleanPhone,
        username: newUserForm.email,
        active: true,
        company: project.client._id,
        userType: 'Employee',
        projectId: project._id,
        projectName: project.name,
        companyName: project.client.name,
      });

      // Add new user to client members
      setClientMembers([...clientMembers, response.data]);

      // Add new user to project contacts
      await handleToggleMember(response.data._id, false);

      // Reset form and close modal
      setNewUserForm({ firstName: '', lastName: '', email: '', phone: '' });
      setIsCreatingUser(false);
    } catch (error) {
      console.error('Failed to create client user:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLeadChange = async (userId: string) => {
    setLoading(true);
    try {
      await updateProject({
        ...project,
        lead: userId,
      });
    } catch (error) {
      console.error('Failed to update project lead:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderMemberList = (members: User[], isInternal: boolean) => (
    <div className="space-y-2 h-full">
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-semibold text-gray-700">
          {isInternal ? 'Internal Team Members' : 'Client Team Members'}
        </h3>
        <button
          onClick={() => handleToggleAll(isInternal)}
          className="text-sm text-blue-600 hover:text-blue-800"
          disabled={loading}
        >
          {members.every((member) =>
            project.contacts?.some(
              (contact) =>
                (typeof contact === 'string' ? contact : contact._id) ===
                member._id,
            ),
          )
            ? 'Remove All'
            : 'Add All'}
        </button>
      </div>
      <div className="space-y-2">
        {members.map((member) => (
          <div
            key={member._id}
            className="flex items-center justify-between p-2 rounded-lg hover:bg-gray-50"
          >
            <div className="flex items-center space-x-3">
              {member.imageUrl ? (
                <UserCircleIcon className="w-8 h-8 text-gray-400" />
              ) : (
                <UserCircleIcon className="w-8 h-8 text-gray-400" />
              )}
              <div>
                <p className="text-sm font-medium text-gray-900">
                  {member.firstName} {member.lastName}
                </p>
                <p className="text-xs text-gray-500">{member.email}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              {/* Only show lead selection for internal members */}
              {isInternal && (
                <Radio
                  checked={project.lead?._id === member._id}
                  onChange={() => handleLeadChange(member._id)}
                  disabled={loading}
                >
                  Lead
                </Radio>
              )}
              <Switch
                checked={project.contacts?.some(
                  (contact) =>
                    (typeof contact === 'string' ? contact : contact._id) ===
                    member._id,
                )}
                onChange={() => handleToggleMember(member._id, isInternal)}
                disabled={loading}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // Update the phone input section in the modal
  const renderPhoneInput = () => (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Phone Number
      </label>
      <input
        type="tel"
        value={newUserForm.phone}
        onChange={(e) => {
          const value = e.target.value;
          // Only allow digits, spaces, parentheses, and hyphens
          if (/^[\d\s()-]*$/.test(value)) {
            setNewUserForm({
              ...newUserForm,
              phone: value,
            });
          }
        }}
        placeholder="(555) 555-5555"
        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
          newUserForm.phone && !isValidPhone(newUserForm.phone)
            ? 'border-red-300'
            : ''
        }`}
      />
      {newUserForm.phone && !isValidPhone(newUserForm.phone) && (
        <p className="mt-1 text-xs text-red-500">
          Please enter a valid phone number: (555) 555-5555
        </p>
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      {renderMemberList(companyMembers, true)}
      {clientMembers.length > 0 && (
        <>
          <div className="border-t border-gray-200" />
          <div className="space-y-4">
            {renderMemberList(clientMembers, false)}
            <button
              onClick={() => setIsCreatingUser(true)}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Add New Client User
            </button>
          </div>
        </>
      )}

      {/* Modal for creating new client user */}
      {isCreatingUser && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Add New Client User
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  type="text"
                  value={newUserForm.firstName}
                  onChange={(e) =>
                    setNewUserForm({
                      ...newUserForm,
                      firstName: e.target.value,
                    })
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  type="text"
                  value={newUserForm.lastName}
                  onChange={(e) =>
                    setNewUserForm({ ...newUserForm, lastName: e.target.value })
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={newUserForm.email}
                  onChange={(e) =>
                    setNewUserForm({ ...newUserForm, email: e.target.value })
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              {renderPhoneInput()}
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setIsCreatingUser(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateClientUser}
                disabled={
                  loading ||
                  !newUserForm.phone ||
                  !isValidPhone(newUserForm.phone) ||
                  !newUserForm.email ||
                  !newUserForm.firstName ||
                  !newUserForm.lastName
                }
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                Create User
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamManagement;
