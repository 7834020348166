import React, { useState } from 'react';
import { Modal, Radio, Select, Input, Upload, message, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { DeliverableType } from '../../models/general';

const { Dragger } = Upload;
const { TextArea } = Input;

interface FileUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (
    files: any[],
    isDeliverable: boolean,
    metadata: any,
  ) => Promise<void>;
  onSuccess?: (newFile: any) => void;
  forcePublic?: boolean;
  filter?: 'all' | 'internal' | 'public' | 'deliverables';
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  onClose,
  onUpload,
  onSuccess,
  forcePublic = false,
  filter,
}) => {
  const [fileType, setFileType] = useState<'regular' | 'deliverable'>(
    filter === 'deliverables' ? 'deliverable' : 'regular',
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [classification, setClassification] = useState<'internal' | 'public'>(
    forcePublic ? 'public' : 'internal',
  );
  const [category, setCategory] = useState('');
  const [customNames, setCustomNames] = useState<{ [key: string]: string }>({});

  // Deliverable specific states
  const [deliverableType, setDeliverableType] =
    useState<DeliverableType>('schematic');
  const [version, setVersion] = useState('');
  const [description, setDescription] = useState('');

  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: number;
  }>({});

  const handleFileListChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
    // Initialize custom names for new files
    const newCustomNames = { ...customNames };
    newFileList.forEach((file: UploadFile) => {
      if (!customNames[file.uid]) {
        newCustomNames[file.uid] = file.name;
      }
    });
    setCustomNames(newCustomNames);
  };

  const handleNameChange = (uid: string, newName: string) => {
    setCustomNames((prev) => ({
      ...prev,
      [uid]: newName,
    }));
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error('Please select at least one file to upload');
      return;
    }

    if (fileType === 'deliverable') {
      if (!version || !deliverableType) {
        message.error('Please fill in all required fields for deliverable');
        return;
      }
    }

    const metadata =
      fileType === 'deliverable'
        ? {
            deliverableType,
            version,
            description,
            classification: 'deliverables',
            status: 'current',
          }
        : {
            classification,
            category,
          };

    try {
      const filesWithCustomNames = fileList.map((file) => ({
        ...file,
        customName: customNames[file.uid] || file.name,
      }));

      // Initialize progress for each file
      const initialProgress = {};
      filesWithCustomNames.forEach((file) => {
        initialProgress[file.uid] = 0;
      });
      setUploadProgress(initialProgress);

      // Upload files one by one to show individual progress
      for (const file of filesWithCustomNames) {
        setUploadProgress((prev) => ({
          ...prev,
          [file.uid]: 10,
        }));

        await onUpload([file], fileType === 'deliverable', metadata);

        setUploadProgress((prev) => ({
          ...prev,
          [file.uid]: 100,
        }));
      }

      message.success('Files uploaded successfully');
      handleReset();
      onClose();
    } catch (error) {
      message.error('Failed to upload files');
    }
  };

  const handleReset = () => {
    setFileList([]);
    setFileType('regular');
    setClassification('internal');
    setCategory('');
    setDeliverableType('schematic');
    setVersion('');
    setDescription('');
    setCustomNames({});
  };

  const getFilePreview = (file: UploadFile) => {
    if (file.type?.startsWith('image/')) {
      return (
        <div className="w-10 h-10 mr-2">
          <img
            src={URL.createObjectURL(file.originFileObj as Blob)}
            alt={file.name}
            className="w-full h-full object-cover rounded"
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      title="Upload Files"
      open={isOpen}
      onCancel={onClose}
      onOk={handleUpload}
      width={600}
      okText="Upload"
      destroyOnClose
    >
      <div className="space-y-4">
        {!filter && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              File Type
            </label>
            <Radio.Group
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
            >
              <Radio.Button value="regular">Regular File</Radio.Button>
              <Radio.Button value="deliverable">Deliverable</Radio.Button>
            </Radio.Group>
          </div>
        )}

        {!forcePublic && fileType !== 'deliverable' && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Classification
            </label>
            <Radio.Group
              value={classification}
              onChange={(e) => setClassification(e.target.value)}
            >
              <Radio.Button value="internal">Internal</Radio.Button>
              <Radio.Button value="public">Public</Radio.Button>
            </Radio.Group>
          </div>
        )}

        <Dragger
          multiple
          fileList={fileList}
          onChange={handleFileListChange}
          beforeUpload={() => false}
          className="mb-4"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag files to this area to upload
          </p>
        </Dragger>

        {fileList.length > 0 && (
          <div className="space-y-2">
            <h4 className="font-medium">File Names</h4>
            {fileList.map((file) => (
              <div key={file.uid} className="space-y-2">
                <div className="flex items-center gap-2">
                  {getFilePreview(file)}
                  <div className="flex-grow">
                    <Input
                      value={customNames[file.uid] || file.name}
                      onChange={(e) =>
                        handleNameChange(file.uid, e.target.value)
                      }
                      placeholder="Enter file name"
                    />
                  </div>
                  <span className="text-xs text-gray-500">
                    .{file.name.split('.').pop()}
                  </span>
                </div>
                {uploadProgress[file.uid] > 0 && (
                  <Progress percent={uploadProgress[file.uid]} size="small" />
                )}
              </div>
            ))}
          </div>
        )}

        {fileType === 'deliverable' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Deliverable Type *
              </label>
              <Select
                value={deliverableType}
                onChange={setDeliverableType}
                className="w-full"
              >
                <Select.Option value="schematic">Schematic</Select.Option>
                <Select.Option value="permit-set">Permit Set</Select.Option>
              </Select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Version *
              </label>
              <Input
                value={version}
                onChange={(e) => setVersion(e.target.value)}
                placeholder="e.g., v1.0"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <TextArea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description"
                rows={3}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FileUploadModal;
