import React from 'react';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { Task as WorkTask } from '../../models/general';
import { updateTask } from '../../utils/api';

interface UserGanttChartProps {
  tasks: WorkTask[];
  setTasks: (tasks: WorkTask[]) => void;
  onTaskDoubleClick: (task: WorkTask) => void;
  // getColorForProject: (projectId: string) => string; // Color function passed from AssigneeTaskList
  isViewOnly?: boolean;
}

const UserGanttChart: React.FC<UserGanttChartProps> = ({
  tasks,
  onTaskDoubleClick,
  setTasks,
  // getColorForProject,
  isViewOnly,
}) => {
  const convertTasks = (tasks: WorkTask[]): Task[] => {
    // Sort tasks by start date in ascending order
    const sortedTasks = tasks.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    );

    // Convert sorted tasks to Gantt tasks
    return sortedTasks.map((task) => {
      let progress = 0;

      // Set progress based on the task status
      switch (task.status) {
        case 'Done':
          progress = 100;
          break;
        case 'Blocked':
          progress = 0;
          break;
        case 'In Progress':
          progress = 25;
          break;
        case 'In Review':
          progress = 75;
          break;
        default:
          progress = 0;
          break;
      }

      // Use project color for task background if available
      // const projectColor = getColorForProject(task.project?._id || '');
      // const styles = {
      //   backgroundColor: projectColor,
      //   border: `1px solid ${projectColor}`,
      // };

      // Update task name to display scope in uppercase followed by title
      const taskName = `${task.project?.name?.toUpperCase() || 'GENERAL'}: ${
        task.scope || 'GENERAL'
      } - ${task.title} - ${task.status}`;

      return {
        id: task._id || '1',
        name: taskName,
        start: new Date(task.startDate) || new Date(),
        end:
          new Date(
            new Date(task.startDate).getTime() +
              task.duration * 24 * 60 * 60 * 1000,
          ) || new Date(),
        type: 'task',
        progress: progress,
        dependencies: [],
      };
    });
  };

  const handleGanttDoubleClick = (ganttTask: Task) => {
    const correspondingTask = tasks.find((t) => t._id === ganttTask.id);
    if (correspondingTask) {
      onTaskDoubleClick(correspondingTask);
    }
  };

  const handleTaskChange = async (updatedTask: Task) => {
    const correspondingTask = tasks.find((t) => t._id === updatedTask.id);
    if (correspondingTask) {
      correspondingTask.startDate = updatedTask.start;
      delete correspondingTask.__v;
      try {
        setTasks(tasks);
        console.log('correspondingTask', correspondingTask);
        const response = await updateTask(
          correspondingTask._id,
          correspondingTask,
        );
        const newTasks = tasks.map((task) =>
          task._id === correspondingTask._id ? response.data : task,
        );
        setTasks(newTasks);
      } catch (error) {
        console.error('Failed to update task status', error);
      }
    }
  };

  return (
    <Gantt
      tasks={convertTasks(tasks)}
      viewMode={ViewMode.Day}
      onDoubleClick={handleGanttDoubleClick}
      onDateChange={handleTaskChange}
      listCellWidth={'0'}
      todayColor="#909090"
    />
  );
};

export default UserGanttChart;
