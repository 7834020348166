import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { format } from 'date-fns';
import { activeCompanyState } from '../atoms/new.company.atom';
import { useEffect, useState } from 'react';
import { projectListState } from '../atoms/project.atom';
import {
  fetchProjectsByClient,
  fetchProjectsByCompany,
  fetchTasksByProject,
  updateProject,
} from '../utils/api';
import { activeUserState } from '../atoms/user.atom';
import { clearToken } from '../utils/tokenManager';
import { useNavigate } from 'react-router-dom';
import FileUploadModal from '../components/FileUploadComponent';
import FileList from '../components/Dashboard/FileList';
import MilestoneList from '../components/MilestoneList';
import { Project, Task } from '../models/general';
import { Modal, Tooltip, message, Tabs } from 'antd';
import MilestoneListView from '../components/MilestoneListView';
import ProjectGanttChart from '../components/Projects/ProjectGanttChart';
import Joyride, { Step } from 'react-joyride';
import { useLocalStorage } from '../hooks/useLocalStorage';
import ThreadList from '../components/Thread/ThreadList';

const GuestPage = () => {
  const setActiveUser = useSetRecoilState(activeUserState);
  const setActiveCompany = useSetRecoilState(activeCompanyState);
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [tasks, setTasks] = useState([] as Task[]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activeCompany = useRecoilValue(activeCompanyState);
  const [projects, setProjects] = useRecoilState(projectListState);
  const [activeProject, setActiveProject] = useState({} as Project);
  const [hasSeenTour, setHasSeenTour] = useLocalStorage(
    'guest-tour-completed',
    false,
  );
  const [runTour, setRunTour] = useState(false);
  const [activeTabMap, setActiveTabMap] = useState<{ [key: string]: string }>(
    {},
  );

  useEffect(() => {
    console.log('hasSeenTour:', hasSeenTour);
    if (projects.length > 0) {
      const timer = setTimeout(() => {
        console.log('Setting runTour to:', !hasSeenTour);
        setRunTour(!hasSeenTour);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [hasSeenTour, projects]);

  const tourSteps: Step[] = [
    {
      target: '.project-card',
      content:
        'Welcome to your project dashboard! Here you can view and manage all your active projects.',
      placement: 'center',
      disableBeacon: true,
    },
    {
      target: '.signature-needed-button',
      content:
        'When the letter agreement is ready for your signature, a button will appear here. Click it to review and sign the document.',
      placement: 'bottom',
      spotlightPadding: 5,
    },
    {
      target: '.file-upload-section',
      content: 'Need to share files with us? You can easily upload them here.',
      placement: 'bottom',
    },
    {
      target: '.deliverables-section',
      content:
        'Find all your project deliverables, including plans and documents, in this section.',
      placement: 'left',
    },
    {
      target: '.schedule-button',
      content:
        "Track your project's progress and view the latest schedule here.",
      placement: 'bottom',
    },
  ];

  const handleTourFinish = () => {
    console.log('Tour finished or skipped');
    setHasSeenTour(true);
    setRunTour(false);
  };

  useEffect(() => {
    const fetchCompanyData = async (companyId: string) => {
      console.log('getting company data');
      try {
        const response = await fetchProjectsByCompany(companyId);
        setProjects(response.data.reverse());
      } catch (error) {
        console.error('Failed to fetch projects', error);
      }
    };
    const fetchClientData = async (companyId: string) => {
      console.log('getting client data');
      try {
        const response = await fetchProjectsByClient(companyId);
        setProjects(response.data.reverse());
      } catch (error) {
        console.error('Failed to fetch projects', error);
      }
    };
    if (
      activeCompany &&
      activeCompany.subscriptionLevel &&
      activeCompany.subscriptionLevel !== 'Guest'
    ) {
      fetchCompanyData(activeCompany._id);
    } else if (activeCompany) {
      fetchClientData(activeCompany._id);
    }
  }, [activeCompany]);

  const signOut = async () => {
    try {
      clearToken();
      setActiveUser(null);
      setActiveCompany(null);
      navigate('/');
    } catch (error) {
      console.error('Signin failed', error);
    }
  };

  const handleUploadFileClick = (project: Project) => {
    setSelectedProject(project);
    setIsFileUploadModalOpen(true);
  };

  const fetchProjectTasks = async (projectId: string) => {
    if (!projectId) return;
    try {
      const response = await fetchTasksByProject(projectId);
      setTasks(response.data);
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    }
  };

  const handleFileUploadSuccess = async (fileInfo: any) => {
    let updatedProject = {
      ...selectedProject,
      clientDocuments: [...selectedProject?.clientDocuments, fileInfo],
    };

    try {
      const response = await updateProject(selectedProject._id, updatedProject);
      setSelectedProject(response.data);
      setProjects((projects: any[]) =>
        projects.map((proj) =>
          proj._id === selectedProject._id ? response.data : proj,
        ),
      );
    } catch (error) {
      console.error('Failed to update project', error);
    }
  };

  const handleShareProject = (projectId: string) => {
    const publicUrl = `${window.location.origin}/projects/public/${projectId}`;
    navigator.clipboard.writeText(publicUrl);
    message.success('Project link copied to clipboard!');
  };

  // Helper function to handle tab changes for specific projects
  const handleTabChange = (projectId: string, tabKey: string) => {
    setActiveTabMap((prev) => ({
      ...prev,
      [projectId]: tabKey,
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Joyride
        steps={tourSteps}
        run={runTour}
        continuous
        showProgress
        showSkipButton
        disableOverlayClose
        spotlightClicks
        styles={{
          options: {
            primaryColor: '#2563eb',
            zIndex: 1000,
          },
        }}
        callback={({ status, type }) => {
          console.log('Joyride status:', status, 'type:', type);
          if (['finished', 'skipped'].includes(status)) {
            handleTourFinish();
          }
        }}
      />

      <div className="bg-gray-200">
        <div className="bg-white p-2 flex justify-between">
          <h1 className="text-2xl font-bold">
            {activeCompany.name} - Guest Dashboard
          </h1>
          <button
            onClick={() => signOut()}
            className="px-4 text-sm bg-black text-white font-semibold hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
          >
            Log Out
          </button>
        </div>

        <div className="p-6">
          {projects.map((project: Project) => (
            <div
              key={project._id}
              className="project-card p-4 mb-4 bg-white rounded-lg shadow-md"
            >
              <div className="flex justify-between mb-2">
                <h2 className="text-xl font-semibold capitalize">
                  {project.name} - {project.address}
                </h2>
                <div className="flex gap-2">
                  {project.signedContracts?.length > 0 && (
                    <div className="mb-4">
                      <button
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        onClick={() => {
                          navigate(`/sign-agreement/${project._id}`);
                        }}
                      >
                        View Letter Agreement
                      </button>
                    </div>
                  )}
                  {project.workFlowStatus === 'letter agreement - sent' && (
                    <button
                      className="signature-needed-button px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={() => {
                        navigate(`/sign-agreement/${project._id}`);
                      }}
                    >
                      Signature Needed - Letter Agreement
                    </button>
                  )}

                  <button
                    className="schedule-button px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => {
                      setIsModalVisible(true);
                      setActiveProject(project);
                      fetchProjectTasks(project._id);
                    }}
                  >
                    View Project Schedule
                  </button>
                  <button
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 flex items-center gap-2"
                    onClick={() => handleShareProject(project._id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                    </svg>
                    Share
                  </button>
                </div>
              </div>

              <Tabs
                activeKey={activeTabMap[project._id] || '1'}
                onChange={(key) => handleTabChange(project._id, key)}
                items={[
                  {
                    key: '1',
                    label: 'Project Overview',
                    children: (
                      <div className="grid grid-cols-3">
                        <div className="mx-1">
                          <h2 className="text-l font-semibold capitalize">
                            Milestones
                          </h2>
                          <div className="border-gray-400 h-56 border rounded p-2 overflow-auto">
                            <MilestoneListView project={project} />
                          </div>
                        </div>

                        <div className="file-upload-section mx-1">
                          <h2 className="text-l font-semibold capitalize">
                            Your Files
                          </h2>
                          <div className="border-gray-400 h-56 border rounded p-2 overflow-auto">
                            <FileList
                              project={project}
                              isViewOnly={false}
                              filter="public"
                              forcePublic={true}
                            />
                          </div>
                        </div>

                        <div className="deliverables-section mx-1">
                          <h2 className="text-l font-semibold capitalize">
                            Deliverables
                          </h2>
                          <div className="border-gray-400 h-56 border rounded p-2 overflow-auto">
                            <FileList
                              project={project}
                              isViewOnly={true}
                              filter="deliverables"
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: '2',
                    label: 'Discussions',
                    children: (
                      <div className="p-4">
                        <ThreadList projectId={project._id} />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          ))}

          {selectedProject && (
            <>
              <FileUploadModal
                isGuest={true}
                isOpen={isFileUploadModalOpen}
                onClose={() => setIsFileUploadModalOpen(false)}
                projectId={selectedProject._id}
                companyId={
                  selectedProject.company._id || selectedProject.company
                }
                onFileUploadSuccess={(fileInfo) => {
                  handleFileUploadSuccess(fileInfo);
                }}
              />
            </>
          )}

          <Modal
            title={activeProject.name + ' Project Schedule'}
            open={isModalVisible}
            onCancel={() => {
              setIsModalVisible(false);
              setTasks([] as Task[]);
              setActiveProject({} as Project);
            }}
            onOk={() => {
              setIsModalVisible(false);
              setTasks([] as Task[]);
              setActiveProject({} as Project);
            }}
            width={'95%'}
          >
            {tasks.length > 0 ? (
              <ProjectGanttChart
                tasks={tasks}
                setTasks={() => {
                  return;
                }}
                onTaskDoubleClick={() => {
                  return;
                }}
                isViewOnly={true}
              />
            ) : (
              <h2>Schedule Loading</h2>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default GuestPage;
