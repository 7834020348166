import { atom } from 'recoil';
import { Thread, Message } from '../types/thread.types';

export const threadsState = atom<Thread[]>({
  key: 'threadsState',
  default: [],
});

export const activeThreadState = atom<Thread | null>({
  key: 'activeThreadState',
  default: null,
});

export const threadMessagesState = atom<{ [threadId: string]: Message[] }>({
  key: 'threadMessagesState',
  default: {},
});
