import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeUserState } from '../atoms/user.atom';
import { activeCompanyState } from '../atoms/new.company.atom';
import { useEffect, useState } from 'react';
import {
  clearToken,
  getToken,
  storeLinkToken,
  verifyToken,
} from '../utils/tokenManager';
import { jwtDecode } from 'jwt-decode';
import Modal from './modal';

const PrivateRoute = () => {
  const [activeUser, setActiveUser] = useRecoilState(activeUserState);
  const [linkToken, setLinkToken] = useState<string>();
  const setActiveCompany = useSetRecoilState(activeCompanyState);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = await verifyToken();
      const queryParams = new URLSearchParams(location.search);
      const linkToken = queryParams.get('token');
      console.log('checking Token', linkToken);

      if (linkToken) {
        setLinkToken(linkToken);
        storeLinkToken(linkToken);
        clearToken();
      } else if (token) {
        try {
          const decodedToken: { exp: number; user: any; company: any } =
            jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            setActiveUser(decodedToken.user);
            setActiveCompany(decodedToken.company);
          } else {
            clearToken();
          }
        } catch (error) {
          console.error('Failed to decode token', error);
          clearToken();
        }
      }
      setLoading(false);
    };

    checkAuth();
  }, [setActiveUser, setActiveCompany, location.search]);

  if (loading) {
    // Optionally, return a loading spinner or placeholder
    return (
      <Modal
        isOpen={true}
        onClose={() => {
          return;
        }}
        children={<div>Loading...</div>}
      />
    );
  }

  if (linkToken) {
    return <Navigate to="/emaillogin" state={{ from: location }} />;
  }
  if (!activeUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
