import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { projectListState } from '../../atoms/project.atom';
import { Milestone, Project } from '../../models/general';
import {
  fetchProjectsByClient,
  fetchProjectsByCompany,
  updateProject,
} from '../../utils/api';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { addDays, format, isBefore, isToday } from 'date-fns';

// Import @hello-pangea/dnd components
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// Import classifications from utils/misc.ts
import { classifications } from '../../utils/misc';
import ProjectSchedulePreview from './ProjectSchedulePreview';

// Assuming you have a GanttChart component
// import GanttChart from './GanttChart';

import { workflowPhases, WorkflowPhase } from '../../utils/workstatus';
import { getSignedImageUrl } from '../../utils/imageUrl';
import { toast } from 'react-hot-toast';
import { activeAuths } from '../../atoms/user.atom';
import { Suspense } from 'react';

const phaseOptions = [
  'Prospective Jobs',
  'Pre Work',
  'Drawings In Progress',
  'AHJ Review',
  'Finished or Closed',
  'On Hold',
];

const phaseWorkflowStatusMap = {
  'Prospective Jobs': [
    'initiated',
    'estimating',
    'estimate - finished',
    'letter agreement - sent',
    'letter agreement - signed',
    'Deposit - Sent',
    'Deposit - Paid',
  ],
  'Pre Work': ['scheduling - needs schedule', 'scheduling - waiting to start'],
  'Drawings In Progress': [
    'schematics - in progress',
    'schematics - blocked',
    'schematics - finished',
    'design plans - in progress',
    'design plans - blocked',
    'design plans - finished',
  ],
  'AHJ Review': [
    'AHJ - submitted',
    'AHJ - has comments',
    'AHJ - addressing comments',
    'AHJ - approved',
  ],
  all: [
    'initiated',
    'estimating',
    'estimate - finished',
    'letter agreement - sent',
    'letter agreement - signed',
    'Deposit - Sent',
    'Deposit - Paid',
    'schematics - in progress',
    'schematics - blocked',
    'schematics - finished',
    'design plans - in progress',
    'design plans - blocked',
    'design plans - finished',
    'AHJ - submitted',
    'AHJ - has comments',
    'AHJ - addressing comments',
    'AHJ - approved',
  ],
  'Finished or Closed': ['closed', 'closed - lost'],
  'On Hold': ['holding'],
};

export function ProjectsTable() {
  const navigate = useNavigate();
  const activeCompany = useRecoilValueLoadable(activeCompanyState);
  const activeAuthsLoadable = useRecoilValueLoadable(activeAuths);
  const [searchText, setSearchText] = useState<string>('');
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false);
  const [leadFilter, setProjectLeadFilter] = useState<string | null>(null);
  const [phaseFilter, setPhaseFilter] = useState<WorkflowPhase | 'all'>('all');
  const [classificationFilter, setClassificationFilter] =
    useState<string>('all');
  const [expandedProjects, setExpandedProjects] = useState<string[]>([]);
  const [projects, setProjects] = useRecoilState(projectListState);
  const [signedUrls, setSignedUrls] = useState<{ [key: string]: string }>({});
  const [showAllProjects, setShowAllProjects] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async (companyId: string) => {
      try {
        // Don't show loading state if we already have data
        const shouldShowLoading = projects.length === 0;
        setIsLoadingProjects(shouldShowLoading);

        const response = await fetchProjectsByCompany(companyId);
        setProjects(response.data);
      } catch (error) {
        console.error('Failed to fetch projects', error);
        // Only show error if we have no data to display
        if (projects.length === 0) {
          // Optionally show error state
        }
      } finally {
        setIsLoadingProjects(false);
      }
    };

    const fetchClientData = async (companyId: string) => {
      try {
        const shouldShowLoading = projects.length === 0;
        setIsLoadingProjects(shouldShowLoading);

        const response = await fetchProjectsByClient(companyId);
        setProjects(response.data);
      } catch (error) {
        console.error('Failed to fetch projects', error);
        if (projects.length === 0) {
          // Optionally show error state
        }
      } finally {
        setIsLoadingProjects(false);
      }
    };

    if (activeCompany.state === 'hasValue') {
      const company = activeCompany.getValue();
      if (
        company &&
        company.subscriptionLevel &&
        company.subscriptionLevel !== 'Guest'
      ) {
        fetchCompanyData(company._id);
      } else if (company) {
        fetchClientData(company._id);
      }
    }
  }, [activeCompany.state]);

  useEffect(() => {
    const loadSignedUrls = async () => {
      const newSignedUrls: { [key: string]: string } = {};

      for (const project of projects) {
        if (project.lead?.imageUrl) {
          const signedUrl = await getSignedImageUrl(project.lead.imageUrl);
          if (signedUrl) {
            newSignedUrls[project.lead._id] = signedUrl;
          }
        }
      }

      setSignedUrls(newSignedUrls);
    };

    loadSignedUrls();
  }, [projects]);

  if (
    activeCompany.state === 'loading' ||
    activeAuthsLoadable.state === 'loading'
  ) {
    return <div className="p-8 text-center text-gray-500">Loading...</div>;
  }

  // Get values safely
  const company = activeCompany.getValue();
  const auths = activeAuthsLoadable.getValue();
  const isOwner = auths?.hasOwner;

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handlePhaseFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPhaseFilter(event.target.value);
  };

  const handleClassificationFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setClassificationFilter(event.target.value);
  };

  const toggleExpand = (projectId: string) => {
    setExpandedProjects((prev) =>
      prev.includes(projectId)
        ? prev.filter((id) => id !== projectId)
        : [...prev, projectId],
    );
  };

  const isExpanded = (projectId: string) =>
    expandedProjects.includes(projectId);

  const handleProjectLeadFilter = (leadId: string | null) => {
    setProjectLeadFilter(leadId);
  };

  const filteredProjects = projects
    ? projects
        .filter((project) => {
          const matchesSearchText =
            project.name.toLowerCase().includes(searchText.toLowerCase()) ||
            project.client.name
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            project.address.toLowerCase().includes(searchText.toLowerCase());

          const matchesProjectLead =
            !leadFilter || (project.lead && project.lead._id === leadFilter);

          const matchesPhase =
            phaseFilter === 'all' ||
            workflowPhases[phaseFilter]?.includes(project.workFlowStatus);

          const matchesClassification =
            classificationFilter === 'all' ||
            project.classification === classificationFilter;

          return (
            matchesSearchText &&
            matchesProjectLead &&
            matchesPhase &&
            matchesClassification
          );
        })
        // Sort projects by sequence value
        .sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
    : [];

  const milestoneStatus = (project: Project) => {
    const milestones: Milestone[] = project.milestones || [];

    // Find the milestone with the lowest sequence number that is not completed
    const incompleteMilestones = milestones
      .filter((milestone) => !milestone.completionDate)
      .sort((a, b) => a.sequence - b.sequence);

    if (incompleteMilestones.length === 0) {
      return { status: 'Completed', dueDate: null };
    }
    const nextMilestone = incompleteMilestones[0];
    const now = new Date();
    const dueDate = nextMilestone.targetDate;

    if (!dueDate) {
      return { status: 'No Due Date', dueDate: null };
    }

    if (isBefore(dueDate, now) || isToday(dueDate)) {
      return { status: 'Overdue', dueDate };
    } else if (isBefore(dueDate, addDays(now, 3))) {
      return { status: 'Due Soon', dueDate };
    } else {
      return { status: 'Not Due', dueDate };
    }
  };

  // Get unique project leads
  const leads = Array.from(
    new Set(
      projects
        .map((project) => project.lead)
        .filter((lead) => lead && lead._id)
        .map((lead) => JSON.stringify(lead)),
    ),
  ).map((leadStr) => JSON.parse(leadStr));

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Check if the position has actually changed
    if (source.index === destination.index) return;

    // Create a copy of the filtered projects
    const reorderedProjects = Array.from(filteredProjects);

    // Remove the dragged project and insert it at the new position
    const [movedProject] = reorderedProjects.splice(source.index, 1);
    reorderedProjects.splice(destination.index, 0, movedProject);

    // Create a mapping of project IDs to their new sequence numbers
    const updatedSequenceMap = {};
    reorderedProjects.forEach((project, index) => {
      updatedSequenceMap[project._id] = index;
    });

    // Update the sequence numbers in the main projects list
    const updatedProjects = projects.map((project) => {
      if (updatedSequenceMap.hasOwnProperty(project._id)) {
        return {
          ...project,
          sequence: updatedSequenceMap[project._id],
        };
      }
      return project;
    });

    // Update the state
    setProjects(updatedProjects);

    // Update the sequences on the server
    try {
      await Promise.all(
        updatedProjects.map((project) => {
          if (updatedSequenceMap.hasOwnProperty(project._id)) {
            return updateProject(project._id, { sequence: project.sequence });
          }
        }),
      );
    } catch (error) {
      console.error('Failed to update project sequences', error);
    }
  };

  const handlePhaseChange = async (projectId: string, newPhase: string) => {
    try {
      await updateProject(projectId, { workFlowStatus: newPhase });

      // Update local state
      setProjects(
        projects.map((project) =>
          project._id === projectId
            ? { ...project, workFlowStatus: newPhase }
            : project,
        ),
      );
    } catch (error) {
      console.error('Failed to update project phase:', error);
      // Optionally add error handling UI feedback here
    }
  };

  const handlePriorityToggle = async (
    projectId: string,
    currentPriority: boolean,
  ) => {
    if (!isOwner) return;
    try {
      const updatedProject = {
        ...projects.find((p) => p._id === projectId),
        isPriority: !currentPriority,
      };

      await updateProject(projectId, updatedProject);

      // Update local state
      setProjects(
        projects.map((p) =>
          p._id === projectId ? { ...p, isPriority: !currentPriority } : p,
        ),
      );
    } catch (error) {
      console.error('Failed to update priority:', error);
      toast.error('Failed to update priority');
    }
  };

  return (
    <div className="max-w-[1600px] mx-auto bg-white rounded-lg shadow">
      {/* Search and Filters Bar */}
      <div className="p-4 border-b bg-gray-50 flex items-center gap-4">
        {/* Search */}
        <div className="flex-1">
          <input
            type="text"
            className="w-full px-3 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            value={searchText}
            onChange={handleSearchTextChange}
            placeholder="Search projects..."
          />
        </div>

        {/* Priority Toggle */}
        <button
          onClick={() => setShowAllProjects(!showAllProjects)}
          className={`px-4 py-2 rounded-md font-medium transition-colors ${
            showAllProjects
              ? 'bg-gray-200 hover:bg-gray-300'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          {showAllProjects ? 'Show Priority Only' : 'Show All Projects'}
        </button>

        {/* Quick Filters */}
        <div className="flex items-center gap-2">
          <select
            value={phaseFilter}
            onChange={handlePhaseFilterChange}
            className="px-3 py-2 rounded-md border border-gray-300"
          >
            <option value="all">All Phases</option>
            {Object.keys(workflowPhases).map((phase) => (
              <option key={phase} value={phase}>
                {phase}
              </option>
            ))}
          </select>

          <select
            value={classificationFilter}
            onChange={handleClassificationFilterChange}
            className="px-3 py-2 rounded-md border border-gray-300"
          >
            <option value="all">All Types</option>
            {classifications.map((classification) => (
              <option key={classification} value={classification}>
                {classification}
              </option>
            ))}
          </select>
        </div>

        {/* Lead Filter Pills */}
        <div className="flex gap-1">
          {leads.map((lead) => (
            <button
              key={lead._id}
              onClick={() => handleProjectLeadFilter(lead._id)}
              className={`h-8 px-2 rounded-full flex items-center gap-1 text-sm transition-colors ${
                leadFilter === lead._id
                  ? 'bg-blue-100 text-blue-800'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
              title={`${lead.firstName} ${lead.lastName}`}
            >
              <div className="w-6 h-6 rounded-full bg-blue-500 text-white flex items-center justify-center text-xs">
                {lead.firstName[0]}
                {lead.lastName[0]}
              </div>
              <span>{lead.firstName}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Projects List */}
      <div className="divide-y divide-gray-200">
        {isLoadingProjects && projects.length === 0 ? (
          <div className="p-8 text-center text-gray-500">Loading...</div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="projects">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {filteredProjects
                    .filter((project) => showAllProjects || project.isPriority)
                    .map((project, index) => {
                      const { status, dueDate } = milestoneStatus(project);
                      const initials = project.lead
                        ? `${project.lead.firstName[0]}${project.lead.lastName[0]}`.toUpperCase()
                        : '??';
                      const leadFullName = project.lead
                        ? `${project.lead.firstName} ${project.lead.lastName}`
                        : 'No Lead Assigned';

                      return (
                        <Draggable
                          key={project._id}
                          draggableId={project._id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="hover:bg-gray-50 transition-colors"
                            >
                              <div className="p-4 flex items-center gap-4">
                                {/* Status Indicator */}
                                <div className="flex-shrink-0">
                                  <div
                                    className={`w-3 h-3 rounded-full ${
                                      status === 'Overdue'
                                        ? 'bg-red-500'
                                        : status === 'Due Soon'
                                          ? 'bg-yellow-500'
                                          : 'bg-green-500'
                                    }`}
                                    title={`${status}${dueDate ? ` - Due ${format(dueDate, 'MMM d, yyyy')}` : ''}`}
                                  />
                                </div>

                                {/* Priority Toggle */}
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handlePriorityToggle(
                                      project._id,
                                      project.isPriority,
                                    );
                                  }}
                                  className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center transition-colors ${
                                    project.isPriority
                                      ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200'
                                      : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                                  } ${!isOwner ? 'cursor-not-allowed opacity-50' : ''}`}
                                  disabled={!isOwner}
                                  title={
                                    !isOwner
                                      ? 'Only owners can modify priority'
                                      : 'Toggle priority'
                                  }
                                >
                                  ⭐
                                </button>

                                {/* Project Info */}
                                <div
                                  className="flex-1 min-w-0 cursor-pointer"
                                  onClick={() =>
                                    navigate(`/project/${project._id}`)
                                  }
                                >
                                  <div className="flex items-baseline gap-2">
                                    <h3 className="font-medium truncate">
                                      {project.name}
                                    </h3>
                                    <span className="text-sm text-gray-500 truncate">
                                      {project.address}
                                    </span>
                                  </div>
                                  <div className="mt-1 flex items-center gap-2 text-sm text-gray-500">
                                    <span>{project.client?.name}</span>
                                    <span>•</span>
                                    <span>{project.classification}</span>
                                  </div>
                                </div>

                                {/* Phase Select */}
                                <select
                                  value={project.workFlowStatus}
                                  onChange={(e) =>
                                    handlePhaseChange(
                                      project._id,
                                      e.target.value,
                                    )
                                  }
                                  className="flex-shrink-0 px-3 py-1.5 rounded border text-sm"
                                >
                                  {Object.entries(workflowPhases).map(
                                    ([phase, statuses]) => (
                                      <optgroup key={phase} label={phase}>
                                        {statuses.map((status) => (
                                          <option key={status} value={status}>
                                            {status}
                                          </option>
                                        ))}
                                      </optgroup>
                                    ),
                                  )}
                                </select>

                                {/* Lead Info */}
                                <div
                                  className="flex-shrink-0 flex items-center gap-2"
                                  title={`Project Lead: ${leadFullName}`}
                                >
                                  {project.lead?.imageUrl ? (
                                    <img
                                      src={
                                        signedUrls[project.lead._id] ||
                                        project.lead.imageUrl
                                      }
                                      alt={leadFullName}
                                      className="w-8 h-8 rounded-full"
                                    />
                                  ) : (
                                    <div className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-medium">
                                      {initials}
                                    </div>
                                  )}
                                </div>

                                {/* Actions */}
                                <button
                                  onClick={() =>
                                    navigate(`/project/${project._id}`)
                                  }
                                  className="flex-shrink-0 px-3 py-1.5 rounded-md bg-blue-50 text-blue-600 hover:bg-blue-100 transition-colors"
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  );
}
