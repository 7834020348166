import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { activeUserState, activeAuths } from '../../atoms/user.atom';
import {
  fetchThreadsByProject,
  archiveThread,
  createThread,
  fetchProject,
} from '../../utils/api';
import { Modal, Input, Radio, message } from 'antd';
import { ThreadChat } from './ThreadChat';
import {
  PlusIcon,
  HashtagIcon,
  LockClosedIcon,
  TrashIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { Thread } from '../../models/thread.types';
import { Popconfirm } from 'antd';

interface ThreadListProps {
  projectId: string;
}

const ThreadList: React.FC<ThreadListProps> = ({ projectId }) => {
  const [activeThread, setActiveThread] = useState<Thread | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newThreadName, setNewThreadName] = useState('');
  const [threadType, setThreadType] = useState<'public' | 'private'>('public');
  const [isCreating, setIsCreating] = useState(false);
  const activeUser = useRecoilValue(activeUserState);
  const activeAuthorizations = useRecoilValue(activeAuths);

  const { data: threads = [], refetch } = useQuery(
    ['projectThreads', projectId],
    () => fetchThreadsByProject(projectId).then((response) => response.data),
  );

  const { data: project } = useQuery(['project', projectId], () =>
    fetchProject(projectId).then((response) => response.data),
  );

  const filteredThreads = threads.filter((thread) =>
    thread.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const canArchiveThread = (thread: Thread) => {
    return (
      activeUser?._id === thread.createdBy || activeAuthorizations.hasOwner
    );
  };

  const canCreateThread = () => {
    if (!activeUser?.company || !project?.company) return false;
    const projectCompanyId =
      typeof project.company === 'string'
        ? project.company
        : project.company._id;

    return activeUser.company === projectCompanyId;
  };

  const handleArchive = async (threadId: string) => {
    try {
      await archiveThread(threadId);
      if (activeThread?._id === threadId) {
        setActiveThread(null);
      }
      refetch(); // Refresh the threads list
    } catch (error) {
      console.error('Failed to archive thread:', error);
    }
  };

  const handleCreateThread = async () => {
    if (!canCreateThread()) {
      message.error(
        'You do not have permission to create threads in this project',
      );
      return;
    }

    if (!newThreadName.trim()) {
      message.error('Please enter a thread name');
      return;
    }

    setIsCreating(true);
    try {
      await createThread({
        name: newThreadName.trim(),
        type: 'project',
        projectId,
        isPrivate: threadType === 'private',
        members: threadType === 'private' ? [activeUser?._id] : [], // Add current user for private threads
      });

      message.success('Thread created successfully');
      setIsCreateModalOpen(false);
      refetch(); // Refresh thread list

      // Reset form
      setNewThreadName('');
      setThreadType('public');
    } catch (error) {
      console.error('Failed to create thread:', error);
      message.error('Failed to create thread');
    } finally {
      setIsCreating(false);
    }
  };

  const renderThreadItem = (thread: Thread) => (
    <div
      key={thread._id}
      className={`flex items-center justify-between w-full px-3 py-2 text-sm rounded-md hover:bg-gray-100 ${
        activeThread?._id === thread._id ? 'bg-gray-100' : ''
      }`}
    >
      <div
        className="flex items-center gap-2 flex-1 cursor-pointer"
        onClick={() => setActiveThread(thread)}
      >
        {thread.isPrivate ? (
          <LockClosedIcon className="w-4 h-4 text-gray-400" />
        ) : (
          <HashtagIcon className="w-4 h-4 text-gray-400" />
        )}
        <span className="flex-1">{thread.name}</span>
      </div>
      <div className="flex items-center gap-2">
        {canArchiveThread(thread) && (
          <Popconfirm
            title="Archive this thread?"
            onConfirm={() => handleArchive(thread._id)}
            okText="Yes"
            cancelText="No"
          >
            <button
              className="p-1 hover:bg-gray-100 rounded-full"
              title="Archive thread"
            >
              <TrashIcon className="w-4 h-4 text-yellow-500 hover:text-yellow-600" />
            </button>
          </Popconfirm>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex h-[600px] bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Left Sidebar */}
      <div className="w-64 border-r flex flex-col">
        {/* Search Bar */}
        <div className="p-4 border-b">
          <div className="relative">
            <input
              type="text"
              placeholder="Search threads..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-9 pr-3 py-2 border rounded-md text-sm"
            />
            <MagnifyingGlassIcon className="w-4 h-4 text-gray-400 absolute left-3 top-3" />
          </div>
        </div>

        {/* Thread List */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-4 space-y-6">
            <div>
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-xs font-semibold text-gray-500 uppercase">
                  Project Threads
                </h3>
                {canCreateThread() && (
                  <button
                    onClick={() => setIsCreateModalOpen(true)}
                    className="p-1 hover:bg-gray-100 rounded-full"
                  >
                    <PlusIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
                  </button>
                )}
              </div>
              <div className="space-y-1">
                {filteredThreads.map((thread) => renderThreadItem(thread))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1">
        {activeThread ? (
          <ThreadChat thread={activeThread} />
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            Select a thread to start messaging
          </div>
        )}
      </div>

      {/* Create Thread Modal */}
      <Modal
        title="Create New Thread"
        open={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        onOk={handleCreateThread}
        okText="Create"
        confirmLoading={isCreating}
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Thread Name
            </label>
            <Input
              value={newThreadName}
              onChange={(e) => setNewThreadName(e.target.value)}
              placeholder="Enter thread name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Thread Type
            </label>
            <Radio.Group
              value={threadType}
              onChange={(e) => setThreadType(e.target.value)}
            >
              <Radio.Button value="public">
                <div className="flex items-center gap-1">
                  <HashtagIcon className="w-4 h-4" />
                  Public
                </div>
              </Radio.Button>
              <Radio.Button value="private">
                <div className="flex items-center gap-1">
                  <LockClosedIcon className="w-4 h-4" />
                  Private
                </div>
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ThreadList;
