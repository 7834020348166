import React from 'react';
import { useNotifications } from '../../hooks/useNotifications';

export const NotificationBadge: React.FC = () => {
  const { getUnreadCount } = useNotifications();
  const unreadCount = getUnreadCount();

  if (unreadCount === 0) return null;

  return (
    <div className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full min-w-[18px] h-[18px] flex items-center justify-center px-1">
      {unreadCount}
    </div>
  );
};
