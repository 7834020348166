import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeProjectState } from '../atoms/project.atom';
import { activeAuths } from '../atoms/user.atom';
import { fetchProject, updateProject } from '../utils/api';

const ProjectPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [activeProject, setActiveProject] = useRecoilState(activeProjectState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const loadProject = async () => {
      if (!projectId) return;

      // Only fetch if we don't have the project or if it's a different project
      if (!activeProject?._id || activeProject._id !== projectId) {
        setIsLoading(true);
        try {
          const response = await fetchProject(projectId);
          setActiveProject(response.data);
        } catch (error) {
          console.error('Failed to fetch project:', error);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    loadProject();
  }, [projectId]);

  const handleUpdateProject = async (
    id: string,
    updatedProject: typeof activeProject,
  ) => {
    try {
      console.log('ProjectPage: Sending update:', updatedProject);
      const response = await updateProject(id, updatedProject);
      if (response?.data) {
        console.log('ProjectPage: Update successful:', response.data);
        setActiveProject(response.data);
        return response.data;
      }
      throw new Error('No data received from update');
    } catch (error) {
      console.error('Failed to update project:', error);
      throw new Error(
        error instanceof Error ? error.message : 'Failed to update project',
      );
    }
  };

  if (isLoading || !activeProject?._id) {
    return <div>Loading project...</div>;
  }

  return (
    <div className="flex h-full">
      {/* Left Navigation */}
      <nav className="w-64 bg-white shadow-lg p-4">
        <NavLink
          to="."
          end
          className={({ isActive }) =>
            `block p-2 mb-2 rounded ${
              isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
            }`
          }
        >
          Project Details
        </NavLink>

        {activeAuthorizations.hasEstimating && (
          <NavLink
            to="loe"
            className={({ isActive }) =>
              `block p-2 mb-2 rounded ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
              }`
            }
          >
            LOE
          </NavLink>
        )}

        {activeAuthorizations.hasLegal && (
          <NavLink
            to="agreement"
            className={({ isActive }) =>
              `block p-2 mb-2 rounded ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
              }`
            }
          >
            Letter Agreement
          </NavLink>
        )}

        <NavLink
          to="schedule"
          className={({ isActive }) =>
            `block p-2 mb-2 rounded ${
              isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
            }`
          }
        >
          Schedule
        </NavLink>
      </nav>

      {/* Main Content Area */}
      <main className="flex-1 p-6">
        <Outlet
          context={{ activeProject, updateProject: handleUpdateProject }}
        />
      </main>
    </div>
  );
};

export default ProjectPage;
