import React, { useState, useEffect } from 'react';
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import { getSignedImageUrl } from '../../utils/imageUrl';

interface Attachment {
  url: string;
  name: string;
  type?: string;
  size?: number;
}

interface Props {
  attachments: Attachment[];
}

export const MessageAttachments: React.FC<Props> = ({ attachments }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [signedUrls, setSignedUrls] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const loadSignedUrls = async () => {
      const urlMap: { [key: string]: string } = {};

      for (const attachment of attachments) {
        try {
          const signedUrl = await getSignedImageUrl(attachment.url);
          urlMap[attachment.url] = signedUrl;
        } catch (error) {
          console.error('Error getting signed URL:', error);
          urlMap[attachment.url] = attachment.url; // Fallback to original URL
        }
      }

      setSignedUrls(urlMap);
    };

    loadSignedUrls();
  }, [attachments]);

  const isImageUrl = (url: string): boolean => {
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(url);
  };

  const renderAttachment = (attachment: Attachment) => {
    const displayUrl = signedUrls[attachment.url] || attachment.url;
    const isImage =
      attachment.type?.startsWith('image/') || isImageUrl(attachment.url);

    if (isImage) {
      return (
        <div key={attachment.url} className="mt-2">
          <img
            src={displayUrl}
            alt={attachment.name || 'Image attachment'}
            className="max-h-48 rounded-lg cursor-pointer hover:opacity-90"
            onClick={() => setImagePreview(displayUrl)}
          />
        </div>
      );
    }

    return (
      <div key={attachment.url} className="mt-2">
        <a
          href={displayUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 p-2 border rounded-lg hover:bg-gray-50"
        >
          <PaperClipIcon className="w-5 h-5 text-gray-500" />
          <span className="text-sm text-blue-500">
            {attachment.name || 'Download attachment'}
          </span>
        </a>
      </div>
    );
  };

  return (
    <>
      <div className="space-y-2">{attachments.map(renderAttachment)}</div>

      {/* Image Preview Modal */}
      <Dialog
        open={!!imagePreview}
        onClose={() => setImagePreview(null)}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="relative bg-white rounded-lg max-w-3xl mx-auto">
            <img
              src={imagePreview || ''}
              alt="Preview"
              className="max-h-[80vh] rounded-lg"
            />
            <button
              onClick={() => setImagePreview(null)}
              className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
