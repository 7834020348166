import { atom } from 'recoil';

interface CompanyMember {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl?: string;
}

export const companyMembersState = atom<CompanyMember[]>({
  key: 'companyMembersState',
  default: [],
});
