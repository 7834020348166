import React, { useState, useRef } from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { NotificationsList } from './NotificationsList';
import { NotificationBadge } from './NotificationBadge';

export const NotificationsDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="relative p-2 text-gray-300 hover:text-white focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <BellIcon className="w-6 h-6" />
        <NotificationBadge />
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-80 bg-gray-800 rounded-lg shadow-lg border border-gray-700 z-50">
          <div className="p-3 border-b border-gray-700">
            <h3 className="text-lg font-medium text-white">Notifications</h3>
          </div>
          <NotificationsList />
        </div>
      )}
    </div>
  );
};
