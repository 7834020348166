import React, { useEffect, useState } from 'react';
import {
  createTask,
  fetchTasksByProject,
  updateTask,
  deleteTask,
  fetchTemplatesByCompany,
  createTemplate,
} from '../utils/api';
import { useOutletContext, useParams } from 'react-router-dom';
import { Gantt, Task } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import Modal from 'react-modal';
import {
  Task as WorkTask,
  DisciplineType,
  Project,
  Template,
} from '../models/general';
import ProjectTaskList from '../components/Projects/ProjectTaskList';
import ProjectGanttChart from '../components/Projects/ProjectGanttChart';
import { milestonesState } from '../atoms/project.atom';
import { useRecoilValue } from 'recoil';
import { addDays, differenceInDays, min, parseISO } from 'date-fns';
import { activeAuths, activeUserState } from '../atoms/user.atom';
import { MdPersonOutline } from 'react-icons/md';
import MilestoneList from '../components/MilestoneList';

Modal.setAppElement('#root');

interface OutletContext {
  activeProject: Project;
  updateProjectList: (project: Project) => void;
}

const Schedule: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const activeAuthorizations = useRecoilValue(activeAuths);
  const { activeProject, updateProjectList } =
    useOutletContext<OutletContext>();
  const activeUser = useRecoilValue(activeUserState);
  const [tasks, setTasks] = useState<WorkTask[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedStart, setSelectedStart] = useState<Date | null>(null); // State for schedule start date
  const [templateTitle, setTemplateTitle] = useState<string>('');
  const [showCreateTemplate, setShowCreateTemplate] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  ); // State to store selected template ID
  const [currentTaskId, setCurrentTaskId] = useState<string | null>(null);
  const activeMilestones = useRecoilValue(milestonesState);
  const [formData, setFormData] = useState<WorkTask>({
    title: '',
    description: '',
    status: 'To Do',
    priority: 'Medium',
    assignee: undefined,
    reporter: undefined,
    milestone: undefined,
    startDate: new Date(),
    duration: 1,
    project: '',
  });

  const fetchProjectTasks = async () => {
    if (!projectId) return;
    try {
      const response = await fetchTasksByProject(projectId);
      console.log(response.data);
      setTasks(response.data);
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    }
  };
  useEffect(() => {
    fetchProjectTasks();
  }, [projectId]);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        console.log(activeUser);
        const response = await fetchTemplatesByCompany(activeUser?.company);
        setTemplates(response.data);
      } catch (error) {
        console.error('Failed to fetch templates', error);
      }
    };

    loadTemplates();
  }, []);
  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setCurrentTaskId(null);
    setFormData({
      title: '',
      description: '',
      scope: '',
      status: 'To Do',
      priority: 'Medium',
      assignee: undefined,
      reporter: undefined,
      startDate: new Date(),
      duration: 1,
      project: projectId || '',
    });
  };

  const handleEdit = (task: WorkTask): void => {
    setFormData({
      title: task.title,
      description: task.description,
      scope: task.scope,
      status: task.status,
      priority: task.priority,
      assignee: task.assignee,
      reporter: task.reporter,
      milestone: task.milestone,
      startDate: task.startDate,
      duration: task.duration,
      project: task.project,
    });
    setCurrentTaskId(task._id);
    setIsEditMode(true);
    openModal();
  };

  const saveTemplate = async (tasks: WorkTask[]): void => {
    try {
      const firstStartDate = getEarliestStartDate(tasks) || '';
      const template = {
        title: templateTitle || 'Untitled Template', // Use input template title or fallback
        type: 'schedule',
        company: activeUser.company,
        list: tasks.map((task) => ({
          ...task,
          _id: null,
          relStart: differenceInDays(task.startDate || '', firstStartDate),
        })),
      };
      await createTemplate(template);
      setTemplateTitle('');
      setShowCreateTemplate(false);
      console.log('Created Template:', template);
    } catch (err) {
      console.log('error saving Template', err);
    }

    // Logic to save the template (e.g., API call) would go here
  };

  const handleTemplateSelection = async () => {
    if (!selectedTemplateId || !selectedStart) return; // Ensure start date is selected

    const selectedTemplate = templates.find(
      (template) => template._id === selectedTemplateId,
    );
    if (!selectedTemplate) return;

    const createdTasks: WorkTask[] = [];
    selectedTemplate.list.forEach((task) => {
      if (!task.scope || activeProject.disciplines.includes(task.scope)) {
        createdTasks.push({
          ...task,
          milestone: activeMilestones.find((milestone) => {
            return milestone.title === task.milestone.title;
          }),
          assignee: null,
          project: projectId,
          startDate: addDays(selectedStart, task.relStart), // Use selected start date
        });
      }
    });

    try {
      await Promise.all(createdTasks.map((task) => createTask(task)));
      fetchProjectTasks(); // Refresh task list
    } catch (error) {
      console.error('Failed to create tasks from template', error);
    }
  };
  function getEarliestStartDate(tasks: WorkTask[]): string | null {
    if (tasks.length === 0) return null;

    // Convert each startDate to a Date object using parseISO
    const startDates = tasks.map((task) => parseISO(task.startDate));

    // Find the minimum date using date-fns' min function
    const earliestDate = min(startDates);

    return earliestDate.toISOString();
  }

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ): void => {
    const { name, value } = e.target;
    if (name === 'assignee') {
      const selectedUser = activeProject.contacts?.find(
        (contact) => contact._id === value,
      );
      console.log('finding User', name, value, selectedUser);
      setFormData({
        ...formData,
        assignee: selectedUser,
      });
    }
    if (name === 'milestone') {
      console.log('finding milestone', name, value);
      const selectedMilestone = activeMilestones.find(
        (milestone) => milestone._id === value,
      );
      setFormData({
        ...formData,
        milestone: selectedMilestone,
      });
    } else if (name !== 'assignee') {
      console.log('2', formData, name, value);
      setFormData({
        ...formData,
        [name]: name === 'duration' ? parseInt(value) : value,
      });
    }
  };

  const handleUpdateStatus = async (taskId: string, newStatus: string) => {
    try {
      // Find the task that needs to be updated
      const taskToUpdate = tasks.find((task) => task._id === taskId);
      if (!taskToUpdate) return;

      // Store the original tasks state before making any changes
      const originalTasks = [...tasks];

      // Update the status in the local state immediately for a responsive UI
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId ? { ...task, status: newStatus } : task,
        ),
      );

      // Prepare the updated task data to send to the API
      const updatedTask = { ...taskToUpdate, status: newStatus };

      // Call the updateTask API function to persist the change
      const response = await updateTask(taskId, updatedTask);
      const newTasks = tasks.map((task) => {
        return task._id === taskId
          ? { ...task, status: response.data.status }
          : task;
      });
      setTasks(newTasks);
    } catch (error) {
      // If the API call fails, revert back to the original tasks state
      setTasks(originalTasks);
      console.error('Failed to update task status', error);
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    // Store original tasks state for potential rollback
    const originalTasks = [...tasks];
    try {
      const newTask: WorkTask = {
        ...formData,
        project: activeProject._id,
        company: activeProject.company || activeProject.company._id,
        startDate: new Date(formData.startDate),
      };
      if (isEditMode && currentTaskId) {
        // Update tasks optimistically
        const newTasks = tasks.map((task) => {
          return task._id === currentTaskId ? newTask : task;
        });
        setTasks(newTasks);

        const response = await updateTask(currentTaskId, newTask);
        if (!response || !response.data) {
          // Revert on error
          setTasks(originalTasks);
          throw new Error('Failed to update task');
        }
      } else {
        const response = await createTask(newTask);
        if (!response || !response.data) {
          // Revert on error
          setTasks(originalTasks);
          throw new Error('Failed to create task');
        }
        setTasks([...tasks, response.data]);
      }
      closeModal();
    } catch (error) {
      // Revert to original state on any error
      setTasks(originalTasks);
      console.error('Failed to create or update task', error);
    }
  };

  const handleDelete = async (taskId: string): Promise<void> => {
    try {
      await deleteTask(taskId);
      setTasks(tasks.filter((task) => task._id !== taskId));
    } catch (error) {
      console.error('Failed to delete task', error);
    }
  };

  return (
    <div className="space-y-6 max-w-7xl mx-auto">
      <section className="bg-white rounded-lg shadow-lg p-4">
        <h2 className="text-xl font-bold mb-3 text-gray-800 border-b pb-2">
          Project Milestones
        </h2>
        <MilestoneList project={activeProject} isViewOnly={false} />
      </section>

      <div className="schedule-container bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Project Schedule</h2>

        {tasks.length > 0 ? (
          <>
            <ProjectGanttChart
              tasks={tasks}
              onTaskDoubleClick={handleEdit}
              setTasks={setTasks}
            />
            <div className="flex justify-between">
              <button
                onClick={openModal}
                className="bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-700 "
              >
                + Add Task
              </button>
              {activeAuthorizations.hasOwner ? (
                showCreateTemplate ? (
                  <div className="mb-4 flex items-end space-x-2">
                    <input
                      type="text"
                      value={templateTitle}
                      onChange={(e) => setTemplateTitle(e.target.value)}
                      placeholder="Enter Template Title"
                      className="flex-grow px-4 py-2 border rounded-lg"
                    />
                    {templateTitle && (
                      <button
                        onClick={() => saveTemplate(tasks)}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                      >
                        + Create Template
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    onClick={() => setShowCreateTemplate(true)}
                    className="bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-700 "
                    title="Create a new Template Schedule from these tasks"
                  >
                    Create Template Schedule
                  </button>
                )
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <div className="mb-4">
            <h3 className="text-lg font-semibold">
              No tasks found. Create a task to begin
            </h3>
            <button
              onClick={openModal}
              className="bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-700 "
            >
              + Add Task
            </button>
            <h3 className="text-lg font-semibold">
              Or import a schedule from a template:
            </h3>
            <select
              value={selectedTemplateId || ''}
              onChange={(e) => setSelectedTemplateId(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg mb-2"
            >
              <option value="" disabled>
                Select a Template
              </option>
              {templates
                .filter((template) => {
                  return template.type === 'schedule';
                })
                .map((template) => (
                  <option key={template._id} value={template._id}>
                    {template.title}
                  </option>
                ))}
            </select>
            {selectedTemplateId && (
              <>
                <label className="block text-gray-700 font-medium mb-2">
                  Schedule Start Date:
                </label>
                <input
                  type="date"
                  value={
                    selectedStart
                      ? selectedStart.toISOString().slice(0, 10)
                      : ''
                  }
                  onChange={(e) => setSelectedStart(new Date(e.target.value))} // Set selected start date
                  className="w-full px-4 py-2 border rounded-lg mb-4"
                />
                <button
                  onClick={handleTemplateSelection}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  Create Tasks from Template
                </button>
                <button
                  onClick={() => setSelectedTemplateId(null)}
                  className="bg-red-500 text-white px-4 mx-2 py-2 rounded hover:bg-red-700"
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        )}

        <ProjectTaskList
          tasks={tasks}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onUpdateStatus={handleUpdateStatus}
        />

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Task Modal"
          className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-10"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <h2 className="text-2xl font-bold mb-4">
            {isEditMode ? 'Edit Task' : 'Add Task'}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <select
              name="milestone"
              value={formData.milestone?._id || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="" disabled>
                Select a Milestone
              </option>
              {activeMilestones.map((milestone) => (
                <option key={milestone._id} value={milestone._id}>
                  {milestone.title}
                </option>
              ))}
            </select>
            <select
              name="scope"
              value={formData.scope || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="" disabled>
                Select a Discipline
              </option>
              {activeProject.disciplines.map((discipline) => (
                <option key={discipline} value={discipline}>
                  {discipline}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Task Title"
              required
              className="w-full px-4 py-2 border rounded-lg"
            />
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Task Description"
              className="w-full px-4 py-2 border rounded-lg"
            ></textarea>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="To Do">To Do</option>
              <option value="In Progress">In Progress</option>
              <option value="In Review">In Review</option>
              <option value="Done">Done</option>
              <option value="Blocked">Blocked</option>
            </select>
            <select
              name="priority"
              value={formData.priority}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="Lowest">Lowest</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Highest">Highest</option>
            </select>
            <select
              name="assignee"
              value={formData.assignee?._id || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
            >
              <option value="" disabled>
                {' '}
                Select Assignee{' '}
              </option>
              {activeProject.contacts?.map((contact) => (
                <option key={contact._id} value={contact._id}>
                  {contact.firstName} {contact.lastName}
                </option>
              ))}
            </select>
            <input
              type="date"
              name="startDate"
              value={formData.startDate.toString().slice(0, 10)}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border rounded-lg"
            />
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              placeholder="Duration (days)"
              min="1"
              required
              className="w-full px-4 py-2 border rounded-lg"
            />
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={closeModal}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                {isEditMode ? 'Update Task' : 'Add Task'}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default Schedule;
