import { io, Socket } from 'socket.io-client';
import { getToken } from '../utils/tokenManager';
import { jwtDecode } from 'jwt-decode';

export class SocketService {
  private static instance: SocketService;
  private socket: Socket | null = null;
  private notificationHandlers: ((notification: any) => void)[] = [];

  private constructor() {
    const token = getToken();
    let userId = null;

    if (token) {
      try {
        const decodedToken: { exp: number; user: any; company: any } =
          jwtDecode(token);
        userId = decodedToken.user._id;
        console.log('Decoded user data:', decodedToken.user);
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }

    console.log('Initializing socket with auth:', {
      token: token ? 'Present' : 'Missing',
      userId,
    });

    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    this.socket = io(baseUrl, {
      path: '/socket.io',
      auth: {
        token: `Bearer ${token}`,
        userId,
      },
      transports: ['websocket', 'polling'],
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      timeout: 60000, // Match server timeout
    });

    this.socket.on('connect', () => {
      console.log('Socket connected with ID:', this.socket?.id);
      console.log('Socket auth:', this.socket?.auth);
    });

    this.socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      console.error('Error details:', {
        message: error.message,
        description: error.description,
        type: error.type,
        context: error,
      });
      // If websocket fails, it will automatically fall back to polling
    });

    this.socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    this.socket.onAny((eventName, ...args) => {
      console.log('Socket event:', eventName, args);
    });

    this.socket.on('notification:mention', (notification) => {
      console.log('Received mention notification:', notification);
      this.notificationHandlers.forEach((handler) => handler(notification));
    });
  }

  public static getInstance(): SocketService {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  public getSocket(): Socket {
    if (!this.socket) {
      throw new Error('Socket not initialized');
    }
    return this.socket;
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public reconnect(): void {
    if (this.socket) {
      this.socket.connect();
    } else {
      SocketService.instance = new SocketService();
    }
  }

  public addNotificationHandler(handler: (notification: any) => void) {
    this.notificationHandlers.push(handler);
  }

  public removeNotificationHandler(handler: (notification: any) => void) {
    this.notificationHandlers = this.notificationHandlers.filter(
      (h) => h !== handler,
    );
  }
}
