import React, { useState, useEffect } from 'react';
import { Radio, Input, Modal, Image } from 'antd';
import { Project } from '../../models/general';
import { formatDate } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFileImage,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileCode,
} from '@fortawesome/free-solid-svg-icons';
import FileUploadModal from './FileUploadModal';
import {
  uploadFile,
  addProjectFile,
  addDeliverable,
  getSignedUrl,
} from '../../utils/api';
import { message } from 'antd';
import { getSignedImageUrl } from '../../utils/imageUrl';

const { Search } = Input;

interface FileListProps {
  project: Project;
  isViewOnly?: boolean;
  filter?: 'all' | 'internal' | 'public' | 'deliverables';
  forcePublic?: boolean;
}

const FileList: React.FC<FileListProps> = ({
  project,
  isViewOnly = false,
  filter,
  forcePublic = false,
}) => {
  const [activeFilter, setFilter] = useState<
    'all' | 'internal' | 'public' | 'deliverables'
  >(filter || 'all');
  const [searchTerm, setSearchTerm] = useState('');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [files, setFiles] = useState(project.files || []);
  const [deliverables, setDeliverables] = useState(project.deliverables || []);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  // Update local state when project changes
  useEffect(() => {
    setFiles(project.files || []);
    setDeliverables(project.deliverables || []);
  }, [project]);

  const getFileIcon = (filename: string) => {
    const ext = filename.split('.').pop()?.toLowerCase() || '';
    switch (ext) {
      case 'pdf':
        return faFilePdf;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return faFileImage;
      case 'doc':
      case 'docx':
        return faFileWord;
      case 'xls':
      case 'xlsx':
        return faFileExcel;
      case 'js':
      case 'ts':
      case 'jsx':
      case 'tsx':
        return faFileCode;
      default:
        return faFile;
    }
  };

  const formatUploadDate = (dateString: string | Date) => {
    try {
      const date =
        typeof dateString === 'string' ? new Date(dateString) : dateString;
      if (isNaN(date.getTime())) {
        return 'Date not available';
      }
      return formatDate(date, 'MMM dd, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date not available';
    }
  };

  const formatUploader = (
    uploader: { firstName?: string; lastName?: string } | undefined,
  ) => {
    if (!uploader || (!uploader.firstName && !uploader.lastName)) {
      return 'Unknown user';
    }
    return `${uploader.firstName || ''} ${uploader.lastName || ''}`.trim();
  };

  const allFiles = [
    ...(files || []),
    ...(deliverables || []).map((doc) => ({
      ...doc,
      displayBadge: `${doc.deliverableType} ${doc.version}`,
      statusBadge: doc.status,
    })),
  ];

  const filteredFiles = allFiles
    .filter((file) => {
      const currentFilter = filter || activeFilter;

      if (currentFilter === 'deliverables' && !('deliverableType' in file)) {
        return false;
      }
      if (
        currentFilter !== 'all' &&
        currentFilter !== 'deliverables' &&
        file.classification !== currentFilter
      ) {
        return false;
      }
      if (searchTerm) {
        return file.name.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return true;
    })
    .sort((a, b) => {
      try {
        const dateA = new Date(a.uploadedAt);
        const dateB = new Date(b.uploadedAt);
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          return 0;
        }
        return dateB.getTime() - dateA.getTime();
      } catch (error) {
        return 0;
      }
    });

  const handleFileUpload = async (
    files: any[],
    isDeliverable: boolean,
    metadata: any,
  ) => {
    for (const file of files) {
      try {
        const uploadResponse = await uploadFile(
          project.company || project.company._id,
          file.originFileObj,
          file.name,
          metadata.description || '',
          project._id,
        );

        const fileUrl = uploadResponse.data.url;
        const fileName = file.customName || file.name;

        if (isDeliverable) {
          const response = await addDeliverable(project._id, {
            name: fileName,
            url: fileUrl,
            type: file.type,
            ...metadata,
          });
          // Update deliverables list
          setDeliverables(response.data.deliverables);
        } else {
          const response = await addProjectFile(project._id, {
            name: fileName,
            url: fileUrl,
            type: file.type,
            ...metadata,
          });
          // Update files list
          setFiles(response.data.files);
        }
      } catch (error) {
        console.error('Failed to upload file:', error);
      }
    }
  };

  const isImageFile = (filename: string) => {
    const ext = filename.split('.').pop()?.toLowerCase() || '';
    return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext);
  };

  const handleFileClick = async (file: any) => {
    const signedUrl = await getSignedImageUrl(file.url);
    if (!signedUrl) return;

    if (isImageFile(file.name)) {
      setPreviewUrl(signedUrl);
      setPreviewTitle(file.name);
      setPreviewVisible(true);
    } else {
      window.open(signedUrl, '_blank');
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4 mb-4">
        {!filter && (
          <Radio.Group
            onChange={(e) =>
              setFilter(
                e.target.value as
                  | 'all'
                  | 'internal'
                  | 'public'
                  | 'deliverables',
              )
            }
            value={activeFilter}
            className="flex-shrink-0"
          >
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="internal">Internal</Radio.Button>
            <Radio.Button value="public">Public</Radio.Button>
            <Radio.Button value="deliverables">Deliverables</Radio.Button>
          </Radio.Group>
        )}
        <Search
          placeholder="Search files..."
          onChange={(e) => setSearchTerm(e.target.value)}
          className="max-w-xs"
        />

        {!isViewOnly && (
          <button
            onClick={() => setIsUploadModalOpen(true)}
            className="flex-shrink-0 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            + Upload File
          </button>
        )}
      </div>

      <div className="flex gap-4 overflow-x-auto pb-4">
        {filteredFiles.map((file) => (
          <div
            key={file._id}
            className="flex-shrink-0 w-64 bg-white rounded-lg border border-gray-200 p-4 hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => handleFileClick(file)}
          >
            <div className="flex items-start gap-3">
              <div className="w-8 text-gray-400">
                <FontAwesomeIcon icon={getFileIcon(file.name)} size="2x" />
              </div>
              <div className="flex-grow min-w-0">
                <h3
                  className="font-bold text-gray-800 truncate"
                  title={file.name}
                >
                  {file.name}
                </h3>
                <div className="text-sm text-gray-600">
                  <div className="flex items-center gap-1">
                    <span
                      className={`px-2 py-0.5 rounded-full text-xs ${
                        file.classification === 'deliverable'
                          ? 'bg-green-100 text-green-800'
                          : file.classification === 'client'
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-gray-100 text-gray-800'
                      }`}
                    >
                      {file.classification}
                    </span>
                  </div>
                  <div className="mt-1">
                    Uploaded: {formatUploadDate(file.uploadedAt)}
                  </div>
                  <div className="text-xs text-gray-500 truncate">
                    By: {formatUploader(file.uploadedBy)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width="80%"
        centered
      >
        <Image
          alt={previewTitle}
          src={previewUrl}
          style={{ width: '100%' }}
          preview={false}
        />
      </Modal>

      <FileUploadModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onUpload={(files, isDeliverable, metadata) =>
          handleFileUpload(files, isDeliverable, {
            ...metadata,
            classification: forcePublic ? 'public' : metadata.classification,
          })
        }
        forcePublic={forcePublic}
        filter={filter}
      />
    </div>
  );
};

export default FileList;
