import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Modal, Radio, Select, Tooltip } from 'antd'; // Assuming you are using antd for date picker
import { Milestone, Project } from '../models/general';
import { compareAsc, formatDate } from 'date-fns';
import { BorderOutlined, CheckOutlined } from '@ant-design/icons';
import { createMilestone, updateMilestone } from '../utils/api';
import { useRecoilState } from 'recoil';
import { milestonesState } from '../atoms/project.atom';
import { milestoneTitles } from '../utils/misc';

const { Option } = Select;

const MilestoneList: React.FC<{
  project: Project;
  isViewOnly: boolean;
}> = ({ project, isViewOnly }) => {
  const [activeMilestones, setActiveMilestones] =
    useRecoilState(milestonesState);

  useEffect(() => {
    console.log('checking for miles');
    if (project.milestones) setActiveMilestones(project.milestones);
  }, [project._id]);
  const [filter, setFilter] = useState<'all' | 'completed' | 'incomplete'>(
    'incomplete',
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMilestoneTitle, setNewMilestoneTitle] = useState('');
  const [selectedTitle, setSelectedTitle] = useState(''); // For selected predefined title

  const handleMarkAsComplete = async (milestone: Milestone) => {
    if (isViewOnly) return;
    let val = new Date();
    if (milestone.completionDate) {
      val = null;
    }
    // THIS REWRITE IS WORKING, but NOT RESETTING THE COMPLETED DATE WHEN CLICKED
    // rewrite with call to update milestone
    // updateProject({ ...project, milestones: updatedMilestones });
    try {
      if (!milestone._id) {
        alert('OLD MILESTONE FOUND, changes not saved');
        return;
      }
      const updatedMilestone = await updateMilestone(milestone._id, {
        completionDate: val,
      });
      console.log('updating', updateMilestone);
      const updatedMilestones = activeMilestones.map((miles) =>
        miles.title === milestone.title ? updatedMilestone.data : miles,
      );
      setActiveMilestones(updatedMilestones);
    } catch (e) {
      alert('Milestone Failed to Save');
      console.log(e);
    }
  };

  const handleTargetDateChange = async (date: any, milestone: Milestone) => {
    if (isViewOnly) return;

    try {
      if (!milestone._id) {
        alert('OLD MILESTONE FOUND, changes not saved');
        return;
      }
      const updatedMilestone = await updateMilestone(milestone._id, {
        targetDate: date ? date.toDate() : null,
      });
      console.log('updating', updateMilestone);
      const updatedMilestones = activeMilestones.map((miles) =>
        miles.title === milestone.title ? updatedMilestone.data : miles,
      );
      setActiveMilestones(updatedMilestones);
    } catch (e) {
      alert('Milestone Failed to Save');
      console.log(e);
    }
  };

  const handleAddMilestone = async () => {
    const finalTitle =
      newMilestoneTitle ||
      selectedTitle ||
      `Milestone ${activeMilestones.length + 1}`;
    const newMilestoneResponse = await createMilestone({
      title: finalTitle,
      sequence: activeMilestones.length,
      project: project._id,
      targetDate: undefined,
      completionDate: undefined,
    });
    const updatedMilestones = [...activeMilestones, newMilestoneResponse.data];
    setActiveMilestones(updatedMilestones);
    setIsModalVisible(false); // Close the modal after adding milestone
    setNewMilestoneTitle(''); // Clear the input after submission
    setSelectedTitle('');
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewMilestoneTitle(''); // Clear the input if the modal is cancelled
  };

  const handleFilterChange = (e: any) => {
    setFilter(e.target.value);
  };

  const filteredMilestones = activeMilestones
    .filter((milestone) => {
      if (filter === 'completed') {
        return milestone.completionDate;
      } else if (filter === 'incomplete') {
        return !milestone.completionDate;
      }
      return true; // If "all" is selected, show all milestones
    })
    .sort((a, b) => {
      if (a.sequence == undefined || b.sequence == undefined) return 0;
      return a.sequence - b.sequence;
    });

  const isOverdue = (milestone: Milestone) => {
    return (
      !milestone.completionDate &&
      milestone.targetDate &&
      new Date(milestone.targetDate) < new Date()
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4 mb-4">
        <Radio.Group
          onChange={handleFilterChange}
          value={filter}
          className="flex-shrink-0"
        >
          <Radio.Button value="all">All</Radio.Button>
          <Radio.Button value="completed">Completed</Radio.Button>
          <Radio.Button value="incomplete">Future</Radio.Button>
        </Radio.Group>
        {!isViewOnly && (
          <button
            onClick={showModal}
            className="flex-shrink-0 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            + New Milestone
          </button>
        )}
      </div>

      <div className="flex gap-4 overflow-x-auto pb-4">
        {filteredMilestones.map((milestone, index) => (
          <div
            key={index}
            className={`flex-shrink-0 w-64 bg-white rounded-lg border p-4 hover:shadow-md transition-shadow ${
              isOverdue(milestone)
                ? 'border-red-200 bg-red-50'
                : 'border-gray-200'
            }`}
          >
            <div className="flex items-start justify-between mb-2">
              <h3
                className={`font-bold flex-grow mr-2 ${
                  isOverdue(milestone) ? 'text-red-800' : 'text-gray-800'
                }`}
              >
                {milestone.title}
              </h3>
              {!isViewOnly && (
                <div className="flex items-center gap-2 flex-shrink-0">
                  <Tooltip
                    title={
                      milestone.completionDate
                        ? 'Mark as Not Completed'
                        : 'Mark as Completed'
                    }
                  >
                    <button
                      className={`${
                        milestone.completionDate ? 'bg-blue-500' : 'bg-gray-300'
                      } text-white w-7 h-7 flex items-center justify-center rounded-full hover:opacity-80`}
                      onClick={() => handleMarkAsComplete(milestone)}
                    >
                      {milestone.completionDate ? (
                        <CheckOutlined className="text-sm" />
                      ) : (
                        <BorderOutlined className="text-sm" />
                      )}
                    </button>
                  </Tooltip>
                  <DatePicker
                    style={{ width: '32px' }}
                    inputReadOnly
                    onChange={(date) => handleTargetDateChange(date, milestone)}
                    bordered={false}
                  />
                </div>
              )}
            </div>

            <div
              className={`text-sm ${
                isOverdue(milestone) ? 'text-red-600' : 'text-gray-600'
              }`}
            >
              {milestone.completionDate ? (
                <div className="flex items-center gap-1">
                  <span className="w-2 h-2 bg-green-500 rounded-full" />
                  <span>
                    Completed:{' '}
                    {formatDate(milestone.completionDate, 'MMM dd, yyyy')}
                  </span>
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <span
                    className={`w-2 h-2 rounded-full ${
                      isOverdue(milestone) ? 'bg-red-500' : 'bg-yellow-500'
                    }`}
                  />
                  <span>
                    Target:{' '}
                    {milestone.targetDate
                      ? formatDate(milestone.targetDate, 'MMM dd, yyyy')
                      : 'Not Set'}
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <Modal
        title="Add New Milestone"
        open={isModalVisible}
        onOk={handleAddMilestone}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: '100%', marginBottom: 16 }}
          placeholder="Select a predefined title"
          onChange={(value) => setSelectedTitle(value)}
          value={selectedTitle}
        >
          {milestoneTitles.map((title) => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Or enter your custom title"
          value={newMilestoneTitle}
          onChange={(e) => setNewMilestoneTitle(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default MilestoneList;
