import { Button, List, Tag, Divider, Switch, Popconfirm } from 'antd';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { format, addDays, isWithinInterval, isFuture } from 'date-fns';
import { leavesState } from '../../atoms/leave.atom';
import { activeUserState, activeAuths } from '../../atoms/user.atom';
import LeaveRequestModal from './LeaveRequestModal';
import { fetchLeaves, deleteLeave, createBankHolidays } from '../../utils/api';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { LeaveType } from '../../models/leave';
import PendingLeaveApprovals from './PendingLeaveApprovals';

const UpcomingLeaves = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAllLeaves, setShowAllLeaves] = useState(true);
  const [showAllFuture, setShowAllFuture] = useState(false);
  const [leaves, setLeaves] = useRecoilState(leavesState);
  const activeUser = useRecoilValue(activeUserState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const [editingLeave, setEditingLeave] = useState<Leave | null>(null);

  const nextTwoWeeks = addDays(new Date(), 14);

  // Filter leaves based on date range
  const filterLeavesByDate = (leaveList: any[]) => {
    return leaveList.filter((leave) => {
      const startDate = new Date(leave.startDate);
      const endDate = addDays(startDate, leave.duration - 1);
      const today = new Date();

      if (showAllFuture) {
        // Show leaves that either:
        // 1. Start in the future, or
        // 2. Are currently ongoing (end date is in the future)
        return isFuture(startDate) || endDate >= today;
      }

      return (
        isWithinInterval(startDate, {
          start: today,
          end: nextTwoWeeks,
        }) ||
        (startDate <= today && endDate >= today)
      );
    });
  };

  // Get user's leaves
  const userLeaves = filterLeavesByDate(leaves).filter((leave) =>
    leave.members.some((member: any) => member._id === activeUser?._id),
  );

  // Get all approved leaves
  const allApprovedLeaves = filterLeavesByDate(leaves).filter(
    (leave) => leave.status === 'approved',
  );

  // Add the missing function
  const handleCreateBankHolidays = async () => {
    try {
      const currentYear = new Date().getFullYear();
      await createBankHolidays(currentYear);
      await handleSuccess(); // Refresh the leaves list
    } catch (error: any) {
      console.error('Failed to create bank holidays:', error);
      // Optionally show error message to user
      // message.error(error.context || 'Failed to create bank holidays');
    }
  };

  // Separate user's leaves by status
  const pendingLeaves = userLeaves.filter(
    (leave) => leave.status === 'requested',
  );
  const userApprovedLeaves = userLeaves.filter(
    (leave) => leave.status === 'approved',
  );
  const deniedLeaves = userLeaves.filter((leave) => leave.status === 'denied');

  const handleSuccess = async () => {
    try {
      const response = await fetchLeaves();
      setLeaves(response.data);
    } catch (error) {
      console.error('Failed to fetch leaves:', error);
    }
  };

  const handleDelete = async (leaveId: string) => {
    try {
      await deleteLeave(leaveId);
      await handleSuccess(); // Refresh the leaves list
    } catch (error) {
      console.error('Failed to delete leave:', error);
    }
  };

  const canEdit = (leave: any) => {
    return (
      // Can only edit pending leaves
      (leave.status === 'requested' &&
        (leave.createdBy._id === activeUser?._id ||
          activeAuthorizations.hasOwner)) ||
      (leave.type === 'Holiday' && activeAuthorizations.hasOwner)
    );
  };

  const canDelete = (leave: any) => {
    return (
      // Can delete if it's your leave (any status) or you're an owner
      leave.createdBy._id === activeUser?._id || activeAuthorizations.hasOwner
    );
  };

  const handleEdit = (leave: Leave) => {
    setEditingLeave(leave);
    setShowModal(true);
  };

  const renderLeaveList = (leaveList: any[], emptyMessage: string) => (
    <List
      dataSource={leaveList}
      locale={{ emptyText: emptyMessage }}
      size="small"
      renderItem={(leave) => (
        <List.Item
          className="py-2 px-3 mb-2 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors border border-gray-100 shadow-sm"
          actions={[
            canEdit(leave) && (
              <Button
                key="edit"
                type="text"
                icon={<EditOutlined />}
                onClick={() => handleEdit(leave)}
              />
            ),
            canDelete(leave) && (
              <Popconfirm
                key="delete"
                title="Delete this leave request?"
                onConfirm={() => handleDelete(leave._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            ),
          ].filter(Boolean)}
        >
          <List.Item.Meta
            title={
              <div className="flex items-center gap-2">
                <span className="font-medium">{leave.title}</span>
                {!(leave.type === 'Holiday' && leave.status === 'approved') && (
                  <Tag color={getStatusColor(leave.status)}>{leave.status}</Tag>
                )}
                <Tag color={getTypeColor(leave.type)}>{leave.type}</Tag>
              </div>
            }
            description={
              <div className="text-sm text-gray-600">
                <div className="mt-1">
                  {format(new Date(leave.startDate), 'MMM d, yyyy')} -{' '}
                  {format(
                    addDays(new Date(leave.startDate), leave.duration - 1),
                    'MMM d, yyyy',
                  )}
                </div>
                <div className="mt-1">
                  Members:{' '}
                  {leave.members
                    .map((m: any) => `${m.firstName} ${m.lastName}`)
                    .join(', ')}
                </div>
                {leave.description && (
                  <div className="mt-1 text-gray-500">{leave.description}</div>
                )}
              </div>
            }
          />
        </List.Item>
      )}
    />
  );

  // Separate approved leaves into holidays and other leaves
  const approvedLeaves = filterLeavesByDate(leaves)
    .filter((leave) => leave.status === 'approved')
    .sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    );

  const holidays = approvedLeaves.filter((leave) => leave.type === 'Holiday');
  const otherApprovedLeaves = approvedLeaves.filter(
    (leave) => leave.type !== 'Holiday',
  );

  // Sort other approved leaves to put current user's leaves first
  const sortedOtherApprovedLeaves = otherApprovedLeaves.sort((a, b) => {
    const aHasUser = a.members.some(
      (member: any) => member._id === activeUser?._id,
    );
    const bHasUser = b.members.some(
      (member: any) => member._id === activeUser?._id,
    );
    if (aHasUser && !bHasUser) return -1;
    if (!aHasUser && bHasUser) return 1;
    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
  });

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center gap-4">
          <h2 className="text-2xl font-bold">Leave Requests</h2>
          {activeAuthorizations.hasOwner && holidays.length === 0 && (
            <Button
              onClick={handleCreateBankHolidays}
              title="Add this year's bank holidays"
            >
              Add Bank Holidays
            </Button>
          )}
        </div>
        <div className="flex items-center gap-4">
          <PendingLeaveApprovals />
          <Button type="primary" onClick={() => setShowModal(true)}>
            Request Leave
          </Button>
        </div>
      </div>

      <div className="flex items-center gap-6 mb-4">
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">
            Show Holidays and Other's Leaves
          </span>
          <Switch
            checked={showAllLeaves}
            onChange={setShowAllLeaves}
            size="small"
          />
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">Show all future dates</span>
          <Switch
            checked={showAllFuture}
            onChange={setShowAllFuture}
            size="small"
          />
        </div>
      </div>

      <div className="max-h-[600px] overflow-y-auto">
        {pendingLeaves.length > 0 && (
          <>
            <Divider orientation="left" className="sticky top-0 bg-white z-10">
              Pending Approval
            </Divider>
            {renderLeaveList(pendingLeaves, 'No pending leave requests')}
          </>
        )}

        <Divider orientation="left" className="sticky top-0 bg-white z-10">
          Your Approved Leave
        </Divider>
        {renderLeaveList(
          otherApprovedLeaves.filter((leave) =>
            leave.members.some((member: any) => member._id === activeUser?._id),
          ),
          'You have no upcoming approved leave',
        )}

        {showAllLeaves && (
          <>
            <h3 className="text-lg font-semibold mt-4 mb-2 sticky top-0 bg-white z-10">
              Team Members' Leave
            </h3>
            {renderLeaveList(
              otherApprovedLeaves.filter(
                (leave) =>
                  !leave.members.some(
                    (member: any) => member._id === activeUser?._id,
                  ),
              ),
              'No team members have upcoming leave',
            )}
            <Divider orientation="left" className="sticky top-0 bg-white z-10">
              Team Calendar
            </Divider>
            {holidays.length > 0 && (
              <>
                <h3 className="text-lg font-semibold mt-4 mb-2 sticky top-0 bg-white z-10">
                  Holidays
                </h3>
                {renderLeaveList(holidays, 'No upcoming holidays')}
              </>
            )}
          </>
        )}

        {deniedLeaves.length > 0 && (
          <>
            <Divider orientation="left" className="sticky top-0 bg-white z-10">
              Denied Leave
            </Divider>
            {renderLeaveList(deniedLeaves, 'No denied leave requests')}
          </>
        )}
      </div>

      <LeaveRequestModal
        visible={showModal}
        onClose={() => {
          setEditingLeave(null);
          setShowModal(false);
        }}
        onSuccess={handleSuccess}
        initialValues={editingLeave}
      />
    </div>
  );
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'approved':
      return 'green';
    case 'denied':
      return 'red';
    default:
      return 'gold';
  }
};

const getTypeColor = (type: LeaveType) => {
  switch (type) {
    case 'PTO':
      return 'blue';
    case 'Holiday':
      return 'purple';
    case 'Medical':
      return 'cyan';
    case 'Remote Work':
      return 'yellow';
    default:
      return 'default';
  }
};

export default UpcomingLeaves;
