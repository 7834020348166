import { useEffect, useState, Suspense } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { format, formatDate } from 'date-fns';
import { activeCompanyState } from '../atoms/new.company.atom';
import AssigneeTaskList from '../components/Dashboard/AssigneeTaskList';
import CompanyTaskList from '../components/Dashboard/CompanyTaskList';
import {
  activeAuths,
  activeUserState,
  userListState,
} from '../atoms/user.atom';
import { User } from '../models/general';
import TeamScheduleViewer from '../components/Dashboard/TeamScheduleViewer';
import WorkLoadHeatMap from '../components/Dashboard/WorkLoadHeatMap';
import { keyMilestones } from '../atoms/project.atom';
import MilestoneTable from '../components/Dashboard/MilestoneTable';
import MilestoneList from '../components/Dashboard/MilestoneTable';
import CompanyGanttChart from '../components/Dashboard/CompanyGanttChart';
import MilestoneMetrics from '../components/Dashboard/MilestoneMetrics';
import { leavesState } from '../atoms/leave.atom';
import UpcomingLeaves from '../components/Dashboard/UpcomingLeaves';
import { fetchLeaves, fetchUsersByCompany } from '../utils/api';
import { ThreadManager } from '../components/Thread/ThreadManager';

const Main = () => {
  const activeCompany = useRecoilValue(activeCompanyState);
  const activeUser = useRecoilValue(activeUserState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const milestoneValues = useRecoilValue(keyMilestones);
  const setLeaves = useSetRecoilState(leavesState);
  const setUsers = useSetRecoilState(userListState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [leavesResponse, usersResponse] = await Promise.all([
          fetchLeaves(),
          activeCompany?._id
            ? fetchUsersByCompany(activeCompany._id)
            : Promise.resolve({ data: [] }),
        ]);

        setLeaves(leavesResponse.data);
        if (usersResponse.data) {
          setUsers(usersResponse.data);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setLeaves, setUsers, activeCompany?._id]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">Loading...</div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-full">
          Loading...
        </div>
      }
    >
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-xl md:text-2xl text-bold">
            {activeCompany.name} Dashboard -{' '}
            <span className="text-gray-500">
              {format(new Date(), 'MMM d, yyyy')}
            </span>{' '}
          </h2>
        </div>
        <div className="container mx-auto py-4">
          <h1 className="text-xl md:text-2xl font-bold mb-4">
            Company Performance Metrics
          </h1>
          <MilestoneMetrics />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 mt-4">
        <div className="w-full md:w-2/3">
          <div className="bg-white rounded-lg shadow-lg">
            <h2 className="text-xl md:text-2xl font-bold p-4 border-b">
              Team Communication
            </h2>
            <ThreadManager />
          </div>
        </div>

        <div className="w-full md:w-1/3">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <UpcomingLeaves />
          </div>
        </div>
      </div>

      <div className="bg-white mt-4 p-4 rounded-lg shadow-lg">
        <h2 className="text-xl md:text-2xl text-bold">My Tasks:</h2>
        <AssigneeTaskList user={activeUser || ({} as User)} weeksToShow={2} />
      </div>

      {activeAuthorizations.hasOwner && (
        <>
          <div className="bg-white mt-4 p-4 rounded-lg shadow-lg">
            <CompanyTaskList />
          </div>
          <div className="bg-white mt-4 p-4 rounded-lg shadow-lg">
            <TeamScheduleViewer />
          </div>
        </>
      )}
      {activeAuthorizations.hasOwner && milestoneValues && (
        <div className="bg-white mt-4 p-4 rounded-lg shadow-lg">
          <WorkLoadHeatMap companyId={activeCompany._id} />
          <MilestoneList milestoneValues={milestoneValues} />
        </div>
      )}
    </Suspense>
  );
};

export default Main;
