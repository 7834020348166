import { atom, useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { SocketService } from '../services/socket.service';
import { fetchNotifications } from '../utils/api';

interface Notification {
  type: 'mention';
  threadId: string;
  messageId: string;
  mentionedBy: string;
  timestamp: Date;
  read: boolean;
}

// Define the shape of the API response
interface NotificationResponse {
  type: 'mention';
  threadId: string;
  messageId: string;
  mentionedBy: string;
  timestamp: string;
  read: boolean;
}

const notificationsState = atom<Notification[]>({
  key: 'notificationsState',
  default: [],
});

export const useNotifications = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  useEffect(() => {
    // Fetch existing notifications
    const loadNotifications = async () => {
      try {
        const response = await fetchNotifications();
        console.log('Loaded notifications:', response.data);
        setNotifications(
          response.data.map((n: NotificationResponse) => ({
            ...n,
            timestamp: new Date(n.timestamp),
          })),
        );
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    loadNotifications();

    // Set up socket listener
    const socket = SocketService.getInstance();
    const handleNotification = (notification: Notification) => {
      console.log('Socket received notification:', notification);
      addNotification({
        ...notification,
        timestamp: new Date(notification.timestamp),
      });
    };

    socket.addNotificationHandler(handleNotification);

    return () => {
      socket.removeNotificationHandler(handleNotification);
    };
  }, []);

  const addNotification = (notification: Notification) => {
    console.log('Adding notification to state:', notification);
    setNotifications((prev) => {
      // Check for duplicates
      const isDuplicate = prev.some(
        (n) => n.messageId === notification.messageId,
      );
      if (isDuplicate) {
        console.log('Duplicate notification detected, skipping');
        return prev;
      }

      const newNotifications = [...prev, notification];
      console.log('New notifications state:', newNotifications);
      return newNotifications;
    });
  };

  const markAsRead = (messageId: string) => {
    setNotifications((prev) =>
      prev.map((notif) =>
        notif.messageId === messageId ? { ...notif, read: true } : notif,
      ),
    );
  };

  const getUnreadCount = () => {
    return notifications.filter((n) => !n.read).length;
  };

  return {
    notifications,
    addNotification,
    markAsRead,
    getUnreadCount,
  };
};
