// src/api.ts

import axios from 'axios';
import { getToken, clearToken } from '../utils/tokenManager';
import { jwtDecode } from 'jwt-decode';
import useAuth from '../hooks/useAuth';
import { useError } from '../context/ErrorContext';

// Create a function to handle API errors consistently
export const createApiInstance = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // Enhanced request interceptor with token verification
  api.interceptors.request.use(
    async (config) => {
      const token = getToken();
      if (token && token.length > 0) {
        try {
          // Check token expiration
          const decoded: { exp: number } = jwtDecode(token);
          if (decoded.exp * 1000 < Date.now()) {
            clearToken();
            window.location.href = '/login'; // Force redirect to login
            throw new Error('Token expired');
          }

          config.headers = { Authorization: `Bearer ${token}` };
        } catch (error) {
          clearToken();
          window.location.href = '/login'; // Force redirect to login
          throw error;
        }
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // Response interceptor with error handling
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorData = {
        code: error.response?.status,
        statusText: error.response?.statusText || 'Error',
        context:
          error.response?.data?.message || 'An unexpected error occurred',
      };

      window.dispatchEvent(
        new CustomEvent('api-error', {
          detail: errorData,
        }),
      );

      return Promise.reject(errorData);
    },
  );

  return api;
};

const api = createApiInstance();

export const fetchUsers = () => api.get('/users');
export const createUser = (data: {}) => api.post('/users', data);
export const fetchUser = (id: string) => api.get(`/users/${id}`);
export const fetchUsersByCompany = (id: string) =>
  api.get(`/users/company/${id}`);
export const updateUser = (id: string, data: {}) =>
  api.put(`/users/${id}`, data);
export const deleteUser = (id: string) => api.delete(`/users/${id}`);

export const fetchProjects = () => api.get('/projects');
export const fetchProjectsByCompany = (id: string) =>
  api.get(`/projects/company/${id}`);
export const fetchProjectsByClient = (id: string) =>
  api.get(`/projects/client/${id}`);
export const createProject = (data: {}) => api.post('/projects', data);
export const fetchProject = (id: string) => api.get(`/projects/${id}`);
export const updateProject = async (id: string, data: any) => {
  console.log('API: Updating project:', id, data);
  const response = await api.put(`/projects/${id}`, data);
  console.log('API: Update response:', response);
  return response;
};
export const deleteProject = (id: string) => api.delete(`/projects/${id}`);

export const createThread = (data: {
  name: string;
  companyId: string;
  projectId?: string;
  type: 'channel' | 'project';
  members: string[];
}) => api.post('/threads', data);

export const sendMessage = (
  projectId: string,
  threadId: string,
  message: string,
  notify: boolean,
  isLetterAgreement: boolean,
) =>
  api.post(
    `/projects/${projectId}/threads/${threadId}/messages?sendEmail=${notify}${isLetterAgreement ? '&isLetterAgreement=true' : ''}`,
    {
      content: message,
    },
  );

export const fetchMessages = (
  projectId: string,
  threadId: string,
  page?: number,
  pageSize?: number,
) =>
  api.get(
    `projects/${projectId}/threads/${threadId}?${page ? 'page=' + page : ''}${page ? '&pageSize=' + pageSize : ''}`,
  );

export const fetchCompanies = () => api.get('/companies');
export const createCompany = (data: {}) => api.post('/companies', data);
export const fetchCompany = (id: string) => api.get(`/companies/${id}`);
export const updateCompany = (id: string, data: {}) =>
  api.put(`/companies/${id}`, data);
export const deleteCompany = (id: string) => api.delete(`/companies/${id}`);

export const signup = (data: {}) => api.post('/auth/signup', data);
export const signin = (data: {}) => api.post('/auth/signin', data);
export const getLinkToken = (data: {}) => api.post('/auth/getlinktoken', data);
export const checkLinkToken = (data: {}) =>
  api.post('/auth/checklinktoken', data);

export const uploadFile = async (
  companyId: string,
  file: File,
  fileName: string,
  description: string,
  projectId?: string,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', fileName);
  formData.append('description', description);

  return api.post(
    `/file-upload?companyId=${companyId}${projectId ? `&projectId=${projectId}` : ''}`,
    formData,
  );
};

// Add new function for adding chat file to project
export const addProjectChatFile = (
  projectId: string,
  fileInfo: { url: string; name: string; mimetype: string },
) => api.post(`/projects/${projectId}/chat-file`, fileInfo);

export const getSignedUrl = (data: {}) =>
  api.post(`/file-upload/signed-url`, data);

// Milestone API functions
export const createMilestone = (data: {}) => api.post('/milestone', data);
export const fetchMilestones = () => api.get('/milestone');
export const fetchMilestone = (id: string) => api.get(`/milestone/${id}`);
export const fetchMilestonesByProject = (projectId: string) =>
  api.get(`/milestone/project/${projectId}`);
export const updateMilestone = (id: string, data: {}) =>
  api.patch(`/milestone/${id}`, data);
export const deleteMilestone = (id: string) => api.delete(`/milestone/${id}`);

// Task API functions
export const createTask = (data: {}) => api.post('/task', data);
export const fetchTasksByProject = (projectId: string) =>
  api.get(`/task/project/${projectId}`);
export const fetchTasksByAssignee = (userId: string) =>
  api.get(`/task/assignee/${userId}`);
export const fetchTasksByCompany = (companyId: string) =>
  api.get(`/task/company/${companyId}`);
export const updateTask = (id: string, data: {}) =>
  api.patch(`/task/${id}`, data);
export const deleteTask = (id: string) => api.delete(`/task/${id}`);

// Template API functions
export const createTemplate = (data: {}) => api.post('/template', data);
export const fetchTemplates = () => api.get('/template');
export const fetchTemplate = (id: string) => api.get(`/template/${id}`);
export const fetchTemplatesByCompany = (companyId: string) =>
  api.get(`/template/company/${companyId}`);
export const updateTemplate = (id: string, data: {}) =>
  api.put(`/template/${id}`, data);
export const deleteTemplate = (id: string) => api.delete(`/template/${id}`);

// Function to request a password reset email
export const requestPasswordReset = (email: string) =>
  api.post('/users/request-password-reset', { email });

// Function to reset the password using the token
export const resetPassword = (token: string, newPassword: string) =>
  api.post('/users/reset-password', {
    recoveryToken: token,
    newpassword: newPassword,
  });

// Leave API functions
export const fetchLeaves = () => api.get('/leave');
export const createLeave = (data: {
  title: string;
  description?: string;
  members: string[];
  startDate: Date;
  duration: number;
}) => api.post('/leave', data);
export const fetchLeavesByCompany = (companyId: string) =>
  api.get(`/leave/company/${companyId}`);
export const fetchLeavesByMember = (userId: string) =>
  api.get(`/leave/member/${userId}`);
export const updateLeave = (id: string, data: any) =>
  api.patch(`/leave/${id}`, data);
export const deleteLeave = (id: string) => api.delete(`/leave/${id}`);

// Add more API calls as needed...

export const getMetrics = () => {
  return api.get('/metrics');
};

export const createMetrics = (metrics: any[]) => {
  return api.post('/metrics', metrics);
};

export const getMilestoneCounts = (
  date?: string,
  timeFrame?: 'week' | 'month' | 'quarter' | 'year',
) => {
  const params = new URLSearchParams();
  if (date) params.append('date', date);
  if (timeFrame) params.append('timeFrame', timeFrame);

  return api.get(`/metrics/milestone-counts?${params.toString()}`);
};

export const createBankHolidays = (year: number) =>
  api.post('/leave/bank-holidays', { year });

// New thread-related functions
export const fetchThreadsByCompany = (companyId: string) =>
  api.get(`/threads/company/${companyId}`);

export const fetchThreadsByProject = (projectId: string) =>
  api.get(`/threads/project/${projectId}`);

export const fetchThreadMessages = (
  threadId: string,
  page?: number,
  pageSize?: number,
) =>
  api.get(
    `/threads/${threadId}/messages${page ? `?page=${page}&pageSize=${pageSize}` : ''}`,
  );

export const updateThread = (threadId: string, data: {}) =>
  api.patch(`/threads/${threadId}`, data);

export const deleteThread = (threadId: string) =>
  api.delete(`/threads/${threadId}`);

export const addThreadMember = (threadId: string, userId: string) =>
  api.post(`/threads/${threadId}/members`, { userId });

export const removeThreadMember = (threadId: string, userId: string) =>
  api.delete(`/threads/${threadId}/members/${userId}`);

export const archiveThread = (threadId: string) =>
  api.patch(`/threads/${threadId}/archive`);

export const getUnreadMessageCount = (threadId: string) =>
  api.get(`/threads/${threadId}/unread`);

export const markThreadAsRead = (threadId: string) =>
  api.post(`/threads/${threadId}/read`);

// Add this with the other auth-related functions
export const verifyAuthToken = () => api.get('/auth/verify');

// Add with other API functions
export const fetchNotifications = () => api.get('/notifications');
export const markNotificationRead = (messageId: string) =>
  api.post(`/notifications/${messageId}/read`);

// Add with other thread-related functions
export const fetchThread = (threadId: string) => {
  return api.get(`/threads/${threadId}`);
};

// Add with other thread-related functions
export const addThreadMessage = (
  threadId: string,
  data: {
    content: string;
    notify?: boolean;
    isLetterAgreement?: boolean;
  },
) => api.post(`/threads/${threadId}/messages`, data);

// Agreement endpoints
export const createAgreement = (data: {
  content: string;
  projectId: string;
  createdBy: string;
  changeDescription?: string;
}) => api.post('/agreements', data);

export const getProjectAgreements = (projectId: string) =>
  api.get(`/agreements/project/${projectId}`);

export const getCurrentAgreement = (projectId: string) =>
  api.get(`/agreements/project/${projectId}/current`);

export const getAgreement = (id: string) => api.get(`/agreements/${id}`);

export const getAgreementDiff = (id1: string, id2: string) =>
  api.get(`/agreements/${id1}/diff/${id2}`);

export const updateAgreement = (
  id: string,
  data: {
    content?: string;
    changeDescription?: string;
  },
) => api.put(`/agreements/${id}`, data);

// Add these with other project-related functions
export const addDeliverable = (projectId: string, fileData: any) =>
  api.post(`/projects/${projectId}/deliverable`, fileData);

export const addProjectFile = (projectId: string, fileData: any) =>
  api.post(`/projects/${projectId}/files`, fileData);

// User creation endpoints
export const createProjectUser = (data: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  active: boolean;
  company: string;
  userType: string;
  projectId: string;
  projectName: string;
  companyName: string;
}) => api.post('/users/project', data);

export const createCompanyUser = (data: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  active: boolean;
  companyId: string;
  userType: string;
  companyName: string;
}) => api.post('/users/company', data);
