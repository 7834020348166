import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ContractComponent from '../components/AgreementLetter';
import EditableAgreement from '../components/EditableAgreement';
import StaticAgreement from '../components/StaticAgreement';

import { Project } from '../models/general';

interface OutletContext {
  activeProject: Project;
  updateProjectName: (id: string, newName: string) => void;
}

const Agreement: React.FC = () => {
  const { activeProject, updateProjectName } =
    useOutletContext<OutletContext>();

  const [staticContent, setStaticContent] = useState(
    activeProject.agreementContent || activeProject.currentAgreement?.content,
  );
  // I'm stuck some where in here. The conent keeps reseting unexpectedly.
  return (
    <div>
      {!activeProject.agreementContent && (
        <div style={{ display: 'none' }}>
          <StaticAgreement getHtmlContent={setStaticContent} />
        </div>
      )}
      <EditableAgreement initialContent={staticContent} />
    </div>
  );
};

export default Agreement;
