import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { lOETotals, milestonesState } from '../atoms/project.atom';
import ConstructionCostEstimate from '../components/ConstructionCostEstimate';
import LOEItem from '../components/LOEItem';
import { LOEItemI, Milestone, Project } from '../models/general';
import { hourlyRates } from '../utils/misc';
import { addDays } from 'date-fns';
import { fetchMilestonesByProject, updateMilestone } from '../utils/api';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import LOEDisciplineGroup from '../components/LOEDisciplineGroup';

interface ProjectContextType {
  activeProject: Project;
  updateProject: (project: Project) => void;
}

const LOE: React.FC = () => {
  const navigate = useNavigate();
  const { activeProject, updateProject } =
    useOutletContext<ProjectContextType>();
  const [loeItems, setLoeItems] = useState<LOEItemI[]>(
    activeProject.loeEstimate || [],
  );
  const totals = useRecoilValue(lOETotals);
  const [activeMilestones, setActiveMilestones] =
    useRecoilState(milestonesState);

  const isEstimating =
    activeProject.workFlowStatus === 'letter agreement sent' ||
    activeProject.workFlowStatus === 'estimating' ||
    activeProject.workFlowStatus === 'estimate - finished' ||
    activeProject.workFlowStatus === 'initiated';
  const handleLOEItemChange = (index: number, updatedItem: LOEItemI) => {
    const updatedLoeItems = [...loeItems];
    const actualIndex = loeItems.findIndex(
      (item, i) => i === index && item.discipline === updatedItem.discipline,
    );

    if (actualIndex !== -1) {
      updatedLoeItems[actualIndex] = updatedItem;
      setLoeItems(updatedLoeItems);
      handleUpdateProject({
        ...activeProject,
        loeEstimate: updatedLoeItems,
        workFlowStatus: 'estimating',
      });
    }
  };

  const handleAddLOEItem = (discipline: string) => {
    const newItem: LOEItemI = {
      discipline: discipline,
      title: '',
      hoursPE: 0,
      hoursEIT: 0,
      hoursDraftsman: 0,
      hoursAdmin: 0,
      dollarsSub: 0,
      dollarsMilage: 0,
    };
    const updatedLoeItems = [...loeItems, newItem];
    setLoeItems(updatedLoeItems);
    handleUpdateProject({
      ...activeProject,
      loeEstimate: updatedLoeItems,
      workFlowStatus: 'estimating',
    });
  };

  const handleRemoveLOEItem = (index: number) => {
    const updatedLoeItems = loeItems.filter((_, i) => i !== index);
    setLoeItems(updatedLoeItems);
    handleUpdateProject({
      ...activeProject,
      loeEstimate: updatedLoeItems,
      workFlowStatus: 'estimating',
    });
  };

  const handleSaveEstimate = async (updateStatus: boolean) => {
    if (updateStatus) {
      for (const milestone of activeMilestones || []) {
        if (!milestone._id) {
          alert('OLD MILESTONE FOUND, changes not saved');
          return;
        }

        if (milestone.title === 'Estimate Completed') {
          await updateMilestone(milestone._id, {
            completionDate: new Date(),
          });
        } else if (milestone.title === 'Letter Agreement Sent') {
          await updateMilestone(milestone._id, {
            targetDate: addDays(new Date(), 3),
          });
        }
      }
      try {
        const updatedResponse = await fetchMilestonesByProject(
          activeProject._id,
        );
        setActiveMilestones(updatedResponse.data);
      } catch (e) {
        console.log('Failed to update Milestones');
      }

      handleUpdateProject({
        ...activeProject,
        workFlowStatus: 'estimate - finished',
      });
    } else {
      handleUpdateProject({
        ...activeProject,
        workFlowStatus: 'estimating',
      });
    }

    navigate(`/project/${activeProject._id}/agreement`);
  };

  const handleReOpenEstimate = () => {
    handleUpdateProject({
      ...activeProject,
      workFlowStatus: 'estimating',
    });
  };
  const handleMakeTemplate = () => {
    let template = { 'matts scope': {} };
    activeProject.disciplines.forEach((discipline: string) => {
      template['matts scope'][discipline] = { lineItems: [] };
      loeItems.forEach((item) => {
        if (item.discipline === discipline)
          template['matts scope'][discipline].lineItems.push({
            title: item.title,
            hoursPE: item.hoursPE,
            hoursEIT: item.hoursEIT,
            hoursDraftsman: item.hoursDraftsman,
            hoursAdmin: item.hoursAdmin,
            dollarsSub: item.dollarsSub,
            dollarsMilage: item.dollarsMilage,
          });
      });
    });
    console.log(template);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(loeItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLoeItems(items);
    handleUpdateProject({
      ...activeProject,
      loeEstimate: items,
      workFlowStatus: 'estimating',
    });
  };

  const handleUpdateProject = async (updatedData: Partial<Project>) => {
    try {
      await updateProject(activeProject._id, {
        ...activeProject,
        ...updatedData,
      });
    } catch (error) {
      console.error('Failed to update project', error);
      throw error;
    }
  };

  return (
    <div className="">
      <div className="grid grid-cols-3 content-center mt-2">
        {isEstimating && (
          <>
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto"
              onClick={() => handleSaveEstimate(true)}
            >
              Complete Estimate
            </button>
          </>
        )}
        {!isEstimating && (
          <button
            type="submit"
            className="rounded bg-red-500 px-4 py-2 text-white w-60 font-medium mx-auto"
            onClick={() => handleReOpenEstimate()}
          >
            Re-Open Estimate
          </button>
        )}
        {/* <button
          type="submit"
          className="rounded bg-red-500 px-4 py-2 text-white w-60 font-medium mx-auto"
          onClick={() => handleMakeTemplate()}
        >
          Make Template
        </button> */}
      </div>

      <h4 className="text-gray-600 text-2xl font-semibold mt-2">
        Estimate Total - ${totals.total}
      </h4>

      <DragDropContext onDragEnd={handleDragEnd}>
        {activeProject.disciplines.map((discipline: string) => (
          <LOEDisciplineGroup
            key={discipline}
            discipline={discipline}
            items={loeItems}
            isEstimating={isEstimating}
            disciplineTotal={(totals as any)[discipline]}
            onAddItem={handleAddLOEItem}
            onUpdateItem={handleLOEItemChange}
            onRemoveItem={handleRemoveLOEItem}
          />
        ))}
      </DragDropContext>

      <div className="grid grid-cols-3 content-center mt-2">
        {isEstimating && (
          <>
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto"
              onClick={() => handleSaveEstimate(true)}
            >
              Complete Estimate
            </button>
          </>
        )}
        {!isEstimating && (
          <button
            type="submit"
            className="rounded bg-red-500 px-4 py-2 text-white w-60 font-medium mx-auto"
            onClick={() => handleReOpenEstimate()}
          >
            Re-Open Estimate
          </button>
        )}
        {/* <button
          type="submit"
          className="rounded bg-red-500 px-4 py-2 text-white w-60 font-medium mx-auto"
          onClick={() => handleMakeTemplate()}
        >
          Make Template
        </button> */}
      </div>
    </div>
  );
};

export default LOE;
