import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { companyMembersState } from '../../atoms/company.members.atom';
import { useNotifications } from '../../hooks/useNotifications';
import {
  markNotificationRead,
  fetchUsersByCompany,
  fetchThread,
  fetchProject,
} from '../../utils/api';

interface NotificationContext {
  threadName: string;
  projectName?: string;
  projectId?: string;
}

export const NotificationsList: React.FC = () => {
  const navigate = useNavigate();
  const { notifications } = useNotifications();
  const setCompanyMembers = useSetRecoilState(companyMembersState);
  const companyMembers = useRecoilValue(companyMembersState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationContexts, setNotificationContexts] = useState<
    Record<string, NotificationContext>
  >({});

  // Load thread and project details for each notification
  useEffect(() => {
    const loadContexts = async () => {
      const contexts: Record<string, NotificationContext> = {};

      for (const notification of notifications) {
        try {
          const threadResponse = await fetchThread(notification.threadId);
          const thread = threadResponse.data;

          let context: NotificationContext = {
            threadName: thread.name,
          };

          if (thread.projectId) {
            const projectResponse = await fetchProject(thread.projectId);
            const project = projectResponse.data;
            context.projectName = project.name;
            context.projectId = project._id;
          }

          contexts[notification.threadId] = context;
        } catch (error) {
          console.error('Failed to load context for notification:', error);
        }
      }

      setNotificationContexts(contexts);
    };

    if (notifications.length > 0) {
      loadContexts();
    }
  }, [notifications]);

  useEffect(() => {
    const loadCompanyMembers = async () => {
      if (
        activeCompany?._id &&
        (!companyMembers || companyMembers.length === 0)
      ) {
        try {
          const response = await fetchUsersByCompany(activeCompany._id);
          setCompanyMembers(response.data);
        } catch (error) {
          console.error('Failed to fetch company members:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    loadCompanyMembers();
  }, [activeCompany?._id, setCompanyMembers, companyMembers]);

  // Create a new array before sorting
  const sortedNotifications = [...notifications].sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
  );

  const handleNotificationClick = async (notification: any) => {
    try {
      await markNotificationRead(notification.messageId);
      const context = notificationContexts[notification.threadId];

      if (context?.projectId) {
        // Navigate to project and expand it
        navigate(
          `/projects/${context.projectId}?threadId=${notification.threadId}&messageId=${notification.messageId}`,
        );
      } else {
        // Navigate to thread directly
        navigate(
          `/?threadId=${notification.threadId}&messageId=${notification.messageId}`,
        );
      }
    } catch (error) {
      console.error('Error handling notification:', error);
    }
  };

  const getMemberName = (userId: string) => {
    const member = companyMembers.find((m) => m._id === userId);
    return member ? `${member.firstName} ${member.lastName}` : 'Someone';
  };

  return (
    <div className="max-h-96 overflow-y-auto divide-y divide-gray-700">
      {isLoading ? (
        <div className="p-4 text-gray-400 text-center">Loading...</div>
      ) : sortedNotifications.length === 0 ? (
        <div className="p-4 text-gray-400 text-center">
          No notifications yet
        </div>
      ) : (
        sortedNotifications.map((notification) => {
          const context = notificationContexts[notification.threadId];
          return (
            <div
              key={notification.messageId}
              className={`p-4 cursor-pointer hover:bg-gray-700 ${
                !notification.read ? 'bg-gray-700' : ''
              }`}
              onClick={() => handleNotificationClick(notification)}
            >
              <div className="flex items-center gap-2">
                <div className="flex-1">
                  <p className="text-sm text-gray-200">
                    <span className="font-medium text-white">
                      {getMemberName(notification.mentionedBy)}
                    </span>{' '}
                    mentioned you in{' '}
                    <span className="font-medium text-white">
                      {context?.threadName || 'a thread'}
                    </span>
                    {context?.projectName && (
                      <span className="text-gray-400">
                        {' '}
                        in project{' '}
                        <span className="text-white">
                          {context.projectName}
                        </span>
                      </span>
                    )}
                  </p>
                  <p className="text-xs text-gray-400">
                    {new Date(notification.timestamp).toLocaleString()}
                  </p>
                </div>
                {!notification.read && (
                  <div className="w-2 h-2 bg-blue-400 rounded-full" />
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
