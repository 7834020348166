import React from 'react';
import { Company } from '../models/general';

interface EditCompanyPopupProps {
  company: Company;
  onClose: () => void;
  onSave: () => void;
  setSelectedCompany: React.Dispatch<React.SetStateAction<Company | null>>;
  additionalFields?: React.ReactNode;
}

const EditCompanyPopup: React.FC<EditCompanyPopupProps> = ({
  company,
  onClose,
  onSave,
  setSelectedCompany,
  additionalFields,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedCompany((prev) => (prev ? { ...prev, [name]: value } : prev));
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h2 className="text-2xl font-semibold mb-4">Edit Company</h2>
        <div className="mb-4">
          <input
            type="text"
            name="name"
            placeholder="Company Name"
            value={company.name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={company.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={company.phone}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={company.address}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="text"
            name="website"
            placeholder="Website"
            value={company.website || ''}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="text"
            name="logoUrl"
            placeholder="Logo URL"
            value={company.logoUrl || ''}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
        </div>
        {additionalFields}
        <div className="flex justify-end">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={onSave}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCompanyPopup;
