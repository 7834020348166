import React, { useState } from 'react';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { Task as WorkTask } from '../../models/general';
import { updateTask } from '../../utils/api';
import { compareAsc } from 'date-fns';

interface ProjectGanttChartProps {
  tasks: WorkTask[];
  setTasks: (tasks: WorkTask[]) => void;
  onTaskDoubleClick: (task: WorkTask) => void;
  isViewOnly?: boolean;
}

const ProjectGanttChart: React.FC<ProjectGanttChartProps> = ({
  tasks,
  onTaskDoubleClick,
  setTasks,
  isViewOnly,
}) => {
  const [collapsedMilestones, setCollapsedMilestones] = useState<Set<string>>(
    new Set(),
  );
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Day);

  const convertTasks = (tasks: WorkTask[]): Task[] => {
    const ganttTasks: Task[] = [];

    // Group tasks by milestone
    const tasksGroupedByMilestone: { [key: string]: WorkTask[] } = tasks.reduce(
      (acc, task) => {
        const milestoneId = task.milestone?._id || 'No Milestone';
        if (!acc[milestoneId]) {
          acc[milestoneId] = [];
        }
        acc[milestoneId].push(task);
        return acc;
      },
      {} as { [key: string]: WorkTask[] },
    );

    // Sort milestones by their sequence value
    const sortedMilestones = Object.keys(tasksGroupedByMilestone).sort(
      (a, b) => {
        const milestoneA = tasksGroupedByMilestone[a][0].milestone;
        const milestoneB = tasksGroupedByMilestone[b][0].milestone;
        const sequenceA = milestoneA?.sequence ?? Number.MAX_VALUE;
        const sequenceB = milestoneB?.sequence ?? Number.MAX_VALUE;
        return sequenceA - sequenceB;
      },
    );

    // Convert tasks grouped by milestone to Gantt chart tasks
    sortedMilestones.forEach((milestoneId) => {
      const milestoneTasks = tasksGroupedByMilestone[milestoneId];
      const milestoneTitle =
        milestoneTasks[0].milestone?.title || 'No Milestone';

      // Sort tasks within each milestone by startDate
      milestoneTasks.sort((taskA, taskB) =>
        compareAsc(new Date(taskA.startDate), new Date(taskB.startDate)),
      );

      // Calculate start and end dates for the milestone based on tasks
      const milestoneStart = new Date(
        Math.min(
          ...milestoneTasks.map((task) => new Date(task.startDate).getTime()),
        ),
      );

      const milestoneEnd = new Date(
        Math.max(
          ...milestoneTasks.map(
            (task) =>
              new Date(task.startDate).getTime() +
              task.duration * 24 * 60 * 60 * 1000,
          ),
        ),
      );

      // Calculate progress of the milestone as the average progress of all tasks
      const milestoneProgress =
        milestoneTasks.reduce((sum, task) => {
          switch (task.status) {
            case 'Done':
              return sum + 100;
            case 'Blocked':
              return sum + 0;
            case 'In Progress':
              return sum + 25;
            case 'In Review':
              return sum + 75;
            default:
              return sum + 0;
          }
        }, 0) / milestoneTasks.length;

      // Add milestone as a task
      ganttTasks.push({
        id: milestoneId,
        name: milestoneTitle,
        start: milestoneStart,
        end: milestoneEnd,
        type: 'task',
        progress: milestoneProgress,
        hideChildren: false,
        styles: {
          progressColor: '#B8860B', // Dark goldenrod for progress indicator
          progressSelectedColor: '#DAA520', // Goldenrod for selected progress
          backgroundColor: '#FFA500', // Darker shade of yellow for the milestone
        },
        isMilestone: true,
      });

      // If the milestone is collapsed, skip adding its tasks
      if (!collapsedMilestones.has(milestoneId)) {
        milestoneTasks.forEach((task: WorkTask) => {
          let progress = 0;
          let styles = {};

          // Set progress and styles based on the task status
          switch (task.status) {
            case 'Done':
              progress = 100;
              styles = { backgroundColor: 'green', border: '1px solid green' };
              break;
            case 'Blocked':
              progress = 0;
              styles = { backgroundColor: 'red', border: '1px solid red' };
              break;
            case 'To Do':
              progress = 0;
              styles = {};
              break;
            case 'In Progress':
              progress = 25;
              styles = { backgroundColor: 'blue', border: '1px solid blue' };
              break;
            case 'In Review':
              progress = 75;
              styles = { backgroundColor: 'blue', border: '1px solid blue' };
              break;
            default:
              progress = 50;
              styles = { backgroundColor: 'blue', border: '1px solid blue' };
              break;
          }

          // Update task name to display scope in uppercase followed by title
          const taskName = `${task.scope?.toUpperCase() || 'GENERAL'} - ${
            task.title
          } - ${task.status}`;

          ganttTasks.push({
            id: task._id || '1',
            name: taskName,
            start: new Date(task.startDate) || new Date(),
            end:
              new Date(
                new Date(task.startDate).getTime() +
                  task.duration * 24 * 60 * 60 * 1000,
              ) || new Date(),
            type: 'task',
            progress: progress,
            dependencies: [],
            styles: styles,
            parentId: milestoneId,
          });
        });
      }
    });

    return ganttTasks;
  };

  const handleGanttDoubleClick = (ganttTask: Task) => {
    if (ganttTask.isMilestone) return handleExpanderClick(ganttTask);
    if (isViewOnly) return;
    const correspondingTask = tasks.find((t) => t._id === ganttTask.id);
    if (correspondingTask) {
      onTaskDoubleClick(correspondingTask);
    }
  };

  const handleExpanderClick = (task: Task) => {
    if (task.isMilestone) {
      setCollapsedMilestones((prev) => {
        const updatedCollapsedMilestones = new Set(prev);
        if (updatedCollapsedMilestones.has(task.id)) {
          updatedCollapsedMilestones.delete(task.id);
        } else {
          updatedCollapsedMilestones.add(task.id);
        }
        return updatedCollapsedMilestones;
      });
    }
  };

  const handleTaskChange = async (updatedTask: Task) => {
    if (isViewOnly) return;
    if (updatedTask.isMilestone) {
      // Handle milestone drag
      const milestoneTasks = tasks
        .filter((task) => task.milestone?._id === updatedTask.id)
        .sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
        );

      if (milestoneTasks.length > 0) {
        // Calculate time shift
        const timeDiff =
          updatedTask.start.getTime() -
          new Date(milestoneTasks[0].startDate).getTime();

        // Update all tasks in the milestone with new dates
        const updatedTasks = milestoneTasks.map((task) => ({
          ...task,
          startDate: new Date(new Date(task.startDate).getTime() + timeDiff),
        }));

        try {
          // Update all tasks in the milestone
          const updatePromises = updatedTasks.map((task) =>
            updateTask(task._id!, task),
          );
          const responses = await Promise.all(updatePromises);

          // Update local state
          setTasks(
            tasks.map((task) => {
              const updatedTask = responses.find(
                (r) => r.data._id === task._id,
              );
              if (updatedTask) {
                console.log(updatedTask.data.startDate);
              }
              return updatedTask
                ? { ...task, startDate: updatedTask.data.startDate }
                : task;
            }),
          );
        } catch (error) {
          console.error('Failed to update milestone tasks', error);
        }
      }
    } else {
      // Handle individual task drag (existing code)
      const correspondingTask = tasks.find((t) => t._id === updatedTask.id);
      if (correspondingTask) {
        correspondingTask.startDate = updatedTask.start;
        delete correspondingTask.__v;

        try {
          setTasks(tasks);
          const response = await updateTask(
            correspondingTask._id,
            correspondingTask,
          );
          const newTasks = tasks.map((task) => {
            return task._id === correspondingTask._id
              ? { ...task, startDate: response.data.startDate }
              : task;
          });
          setTasks(newTasks);
        } catch (error) {
          console.error('Failed to update task status', error);
        }
      }
    }
  };

  const CustomTaskListTable = ({ row, task }) => {
    return (
      <div className="task-list-item">
        {/* Render only the columns that you need */}
        <span className="task-list-name">{task.title}</span>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <label htmlFor="timeframe" className="text-gray-700 font-medium">
            Time Frame:
          </label>
          <select
            id="timeframe"
            className="px-4 py-2 rounded-md border border-gray-300"
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value as ViewMode)}
          >
            <option value={ViewMode.Day}>Day</option>
            <option value={ViewMode.Week}>Week</option>
            <option value={ViewMode.Month}>Month</option>
          </select>
        </div>
      </div>

      <Gantt
        tasks={convertTasks(tasks)}
        viewMode={viewMode}
        onDoubleClick={handleGanttDoubleClick}
        onExpanderClick={handleExpanderClick}
        onDateChange={handleTaskChange}
        listCellWidth={'0'}
        todayColor="grey"
      />
    </div>
  );
};

export default ProjectGanttChart;
