import React, { useState, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { activeThreadState, threadsState } from '../../atoms/thread.atom';
import { activeUserState, activeAuths } from '../../atoms/user.atom';
import { ThreadList } from './ThreadList';
import { ThreadChat } from './ThreadChat';
import { Thread } from '../../models/thread.types';
import {
  PlusIcon,
  HashtagIcon,
  FolderIcon,
  ArchiveBoxIcon,
  TrashIcon,
  LockClosedIcon,
  UserGroupIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import {
  createThread,
  archiveThread,
  fetchThreadsByCompany,
  fetchUsersByCompany,
  updateThread,
  getUnreadMessageCount,
  markThreadAsRead,
} from '../../utils/api';
import { Button, Popconfirm, Select, Switch } from 'antd';
import { User } from '../../models/general';
import { SocketService } from '../../services/socket.service';
import { projectListState } from '../../atoms/project.atom';
import { companyMembersState } from '../../atoms/company.members.atom';
import { useSearchParams, useNavigate } from 'react-router-dom';

interface CreateThreadForm {
  name: string;
  isPrivate: boolean;
  members: string[];
  projectId?: string;
}

export const ThreadManager: React.FC = () => {
  const [searchParams] = useSearchParams();
  const initialThreadId = searchParams.get('threadId');
  const highlightMessageId = searchParams.get('messageId');
  const [isCreating, setIsCreating] = useState(false);
  const [newThreadName, setNewThreadName] = useState('');
  const [threads, setThreads] = useRecoilState(threadsState);
  const [activeThread, setActiveThread] = useRecoilState(activeThreadState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const activeUser = useRecoilValue(activeUserState);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const [form, setForm] = useState<CreateThreadForm>({
    name: '',
    isPrivate: false,
    members: [],
    projectId: undefined,
  });
  const [companyMembers, setCompanyMembers] =
    useRecoilState(companyMembersState);
  const [editingThread, setEditingThread] = useState<Thread | null>(null);
  const [unreadCounts, setUnreadCounts] = useState<Record<string, number>>({});
  const projects = useRecoilValue(projectListState);
  const [isCreatingProjectThread, setIsCreatingProjectThread] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedProjects, setExpandedProjects] = useState<Set<string>>(
    new Set(),
  );
  const navigate = useNavigate();

  const handleArchive = async (threadId: string) => {
    try {
      await archiveThread(threadId);
      setThreads(threads.filter((t) => t._id !== threadId));
      if (activeThread?._id === threadId) {
        setActiveThread(null);
      }
    } catch (error) {
      console.error('Failed to archive thread:', error);
    }
  };

  const handleCreateThread = async () => {
    if (!form.name.trim() || !activeCompany?._id) return;

    try {
      const response = await createThread({
        name: form.name,
        companyId: activeCompany._id,
        type: isCreatingProjectThread ? 'project' : 'channel',
        projectId: isCreatingProjectThread ? form.projectId : undefined,
        isPrivate: form.isPrivate,
        members: form.isPrivate ? form.members : [],
      });

      setThreads((prev) => [...prev, response.data]);
      setForm({
        name: '',
        isPrivate: false,
        members: [],
        projectId: undefined,
      });
      setIsCreating(false);
      setIsCreatingProjectThread(false);
    } catch (error) {
      console.error('Failed to create thread:', error);
    }
  };

  useEffect(() => {
    const loadThreads = async () => {
      if (!activeCompany?._id) return;

      try {
        const response = await fetchThreadsByCompany(activeCompany._id);
        setThreads(response.data);
      } catch (error) {
        console.error('Failed to fetch threads:', error);
      }
    };

    loadThreads();
  }, [activeCompany?._id, setThreads]);

  useEffect(() => {
    const loadCompanyMembers = async () => {
      if (!activeCompany?._id) return;
      try {
        const response = await fetchUsersByCompany(activeCompany._id);
        setCompanyMembers(response.data);
      } catch (error) {
        console.error('Failed to fetch company members:', error);
      }
    };

    loadCompanyMembers();
  }, [activeCompany?._id]);

  useEffect(() => {
    const fetchUnreadCounts = async () => {
      if (!threads.length || !activeUser?._id) return;

      try {
        const counts = await Promise.all(
          threads.map(async (thread) => {
            const response = await getUnreadMessageCount(thread._id);
            return [thread._id, response.data];
          }),
        );
        console.log(Object.fromEntries(counts));
        setUnreadCounts(Object.fromEntries(counts));
      } catch (error) {
        console.error('Failed to fetch unread counts:', error);
      }
    };

    fetchUnreadCounts();

    const interval = setInterval(fetchUnreadCounts, 30000);
    return () => clearInterval(interval);
  }, [threads, activeUser?._id]);

  useEffect(() => {
    const socket = SocketService.getInstance().getSocket();

    socket.on('unreadCountUpdate', ({ threadId, count }) => {
      setUnreadCounts((prev) => ({
        ...prev,
        [threadId]: (prev[threadId] || 0) + count,
      }));
    });

    return () => {
      socket.off('unreadCountUpdate');
    };
  }, []);

  const getMemberDetails = (memberIds: string[]) => {
    return memberIds
      .map((id) => companyMembers.find((member) => member._id === id))
      .filter((member) => member !== undefined);
  };

  const handleEditMembers = async (threadId: string, members: string[]) => {
    try {
      const response = await updateThread(threadId, { members });
      console.log(response.data);
      // Update the threads state with the response data
      setThreads(
        threads.map((t) =>
          t._id === threadId
            ? { ...t, members: getMemberDetails(response.data.members) }
            : t,
        ),
      );

      // Update the editing thread state to show correct members in modal
      setEditingThread((prev) =>
        prev && prev._id === threadId
          ? { ...prev, members: response.data.members }
          : prev,
      );
    } catch (error) {
      console.error('Failed to update thread members:', error);
    }
  };

  const groupedThreads = useMemo(() => {
    const channels = threads.filter((thread) => !thread.projectId);
    const projectThreads = threads.filter((thread) => thread.projectId);

    const projectGroups = projects.reduce(
      (acc, project) => {
        const projectThreadsList = projectThreads.filter(
          (thread) => thread.projectId === project._id,
        );
        if (projectThreadsList.length > 0) {
          acc[project._id] = {
            project,
            threads: projectThreadsList,
          };
        }
        return acc;
      },
      {} as Record<string, { project: Project; threads: Thread[] }>,
    );

    return { channels, projectGroups };
  }, [threads, projects]);

  const filteredContent = useMemo(() => {
    const query = searchQuery.toLowerCase();

    return {
      channels: groupedThreads.channels.filter((thread) =>
        thread.name.toLowerCase().includes(query),
      ),
      projectGroups: Object.entries(groupedThreads.projectGroups).filter(
        ([_, { project, threads }]) =>
          project.name.toLowerCase().includes(query) ||
          threads.some((thread) => thread.name.toLowerCase().includes(query)),
      ),
    };
  }, [groupedThreads, searchQuery]);

  const toggleProject = (projectId: string) => {
    setExpandedProjects((prev) => {
      const next = new Set(prev);
      if (next.has(projectId)) {
        next.delete(projectId);
      } else {
        next.add(projectId);
      }
      return next;
    });
  };

  const canArchiveThread = (thread: Thread) => {
    return (
      activeUser?._id === thread.createdBy || activeAuthorizations.hasOwner
    );
  };

  const canManageThread = (thread: Thread) => {
    return (
      activeUser?._id === thread.createdBy || activeAuthorizations.hasOwner
    );
  };

  const renderEditButton = (thread: Thread) => {
    if (!thread.isPrivate) return null;

    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setEditingThread(thread);
        }}
        className="p-1 hover:bg-gray-100 rounded-full mr-1"
        title="Edit members"
      >
        <UserGroupIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
      </button>
    );
  };

  const handleThreadSelect = async (thread: Thread) => {
    setActiveThread(thread);
    // Reset unread count for this thread
    setUnreadCounts((prev) => ({
      ...prev,
      [thread._id]: 0,
    }));

    try {
      await markThreadAsRead(thread._id);
    } catch (error) {
      console.error('Failed to mark thread as read:', error);
    }
  };

  const renderThreadItem = (thread: Thread) => (
    <div
      key={thread._id}
      className={`flex items-center justify-between w-full px-3 py-2 text-sm rounded-md hover:bg-gray-100 ${
        activeThread?._id === thread._id ? 'bg-gray-100' : ''
      }`}
    >
      <div
        className="flex items-center gap-2 flex-1 cursor-pointer"
        onClick={() => handleThreadSelect(thread)}
      >
        {thread.isPrivate ? (
          <LockClosedIcon className="w-4 h-4 text-gray-400" />
        ) : (
          <HashtagIcon className="w-4 h-4 text-gray-400" />
        )}
        <span className="flex-1 flex items-center gap-1">
          {thread.name}
          {unreadCounts[thread._id] > 0 && (
            <span className="px-1.5 py-0.5 text-xs bg-blue-500 text-white rounded-full min-w-[1.25rem] text-center">
              {unreadCounts[thread._id]}
            </span>
          )}
        </span>
      </div>
      <div className="flex items-center gap-2">
        {thread.isPrivate &&
          canManageThread(thread) &&
          renderEditButton(thread)}
        {canArchiveThread(thread) && (
          <Popconfirm
            title="Archive this thread?"
            onConfirm={() => handleArchive(thread._id)}
            okText="Yes"
            cancelText="No"
          >
            <button
              className="p-1 hover:bg-gray-100 rounded-full"
              title="Archive thread"
            >
              <TrashIcon className="w-4 h-4 text-yellow-500 hover:text-yellow-600" />
            </button>
          </Popconfirm>
        )}
      </div>
    </div>
  );

  const handleCreateProjectThread = (
    projectId: string,
    projectName: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      projectId,
      name: '', // Reset name but keep the selected project
    }));
    setIsCreatingProjectThread(true);
    setIsCreating(true);
  };

  // Add effect to handle initial thread selection
  useEffect(() => {
    if (initialThreadId && threads.length > 0) {
      const thread = threads.find((t) => t._id === initialThreadId);
      if (thread) {
        setActiveThread(thread);
      }
    }
  }, [initialThreadId, threads]);

  // Add a function to calculate total unread messages for a project
  const getProjectUnreadCount = (projectThreads: Thread[]) => {
    return projectThreads.reduce((total, thread) => {
      return total + (unreadCounts[thread._id] || 0);
    }, 0);
  };

  return (
    <div className="flex h-[600px] bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Left Sidebar */}
      <div className="w-64 border-r flex flex-col">
        {/* Search Bar */}
        <div className="p-4 border-b">
          <div className="relative">
            <input
              type="text"
              placeholder="Search threads..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-9 pr-3 py-2 border rounded-md text-sm"
            />
            <MagnifyingGlassIcon className="w-4 h-4 text-gray-400 absolute left-3 top-3" />
          </div>
        </div>

        {/* Thread Lists */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-4 space-y-6">
            {/* Channels section */}
            <div>
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-xs font-semibold text-gray-500 uppercase">
                  Channels
                </h3>
                <button
                  onClick={() => {
                    setIsCreatingProjectThread(false);
                    setIsCreating(true);
                  }}
                  className="p-1 hover:bg-gray-100 rounded-full"
                >
                  <PlusIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
                </button>
              </div>
              <div className="space-y-1">
                {filteredContent.channels.map((thread) =>
                  renderThreadItem(thread),
                )}
              </div>
            </div>

            {/* Projects section */}
            <div>
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-xs font-semibold text-gray-500 uppercase">
                  Projects
                </h3>
                <button
                  onClick={() => {
                    setForm((prev) => ({ ...prev, projectId: undefined }));
                    setIsCreatingProjectThread(true);
                    setIsCreating(true);
                  }}
                  className="p-1 hover:bg-gray-100 rounded-full"
                  title="New Project Thread"
                >
                  <PlusIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
                </button>
              </div>
              <div className="space-y-2">
                {filteredContent.projectGroups.map(
                  ([projectId, { project, threads }]) => (
                    <div key={projectId} className="space-y-1">
                      {/* Project Header with Create Button */}
                      <div className="flex items-center justify-between group px-2 py-1 hover:bg-gray-100 rounded">
                        <button
                          onClick={() => toggleProject(projectId)}
                          className="flex items-center flex-1 text-sm font-medium text-gray-700"
                        >
                          {expandedProjects.has(projectId) ? (
                            <ChevronDownIcon className="w-4 h-4 mr-1" />
                          ) : (
                            <ChevronRightIcon className="w-4 h-4 mr-1" />
                          )}
                          <span className="flex items-center gap-2">
                            {project.name}
                            {getProjectUnreadCount(threads) > 0 && (
                              <span className="px-1.5 py-0.5 text-xs bg-blue-500 text-white rounded-full min-w-[1.25rem] text-center">
                                {getProjectUnreadCount(threads)}
                              </span>
                            )}
                          </span>
                        </button>

                        <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/project/${project._id}`);
                            }}
                            className="p-1 hover:bg-gray-200 rounded-full"
                            title={`Go to ${project.name}`}
                          >
                            <ArrowTopRightOnSquareIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCreateProjectThread(
                                project._id,
                                project.name,
                              );
                            }}
                            className="p-1 hover:bg-gray-200 rounded-full"
                            title={`New thread in ${project.name}`}
                          >
                            <PlusIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
                          </button>
                        </div>
                      </div>

                      {/* Project Threads */}
                      {expandedProjects.has(projectId) && (
                        <div className="ml-6 space-y-1">
                          {threads.map((thread) => renderThreadItem(thread))}
                        </div>
                      )}
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1">
        {activeThread ? (
          <ThreadChat thread={activeThread} />
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            Select a thread to start messaging
          </div>
        )}
      </div>

      {/* Create Thread Modal */}
      {isCreating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">
              {isCreatingProjectThread
                ? `New Thread in ${projects.find((p) => p._id === form.projectId)?.name || 'Project'}`
                : 'New Channel'}
            </h2>
            <div className="space-y-4">
              <input
                type="text"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                placeholder="Thread name"
                className="w-full px-3 py-2 border rounded-lg"
                autoFocus
              />

              {isCreatingProjectThread && !form.projectId && (
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Project
                  </label>
                  <Select
                    showSearch
                    placeholder="Search for a project"
                    value={form.projectId}
                    onChange={(value) => setForm({ ...form, projectId: value })}
                    className="w-full"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={projects.map((project) => ({
                      label: project.name,
                      value: project._id,
                    }))}
                  />
                </div>
              )}

              <div className="flex items-center gap-2">
                <Switch
                  checked={form.isPrivate}
                  onChange={(checked) =>
                    setForm({ ...form, isPrivate: checked })
                  }
                />
                <span className="text-sm text-gray-600">Private Thread</span>
              </div>

              {form.isPrivate && (
                <Select
                  mode="multiple"
                  placeholder="Select members"
                  className="w-full"
                  value={form.members}
                  onChange={(values) => setForm({ ...form, members: values })}
                  options={companyMembers.map((member) => ({
                    label: `${member.firstName} ${member.lastName}`,
                    value: member._id,
                  }))}
                />
              )}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={() => {
                  setForm({
                    name: '',
                    isPrivate: false,
                    members: [],
                    projectId: undefined,
                  });
                  setIsCreating(false);
                  setIsCreatingProjectThread(false);
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateThread}
                disabled={
                  !form.name || (isCreatingProjectThread && !form.projectId)
                }
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {editingThread && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">Edit Thread Members</h2>
            <div className="space-y-4">
              <h3 className="font-medium">{editingThread.name}</h3>
              <Select
                mode="multiple"
                placeholder="Select members"
                className="w-full"
                value={
                  getMemberDetails(editingThread?.members).map((m) => m._id) ||
                  []
                }
                onChange={(values) =>
                  handleEditMembers(editingThread._id, values)
                }
                options={companyMembers.map((member) => ({
                  label: `${member.firstName} ${member.lastName}`,
                  value: member._id,
                }))}
              />
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={() => setEditingThread(null)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
