import { useNavigate } from 'react-router-dom';
import logo from '../images/abacusLogo.png';
import { activeAuths, activeUserState } from '../atoms/user.atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeCompanyState } from '../atoms/new.company.atom';
import ConstructionCostEstimate from './ConstructionCostEstimate';
import { clearToken } from '../utils/tokenManager';
import { useState } from 'react';
import {
  HomeIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  FolderPlusIcon,
  FolderIcon,
  CalculatorIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline';
import { NotificationsDropdown } from './Notifications/NotificationsDropdown';

const SideNav = () => {
  const setActiveUser = useSetRecoilState(activeUserState);
  const setActiveCompany = useSetRecoilState(activeCompanyState);
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const [showEstimator, setShowEstimator] = useState(false);
  const activeAuthorizations = useRecoilValue(activeAuths);
  const navigate = useNavigate();

  const signOut = async () => {
    try {
      clearToken();
      setActiveUser(null);
      setActiveCompany(null);
      navigate('/');
    } catch (error) {
      console.error('Signin failed', error);
    }
  };

  return (
    <div className="text-white pt-2 flex flex-col h-full">
      {/* User Section - Updated with notifications */}
      <div className="px-4 py-2 border-b border-gray-700">
        <div className="flex items-center justify-between mb-2">
          <h1 className="text-lg font-semibold">
            {activeUser.firstName} {activeUser.lastName}
          </h1>
          <NotificationsDropdown />
        </div>
        {activeCompany ? (
          <h2 className="text-sm text-gray-300">{activeCompany.name}</h2>
        ) : (
          <h2 className="text-sm text-gray-300">No Company Selected</h2>
        )}
      </div>

      {/* Main Navigation */}
      <nav className="flex-1 px-2 py-4 space-y-6">
        {activeUser?.firstName ? (
          <>
            {/* Dashboard Section */}
            <div className="space-y-2">
              <button
                onClick={() => navigate('/')}
                className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
              >
                <HomeIcon className="w-5 h-5 mr-3" />
                Dashboard
              </button>
            </div>

            {/* Projects Section */}
            <div className="space-y-1">
              <h3 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                Projects
              </h3>
              <button
                onClick={() => navigate('/project')}
                className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
              >
                <FolderPlusIcon className="w-5 h-5 mr-3" />
                New Project
              </button>
              <button
                onClick={() => navigate('/projectList')}
                className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
              >
                <FolderIcon className="w-5 h-5 mr-3" />
                Project List
              </button>
            </div>

            {/* Tools Section */}
            <div className="space-y-1">
              <h3 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                Tools
              </h3>
              <button
                onClick={() => setShowEstimator(!showEstimator)}
                className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
              >
                <CalculatorIcon className="w-5 h-5 mr-3" />
                Cost Estimator
              </button>
              {showEstimator && <ConstructionCostEstimate />}
            </div>

            {/* Account Section */}
            <div className="space-y-1">
              <h3 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                Account
              </h3>
              <button
                onClick={() => navigate('/profile')}
                className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
              >
                <UserCircleIcon className="w-5 h-5 mr-3" />
                Profile
              </button>
              <button
                onClick={() => signOut()}
                className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
              >
                <ArrowRightOnRectangleIcon className="w-5 h-5 mr-3" />
                Log Out
              </button>
            </div>

            {/* Admin Section */}
            {activeAuthorizations.hasOwner && (
              <div className="space-y-1">
                <h3 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  Admin
                </h3>
                <button
                  onClick={() => navigate('/companies')}
                  className="w-full flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
                >
                  <Cog8ToothIcon className="w-5 h-5 mr-3" />
                  Admin Dashboard
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="px-4">
            <a
              href="/login"
              className="flex items-center px-4 py-2 text-sm text-white hover:bg-gray-700 rounded-md"
            >
              <ArrowRightOnRectangleIcon className="w-5 h-5 mr-3" />
              Login
            </a>
          </div>
        )}
      </nav>
    </div>
  );
};

export default SideNav;
