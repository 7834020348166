import React, { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import WorkflowStatus from '../components/WorkflowStatus';
import { DisciplineType, Project } from '../models/general';
import { updateProject } from '../utils/api';
import { workFlowStatuses } from '../utils/misc';
import ThreadList from '../components/Thread/ThreadList';
import MilestoneList from '../components/MilestoneList';
import TeamManagement from '../components/TeamManagement';
import FileList from '../components/Dashboard/FileList';

interface ProjectContextType {
  activeProject: Project;
  updateProject: (id: string, project: Project) => Promise<void>;
}

const useDebounce = (callback: Function, delay: number) => {
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );
};

const ProjectDetails: React.FC = () => {
  const { activeProject, updateProject } =
    useOutletContext<ProjectContextType>();

  const [formData, setFormData] = React.useState({
    name: activeProject?.name || '',
    description: activeProject?.description || '',
    workFlowStatus: activeProject?.workFlowStatus || '',
    disciplines: activeProject?.disciplines || [],
  });

  // Update local state when activeProject changes
  React.useEffect(() => {
    if (activeProject) {
      setFormData({
        name: activeProject.name || '',
        description: activeProject.description || '',
        workFlowStatus: activeProject.workFlowStatus || '',
        disciplines: activeProject.disciplines || [],
      });
    }
  }, [activeProject]);

  // Move handleDebouncedUpdate before the safety check
  const handleDebouncedUpdate = useDebounce(
    async (updatedData: typeof formData) => {
      try {
        const updatedProject = {
          ...activeProject,
          ...updatedData,
        };
        await updateProject(activeProject._id, updatedProject);
      } catch (error) {
        console.error('Failed to update project:', error);
        setFormData(formData); // Revert on error
      }
    },
    1000,
  );

  // Add safety check after all hooks
  if (!activeProject?._id) {
    return <div>Loading project details...</div>;
  }

  const handleChange = async (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);

    // Only use debounce for name and description fields
    if (name === 'name' || name === 'description') {
      handleDebouncedUpdate(updatedData);
    } else {
      // Immediately update other fields
      try {
        const updatedProject = {
          ...activeProject,
          ...updatedData,
        };
        await updateProject(activeProject._id, updatedProject);
      } catch (error) {
        console.error('Failed to update project:', error);
        setFormData(formData); // Revert on error
      }
    }
  };

  const handleDisciplineChange = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const discipline = e.target.value as DisciplineType;
    if (!formData.disciplines.includes(discipline)) {
      const updatedDisciplines = [...formData.disciplines, discipline];
      const updatedData = { ...formData, disciplines: updatedDisciplines };
      setFormData(updatedData);

      try {
        const updatedProject = {
          ...activeProject,
          disciplines: updatedDisciplines,
        };
        await updateProject(activeProject._id, updatedProject);
      } catch (error) {
        console.error('Failed to update project:', error);
        setFormData(formData); // Revert on error
      }
    }
  };

  const handleRemoveDiscipline = async (disciplineToRemove: string) => {
    const updatedDisciplines = formData.disciplines.filter(
      (d) => d !== disciplineToRemove,
    );
    const updatedData = { ...formData, disciplines: updatedDisciplines };
    setFormData(updatedData);

    try {
      const updatedProject = {
        ...activeProject,
        disciplines: updatedDisciplines,
      };
      await updateProject(activeProject._id, updatedProject);
    } catch (error) {
      console.error('Failed to update project:', error);
      setFormData(formData); // Revert on error
    }
  };

  const availableDisciplines = Object.values(DisciplineType).filter(
    (discipline) => !formData.disciplines.includes(discipline),
  );

  return (
    <div className="space-y-4 max-w-7xl mx-auto">
      <section className="bg-white rounded-lg shadow-lg p-4">
        <h2 className="text-xl font-bold mb-4 text-gray-800 border-b pb-2">
          Project Files
        </h2>
        <FileList project={activeProject} forcePublic={false} />
      </section>
      {/* Project Info and Client/Team Grid */}
      <div className="grid grid-cols-2 gap-4">
        {/* Left Column - Project and Client Info */}
        <div className="space-y-4">
          {/* Project Info Section */}
          <section className="bg-white rounded-lg shadow-lg p-4 h-68">
            <h2 className="text-xl font-bold mb-2 text-gray-800 border-b pb-1">
              Project Information
            </h2>
            <div className="space-y-3 overflow-y-auto h-52">
              {/* Project Name and Status in one row */}
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="block text-xs font-bold text-gray-600 uppercase tracking-wide">
                    Project Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                  />
                </div>
                <div>
                  <label className="block text-xs font-bold text-gray-600 uppercase tracking-wide">
                    Status
                  </label>
                  <select
                    name="workFlowStatus"
                    value={formData.workFlowStatus}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-white text-sm"
                  >
                    {workFlowStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Description - shorter */}
              <div>
                <label className="block text-xs font-bold text-gray-600 uppercase tracking-wide">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={2}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                />
              </div>

              {/* Disciplines - single row with dropdown */}
              <div className="flex items-center gap-2">
                <div className="flex-1 flex flex-wrap gap-1">
                  {formData.disciplines.map((discipline) => (
                    <span
                      key={discipline}
                      className="inline-flex items-center bg-blue-50 px-2 py-1 rounded-full text-xs font-medium text-blue-700"
                    >
                      {discipline}
                      <button
                        type="button"
                        onClick={() => handleRemoveDiscipline(discipline)}
                        className="ml-1 text-blue-400 hover:text-blue-600"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                </div>
                <select
                  onChange={handleDisciplineChange}
                  value=""
                  className="w-32 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-white text-sm"
                >
                  <option value="" disabled>
                    Add Discipline
                  </option>
                  {availableDisciplines.map((discipline) => (
                    <option key={discipline} value={discipline}>
                      {discipline}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </section>

          {/* Client Info Section */}
          <section className="bg-white rounded-lg shadow-lg p-4 h-64">
            <h2 className="text-xl font-bold mb-2 text-gray-800 border-b pb-1">
              Client Information
            </h2>
            <div className="space-y-2 overflow-y-auto h-32">
              <div>
                <p className="text-base text-gray-800 mt-1">
                  {activeProject.client?.name}
                </p>
              </div>
              <div>
                <p className="text-gray-600 mt-1">
                  {activeProject.client?.address}
                </p>
              </div>
              <div>
                <p className="text-blue-600 hover:text-blue-800 mt-1">
                  {activeProject.client?.email}
                </p>
              </div>
              <div>
                <p className="text-gray-600 mt-1">
                  {activeProject.client?.phone}
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* Right Column - Team Members */}
        <section className="bg-white rounded-lg shadow-lg p-4 h-102">
          <h2 className="text-xl font-bold mb-2 text-gray-800 border-b pb-1 sticky top-0 bg-white z-10">
            Team Members
          </h2>
          <div className="overflow-y-auto h-96">
            <TeamManagement
              project={activeProject}
              companyId={activeProject.company?._id || activeProject.company}
              updateProject={async (updatedProject: Project) => {
                try {
                  console.log(
                    'ProjectDetails: Updating project with:',
                    updatedProject,
                  );
                  await updateProject(activeProject._id, updatedProject);
                  console.log('ProjectDetails: Update successful');
                } catch (error) {
                  console.error('Failed to update team members:', error);
                  throw error;
                }
              }}
            />
          </div>
        </section>
      </div>

      {/* Project Threads Section */}
      <ThreadList projectId={activeProject._id} />
    </div>
  );
};

export { ProjectDetails };
