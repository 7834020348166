import { Modal, Form, Input, Select, DatePicker } from 'antd';
import { useRecoilValue } from 'recoil';
import {
  userListState,
  activeUserState,
  activeAuths,
} from '../../atoms/user.atom';
import { createLeave, updateLeave } from '../../utils/api';
import { differenceInDays } from 'date-fns';
import { useEffect } from 'react';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  initialValues?: Leave | null;
}

const LeaveRequestModal = ({
  visible,
  onClose,
  onSuccess,
  initialValues,
}: Props) => {
  const [form] = Form.useForm();
  const users = useRecoilValue(userListState);
  const activeUser = useRecoilValue(activeUserState);
  const activeAuthorizations = useRecoilValue(activeAuths);

  useEffect(() => {
    if (visible && initialValues) {
      form.setFieldsValue({
        title: initialValues.title,
        description: initialValues.description,
        members: initialValues.members.map((m) => m._id),
        type: initialValues.type,
        dateRange: [
          dayjs(initialValues.startDate),
          dayjs(initialValues.startDate).add(initialValues.duration - 1, 'day'),
        ],
      });
    } else {
      form.resetFields();
    }
  }, [visible, initialValues]);

  const handleSubmit = async (values: any) => {
    try {
      const [startDate, endDate] = values.dateRange;
      const duration =
        differenceInDays(endDate.toDate(), startDate.toDate()) + 1;

      const leaveData = {
        ...values,
        startDate: startDate.toDate(),
        duration,
        members: activeAuthorizations.hasOwner
          ? values.members
          : [activeUser?._id],
        type: values.type || 'PTO',
      };

      delete leaveData.dateRange;

      if (initialValues) {
        await updateLeave(initialValues._id, leaveData);
      } else {
        await createLeave(leaveData);
      }

      onSuccess();
      onClose();
      form.resetFields();
    } catch (error) {
      console.error('Failed to submit leave request:', error);
    }
  };

  return (
    <Modal
      title="Request Leave"
      open={visible}
      onCancel={onClose}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="type" label="Leave Type" initialValue="PTO">
          <Select>
            <Option value="Medical">Medical</Option>
            <Option value="PTO">PTO</Option>
            <Option value="Remote Work">Remote Work</Option>
            {activeAuthorizations.hasOwner && (
              <Option value="Holiday">Holiday</Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>

        {activeAuthorizations.hasOwner && (
          <Form.Item
            name="members"
            label="Members"
            rules={[{ required: true, message: 'Please select members' }]}
          >
            <Select mode="multiple">
              {users.map((user) => (
                <Select.Option key={user._id} value={user._id}>
                  {user.firstName} {user.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="dateRange"
          label="Date Range"
          rules={[{ required: true, message: 'Please select date range' }]}
        >
          <RangePicker
            style={{ width: '100%' }}
            format="MMM D, YYYY"
            defaultPickerValue={
              initialValues
                ? [
                    dayjs(initialValues.startDate),
                    dayjs(initialValues.startDate).add(
                      initialValues.duration - 1,
                      'day',
                    ),
                  ]
                : [dayjs(), dayjs()]
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LeaveRequestModal;
