import React, { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { activeUserState } from '../../atoms/user.atom';
import { activeCompanyState } from '../../atoms/new.company.atom';
import { Message, UserPresence, Thread } from '../../models/thread.types';
import { SocketService } from '../../services/socket.service';
import ReactQuill from 'react-quill';
import { fetchThreadMessages } from '../../utils/api';
import { getSignedImageUrl } from '../../utils/imageUrl';
import { ThreadFileUpload } from './ThreadFileUpload';
import { Dialog } from '@headlessui/react';
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MessageAttachments } from './MessageAttachments';
import { companyMembersState } from '../../atoms/company.members.atom';
import 'react-quill/dist/quill.snow.css';
import { MessageContent } from './MessageContent';
import { useNotifications } from '../../hooks/useNotifications';

interface Props {
  thread: Thread;
}

interface MessageUser {
  _id: string;
  name: string;
  imageUrl?: string;
  email: string;
}

interface MessageWithUser extends Message {
  user?: MessageUser;
}

interface MentionSuggestion {
  id: string;
  firstName: string;
  lastName: string;
}

// Create a custom Blot for mentions
const Quill = ReactQuill.Quill;
const Embed = Quill.import('blots/embed');

class MentionBlot extends Embed {
  static create(data: { id: string; name: string }) {
    const node = super.create();
    node.setAttribute('data-user-id', data.id);
    node.setAttribute('data-user-name', data.name);
    node.setAttribute(
      'class',
      'inline-flex items-center bg-blue-100 text-blue-800 rounded px-2 mx-1',
    );
    node.innerHTML = `@${data.name}`;
    return node;
  }

  static value(node: HTMLElement) {
    return {
      id: node.getAttribute('data-user-id'),
      name: node.getAttribute('data-user-name'),
    };
  }
}

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
Quill.register(MentionBlot);

export const ThreadChat: React.FC<Props> = ({ thread }) => {
  const [messages, setMessages] = useState<MessageWithUser[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [typing, setTyping] = useState<UserPresence[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [signedUrls, setSignedUrls] = useState<{ [key: string]: string }>({});
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [pendingFile, setPendingFile] = useState<any>(null);
  const [showMentions, setShowMentions] = useState(false);
  const [mentionSearch, setMentionSearch] = useState('');
  const [mentionSuggestions, setMentionSuggestions] = useState<
    MentionSuggestion[]
  >([]);
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const quillRef = useRef<ReactQuill>(null);
  const companyMembers = useRecoilValue(companyMembersState);
  const { addNotification } = useNotifications();

  // Load existing messages
  useEffect(() => {
    const loadMessages = async () => {
      if (!thread?._id) return;

      try {
        setLoading(true);
        const response = await fetchThreadMessages(thread._id);
        setMessages(response.data);
        setError(null);
      } catch (err) {
        console.error('Error loading messages:', err);
        setError('Failed to load messages');
      } finally {
        setLoading(false);
      }
    };

    loadMessages();
  }, [thread?._id]);

  useEffect(() => {
    const socket = SocketService.getInstance().getSocket();

    if (!socket.connected) {
      SocketService.getInstance().reconnect();
    }

    // Clean up previous thread connection
    if (socket.connected) {
      socket.emit('leaveThread', thread._id);
    }

    // Remove message listener specific to this thread
    socket.off('message');

    // Join new thread
    socket.emit('joinThread', thread._id);

    // Add message listener with explicit thread check
    socket.on('message', (message: Message) => {
      // Double-check the message belongs to this thread
      if (message.threadId !== thread._id) {
        console.debug(
          'Filtered out message for different thread',
          message.threadId,
        );
        return;
      }

      setMessages((prev) => {
        const exists = prev.some(
          (m) =>
            m._id === message._id ||
            (m.content === message.content &&
              m.userId === message.userId &&
              Math.abs(
                new Date(m.createdAt).getTime() -
                  new Date(message.createdAt).getTime(),
              ) < 1000),
        );

        if (exists) return prev;
        return [...prev, message];
      });
    });

    return () => {
      // Only clean up thread-specific listeners
      socket.off('message');
      socket.emit('leaveThread', thread._id);
    };
  }, [thread._id]);

  // Separate useEffect for global socket events
  useEffect(() => {
    const socket = SocketService.getInstance().getSocket();

    // Add mention listener (this should persist across thread changes)
    socket.on(
      'notification:mention',
      (data: { messageId: string; threadId: string; mentionedBy: string }) => {
        console.log('Received mention notification:', data);
        addNotification({
          type: 'mention',
          threadId: data.threadId,
          messageId: data.messageId,
          mentionedBy: data.mentionedBy,
          timestamp: new Date(),
          read: false,
        });
      },
    );

    // Add other global listeners
    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    return () => {
      // Clean up global listeners only when component unmounts
      socket.off('notification:mention');
      socket.off('connect_error');
    };
  }, []); // Empty dependency array since these are global listeners

  // Emit presence when entering/leaving thread
  useEffect(() => {
    const socket = SocketService.getInstance().getSocket();

    // Notify server that user is viewing this thread
    socket.emit('threadPresence', {
      threadId: thread._id,
      userId: activeUser?._id,
      status: 'active',
    });

    return () => {
      // Notify server that user is no longer viewing this thread
      socket.emit('threadPresence', {
        threadId: thread._id,
        userId: activeUser?._id,
        status: 'inactive',
      });
    };
  }, [thread._id, activeUser?._id]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = () => {
    if (!newMessage.trim() && !pendingFile) return;

    const socket = SocketService.getInstance().getSocket();

    // Get the HTML content directly from the editor
    const editor = quillRef.current?.getEditor();
    const htmlContent = editor?.root.innerHTML || '';
    const delta = editor?.getContents();

    // Process mentions while preserving HTML
    let processedContent = htmlContent;
    const mentions: string[] = []; // Array to collect mention IDs

    delta?.ops?.forEach((op) => {
      if (op.insert?.mention) {
        const mention = op.insert.mention;
        // Add mention ID to mentions array
        mentions.push(mention.id);
        // Replace mention objects with our mention format while preserving surrounding HTML
        processedContent = processedContent.replace(
          `<span class="mention">@${mention.name}</span>`,
          `@[${mention.id}](${mention.name})`,
        );
      }
    });

    const messageData = {
      threadId: thread._id,
      content: processedContent,
      userId: activeUser?._id,
      createdAt: new Date().toISOString(),
      attachments: pendingFile,
      mentions: mentions, // Include the mentions array
    };

    console.log('Sending message:', messageData);

    socket.emit('message', messageData, (response: any) => {
      if (response?.error) {
        console.error('Failed to send message:', response.error);
      }
    });

    setNewMessage('');
    setPendingFile(null);
  };

  // Add this effect to handle signed URLs
  useEffect(() => {
    const loadSignedUrls = async () => {
      const newSignedUrls: { [key: string]: string } = {};

      for (const message of messages) {
        if (message.user?.imageUrl) {
          const signedUrl = await getSignedImageUrl(message.user.imageUrl);
          if (signedUrl) {
            newSignedUrls[message.user._id] = signedUrl;
          }
        }
      }

      setSignedUrls(newSignedUrls);
    };

    loadSignedUrls();
  }, [messages]);

  const handleFileUpload = async (fileInfo: any) => {
    setPendingFile(fileInfo);
  };

  const renderFilePreview = (file: any) => {
    if (file.url.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return (
        <div className="mt-2">
          <img
            src={file.url}
            alt={file.name}
            className="max-h-48 rounded-lg cursor-pointer hover:opacity-90"
            onClick={() => setImagePreview(file.url)}
          />
        </div>
      );
    }

    return (
      <div className="mt-2">
        <a
          href={file.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 p-2 border rounded-lg hover:bg-gray-50"
        >
          <PaperClipIcon className="w-5 h-5 text-gray-500" />
          <span className="text-sm text-blue-500">{file.name}</span>
        </a>
      </div>
    );
  };

  const handleTextChange = (value: string) => {
    setNewMessage(value);

    const editor = quillRef.current?.getEditor();
    if (!editor) return;

    const selection = editor.getSelection();
    if (!selection) return;

    const [leaf] = editor.getLeaf(selection.index);
    const text = leaf?.text || '';

    // Check if we're typing an @mention
    const mentionMatch = text.match(/@(\w*)$/);
    if (mentionMatch) {
      const searchTerm = mentionMatch[1];
      setMentionSearch(searchTerm);

      // Map company members to MentionSuggestion format with correct ID
      const suggestions = companyMembers
        .filter((member) =>
          `${member.firstName} ${member.lastName}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
        )
        .map((member) => ({
          id: member._id, // Make sure we're using the correct ID field
          firstName: member.firstName,
          lastName: member.lastName,
        }));

      setMentionSuggestions(suggestions);
      setShowMentions(true);

      const bounds = editor.getBounds(selection.index);
      setCursorPosition({
        top: bounds.top + bounds.height,
        left: bounds.left,
      });
    } else {
      setShowMentions(false);
    }
  };

  const insertMention = (user: MentionSuggestion) => {
    const editor = quillRef.current?.getEditor();
    if (!editor) return;

    const selection = editor.getSelection();
    if (!selection) return;

    // Delete the @mention text
    const [leaf] = editor.getLeaf(selection.index);
    const text = leaf?.text || '';
    const mentionMatch = text.match(/@(\w*)$/);
    if (mentionMatch) {
      const mentionLength = mentionMatch[0].length;
      editor.deleteText(selection.index - mentionLength, mentionLength);

      // Insert mention chip with correct data
      editor.insertEmbed(selection.index - mentionLength, 'mention', {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`.trim(),
      });

      // Add a space after the mention
      editor.insertText(selection.index - mentionLength + 1, ' ');

      // Move cursor after the mention and space
      editor.setSelection(selection.index - mentionLength + 2, 0);
    }
    setShowMentions(false);
  };

  const handleMentionClick = (userId: string) => {
    // Handle mention click - could show user profile, etc.
    console.log('Mention clicked:', userId);
  };

  return (
    <div className="flex flex-col h-full relative">
      {loading && <div className="p-4">Loading...</div>}
      {error && <div className="p-4 text-red-500">{error}</div>}
      {!loading && !error && (
        <>
          <div className="flex-1 overflow-y-auto p-4">
            {messages.map((message) => {
              const initials = message.user
                ? `${message.user.firstName[0]}${message.user.lastName[0]}`.toUpperCase()
                : '??';

              return (
                <div
                  key={message._id}
                  className={`flex flex-col w-full border-t border-gray-100 ${
                    message.userId === activeUser?._id
                      ? 'bg-gray-100'
                      : 'bg-white'
                  }`}
                >
                  <div className="flex flex-col p-4">
                    <div className="flex items-center gap-2 mb-1">
                      {message.user?.imageUrl ? (
                        <img
                          src={
                            signedUrls[message.user._id] ||
                            message.user.imageUrl
                          }
                          alt={`${message.user.firstName} ${message.user.lastName}`}
                          className="w-8 h-8 rounded-full"
                        />
                      ) : (
                        <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-sm font-medium">
                          {initials}
                        </div>
                      )}
                      <div className="flex items-baseline gap-2">
                        <span className="font-bold">
                          {message.user?.firstName} {message.user?.lastName}
                        </span>
                        <span className="text-xs text-gray-500">
                          {new Date(message.createdAt).toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="ml-10">
                      <MessageContent
                        content={message.content}
                        onMentionClick={handleMentionClick}
                      />
                      {message.attachments &&
                        message.attachments.length > 0 && (
                          <MessageAttachments
                            attachments={message.attachments}
                          />
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </div>

          <div className="border-t p-4">
            <div className="relative">
              <ReactQuill
                ref={quillRef}
                value={newMessage}
                onChange={handleTextChange}
                placeholder="Type @ to mention someone..."
              />

              {showMentions && mentionSuggestions.length > 0 && (
                <div
                  className="absolute z-10 bg-white shadow-lg rounded-lg border"
                  style={{ top: cursorPosition.top, left: cursorPosition.left }}
                >
                  {mentionSuggestions.map((user) => (
                    <button
                      key={user.id}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100"
                      onClick={() => insertMention(user)}
                    >
                      {user.firstName} {user.lastName}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center gap-2 flex-1">
                <ThreadFileUpload
                  projectId={thread?.projectId}
                  companyId={activeCompany._id}
                  onFileUpload={handleFileUpload}
                  onError={(error) => console.error(error)}
                  pendingFile={pendingFile}
                  onClearFile={() => setPendingFile(null)}
                />
              </div>
              <button
                onClick={handleSend}
                disabled={!newMessage.trim() && !pendingFile}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
              >
                Send
              </button>
            </div>
          </div>
        </>
      )}

      {/* Image Preview Modal */}
      <Dialog
        open={!!imagePreview}
        onClose={() => setImagePreview(null)}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="relative bg-white rounded-lg max-w-3xl mx-auto">
            <img
              src={imagePreview || ''}
              alt="Preview"
              className="max-h-[80vh] rounded-lg"
            />
            <button
              onClick={() => setImagePreview(null)}
              className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
